import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Headline6 from "components/elements/Headline6"
import ContentSmall from "components/elements/ContentSmall"
import ButtonMedium1 from "components/elements/ButtonMedium1";
import ButtonMedium4 from "components/elements/ButtonMedium4";
import './Header.css'
import { Alert, AlertTitle, AppBar, Box, createTheme, Drawer, IconButton, Menu, Snackbar, ThemeProvider, Toolbar, Typography, useMediaQuery, useScrollTrigger } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppContext } from "contexts/AppContext";
import { AccountCircle, Close, FavoriteBorder, KeyboardArrowDown, KeyboardArrowRight, Menu as MenuIcon, ShoppingCartOutlined } from "@mui/icons-material";
import { callAPI } from "utils/API";
import Activity from "utils/Activity";
import Translate, { translate } from "utils/Translate";
import { pointsConvertor } from "utils/general";
import ContentNormal from "components/elements/ContentNormal";
import ButtonLarge1 from "components/elements/ButtonLarge1";
import Headline4 from "components/elements/Headline4";
import CustomSelectHeader2 from "components/elements/CustomSelectHeader2";
import { createUseStyles } from "react-jss";

const jss = createUseStyles({
    HeaderIcon: {
        paddingLeft: 20,
        paddingRight: 20,
    },
    "@media screen and (max-width: 360px)": {
        HeaderIcon: {
            paddingLeft: 10,
            paddingRight: 10,
        },
    },
});

function Header5(props) {
    const navigate = useNavigate()
    const [showConfirmLogout, setShowConfirmLogout] = useState(false);
    const [teamAccess, setTeamAccess] = useState();
    const [selectedCustomerNumber, setSelectedCustomerNumber] = useState(null);
    const [customerData, setCustomerData] = useState(null);
    const [customers, setCustomers] = useState(null);
    const [announcements, setAnnouncements] = useState([]);
    const [showBurgerDrawer, setShowBurgerDrawer] = useState(false);
    const [showAccountDrawer, setShowAccountDrawer] = useState(false);
    const { brandData, accessibleShops, styleGuide, menuData, userData, setUserData, langCode } = useContext(AppContext);
    const hofMenuItem = menuData?.filter(menu => menu.poolName.toLowerCase() === 'halloffame') ?? []
    const hofs = (hofMenuItem && hofMenuItem.length) ? hofMenuItem[0].pool : []
    const classes = jss(styleGuide);

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    const isMobile = useMediaQuery('(max-width:900px)');
    const pointSuffix = translate("_POINTS_SHORT_", "pt");
    const isStarkBrand = brandData?.BrandID === 87;

    const brandLogoHeight = !!brandData?.features?.brandLogoHeight ? `${brandData.features.brandLogoHeight}px` : '35px';
    const menuBarBgColor = brandData?.features?.themeOneMenuBarBackgroundColor || "#fff";
    const menuBarTextColor = brandData?.features?.themeOneMenuBarTextColor || styleGuide.color7;
    const topBarBgColor = brandData?.features?.themeOneHeaderTopBarBgColor;

    const headerTheme = createTheme({
        palette: {
            primary: {
                main: menuBarBgColor,
                contrastText: menuBarTextColor,
            },
            snackbar: {
                main: styleGuide.color16,
            }
        },
        components: {
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundColor: menuBarBgColor,
                        color: menuBarTextColor,
                    }
                }
            }
        }
    })

    useEffect(() => {
        getAnnouncements()
        getTeamAccess()

        if (isStarkBrand) {
            fetchAccounts()
        }
    }, [])

    const getAnnouncements = async () => {
        let response = await callAPI('/announcements/web-pool', "GET");
        if (response?.length) {
            setAnnouncements(response)
        }
    }

    const getTeamAccess = async () => {
        if (brandData?.features?.groupsHavingTeamMenuAccess?.length) {
            let groupNames = brandData.features.groupsHavingTeamMenuAccess
            let access = await callAPI('/group/checkAccess', "GET", { query: { groupNames } })
            setTeamAccess(access)
        }
    }

    const fetchAccounts = async () => {
        const customers = await callAPI(`/stark/getCustomers`)
        let customerArray = []
        if (customers) {
            customers.map((e) => {
                customerArray.unshift({ value: e.customerNumber, label: e.name + " (" + e.customerNumber + ")" })
            })
            setCustomers(customerArray)
            setCustomerData(customers)
            setUserData({...userData, selectedCustomerNumber: customers[0].customerNumber})
            setSelectedCustomerNumber(customers[0].customerNumber)
        }
    }

    const setCurrentAccount = async (account = null) => {
        let currentAccount = userData.customerNumber
        if (account && currentAccount !== account) {
            const customer = customerData.filter((x) => x.customerNumber === account)
            const balance = await callAPI(`/stark/getBalance`, 'GET', {}, null, account)
            setUserData({
                ...userData,
                selectedCustomerNumber: account,
                walletAmountPoints: balance,
                userCompany: customer[0].name,
                userCompanyUID: customer[0].cvr,
                userAddress: customer[0].invoiceAddress ? `${customer[0].invoiceAddress.addressLine1} ${customer[0].invoiceAddress.addressLine2}` : '',
                userZipcode: customer[0].invoiceAddress ? customer[0].invoiceAddress.zipCode : '',
                userCity: customer[0].invoiceAddress ? customer[0].invoiceAddress.city : ''
            })
            setSelectedCustomerNumber(account)
        }
    }

    const clearSession = () => {
        localStorage.clear()
        navigate('/logout')
    }

    const showPoints = !!brandData?.features && brandData.features.points !== false && !brandData.features.hideUserPointBalance
    const showWishlist = !brandData?.features?.disableWishlist
    const showBasketMenu = !(brandData && brandData.features && brandData.features.cartIcon === false)

    // Show account button to the left if both wishlist and basket is shown. Else show to the right.
    const showAccountMobileButtonLeft = showWishlist && showBasketMenu
    const accountMobileButton = (
        <AccountButtonMobile
            onLogOutClick={() => {setShowAccountDrawer(false); setShowConfirmLogout(true);}}
            selectedCustomerNumber={selectedCustomerNumber}
            setCurrentAccount={setCurrentAccount}
            customers={customers}
            showPoints={showPoints}
            showDrawer={showAccountDrawer}
            setShowDrawer={(val) => {setShowBurgerDrawer(false); setShowAccountDrawer(val);}}
            teamAccess={teamAccess}
        />
    )
    const appBarShadow = trigger
        ? '0px 2px 10px -1px rgba(0, 0, 0, 0.1)'
        : '0px 0px rgba(0, 0, 0, 0)'
    return (
        <ThemeProvider theme={headerTheme} >
            <AppBar sx={{boxShadow: appBarShadow}}>

                {/* Announcments */}
                <Box>
                    { !!announcements?.length && announcements.map((announcement) => (
                        <div 
                            class="announcement-banner"
                            key={announcement.AnnouncementID}
                            style={{
                                width: '100%',
                                background: announcement.messageType === "INFO" 
                                    ? styleGuide.color18 
                                    : announcement.messageType === "SYSTEM" 
                                        ? styleGuide.color13 
                                        : styleGuide.color19,
                                display: 'flex',
                                alignItems: "center",
                                justifyContent: "center",
                                height: 30,
                            }}
                        >
                            <marquee
                                style={{ color: "#ffffff", width: "90%", fontWeight: "bold", textTransform: 'capitalize' }}
                                behavior={"scroll"}
                                direction={"left"}
                            >
                                {announcement.message}
                            </marquee>
                        </div>
                    ))}
                </Box>

                {/* Top bar */}
                {!(isMobile && announcements.length > 0) && 
                    <Box className={!topBarBgColor ? "headerTopBar" : ""}
                        display={'flex'}
                        justifyContent={'center'}
                        paddingTop={1}
                        paddingBottom={1}
                        style={{backgroundColor: topBarBgColor || ""}}
                    >
                        <Box flexGrow={1} display={'flex'} minHeight={12} maxWidth={1920} style={{paddingRight: 58}}>
                            {!isMobile && <>
                                <Box flexGrow={1} display={'flex'} justifyContent={'center'} gap={5}>
                                    {accessibleShops.map(s => (
                                        <SubtleHeaderButton key={s.ShopID}
                                            onClick={() => {
                                                Activity({activityType: 'click', activityName: 'menu', activityArgument: 'Shop', activityArgumentID: s.ShopID})
                                                navigate(`shop/${s.ShopID}`)
                                            }}
                                        >
                                            {s.translations?.find((translation) => translation.languageKey === langCode)?.moduleName || s.moduleName}
                                        </SubtleHeaderButton>
                                    ))}
                                    {hofs.map(hof => {
                                        const link = `/halloffame/${hof.HalloffameID}`
                                        const title = hof.translations?.find((translation) => translation.languageKey === langCode)?.moduleName ?? hof.moduleName
                                        return (
                                            <SubtleHeaderButton key={title}
                                                onClick={() => {
                                                    Activity({activityType: 'click',activityName: 'menu',activityArgument: 'Halloffame',activityArgumentID: hof.HalloffameID})
                                                    navigate(link);
                                                }}
                                            >
                                                {title}
                                            </SubtleHeaderButton>
                                        )
                                    })}
                                    <SubtleHeaderButton
                                        onClick={() => {
                                            Activity({activityType: 'click',activityName: 'menu',activityArgument: 'Help',activityArgumentID: ''})
                                            navigate('/help')
                                        }}
                                    >
                                        FAQ
                                    </SubtleHeaderButton>
                                    <SubtleHeaderButton 
                                        onClick={() => {
                                            Activity({activityType: 'click',activityName: 'menu',activityArgument: 'Help',activityArgumentID: ''})
                                            navigate("/help", {state: {scrollToContact:true}})
                                        }}
                                    >
                                        { translate("_MENU_CONTACT_", "Kontakt") }
                                    </SubtleHeaderButton>
                                    {teamAccess &&
                                        <SubtleHeaderButton
                                            onClick={() => {
                                                Activity({ activityType: 'click', activityName: 'header', activityArgument: 'Team', activityArgumentID: '' })
                                                navigate('/team')
                                            }}
                                        >
                                            Team
                                        </SubtleHeaderButton>
                                    }
                                </Box>
                                <Box style={{display: 'flex', justifyContent: 'end'}} gap={2}>
                                    {isStarkBrand &&
                                        <SubtleHeaderButton
                                            style={{color: styleGuide.color16}}
                                            onClick={() => {
                                                Activity({ activityType: 'click', activityName: 'header', activityArgument: 'goToStark', activityArgumentID: '' });
                                                window.location.href = 'https://www.stark.dk';
                                            }}
                                        >
                                            STARK.dk
                                        </SubtleHeaderButton>
                                    }
                                    <SubtleHeaderButton
                                        onClick={() => {
                                            Activity({activityType: 'click',activityName: 'menu',activityArgument: 'Logout',activityArgumentID: ''})
                                            setShowConfirmLogout(true)
                                        }}
                                    >
                                        { translate("_MENU_LOG_OUT_", "Log ud") }
                                    </SubtleHeaderButton>
                                </Box>
                            </> }
                        </Box>
                    </Box>
                }

                {/* Header */}
                {isMobile ?
                    <Toolbar sx={{minHeight: 64, padding: '10px 0px 10px 0px'}} >
                        <Box className={classes.HeaderIcon} flex={1} sx={{ justifyContent: 'space-around', display: 'flex'}}>
                            <BurgerButton 
                                onLogOutClick={() => {setShowBurgerDrawer(false); setShowConfirmLogout(true);}}
                                showDrawer={showBurgerDrawer}
                                setShowDrawer={(val) => {setShowAccountDrawer(false); setShowBurgerDrawer(val);}}
                                teamAccess={teamAccess}
                                hofs={hofs}
                            />

                            {showAccountMobileButtonLeft && accountMobileButton}
                        </Box>

                        <img 
                            style={{
                                height: 'auto',
                                width: 'auto',
                                maxWidth: !!brandData?.features?.brandLogoWidth ? `min(${brandData.features.brandLogoWidth}, 35vw)` : '35vw',
                                maxHeight: `min(${brandLogoHeight}, 50px)`,
                                cursor: 'pointer',
                            }}
                            src={brandData.brandLogo}
                            onClick={() => {
                                setShowAccountDrawer(false)
                                setShowBurgerDrawer(false)
                                navigate('/')
                            }}
                        />

                        <Box className={classes.HeaderIcon} flex={1} sx={{ justifyContent: 'space-around', display: 'flex' }}>
                            {!showAccountMobileButtonLeft && accountMobileButton}
                            { showWishlist && <WishlistButton /> }
                            { showBasketMenu && <BasketButton /> }
                        </Box>
                    </Toolbar>
                    :
                    <Box display={'flex'} justifyContent={'center'}>
                        <Box display={'flex'} flexGrow={1} maxWidth={1920} style={{height: 84, display: 'flex', alignItems: 'center', padding: '0px 50px 0px 98px'}}>
                            <img src={brandData.brandLogo}
                                style={{
                                    height: 'auto',
                                    width: 'auto',
                                    maxWidth: !!brandData?.features?.brandLogoWidth ? `min(${brandData.features.brandLogoWidth}, 30vw)` : '30vw',
                                    maxHeight: `min(${brandLogoHeight}, 60px)`,
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigate('/')}
                            />
                            <Box display={'flex'} flexGrow={1} justifyContent={'end'} alignItems={'center'} gap={1}>
                                <AccountButtonDesktop
                                    onLogOutClick={() => setShowConfirmLogout(true)}
                                    selectedCustomerNumber={selectedCustomerNumber}
                                    setCurrentAccount={setCurrentAccount}
                                    customers={customers}
                                    showPoints={showPoints}
                                />
                                { showWishlist && <WishlistButton /> }
                                { showBasketMenu && <BasketButton /> }
                            </Box>
                        </Box>
                    </Box>
                }
            </AppBar>

            {/* Points since last login snack */}
            <Snackbar
                open={(!brandData.features?.disablePoints && !!props.pointsUpdate?.showAlert && !!props.pointsUpdate?.pointsFromLastLogin)}
                autoHideDuration={7000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    color="snackbar"
                    icon={false}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>
                        {`
                            ${translate("_HEADER_POINTS_SINCE_LAST_LOGIN_HI_", "Hej")}
                            ${userData.userName}, 
                        `}
                    </AlertTitle>
                    {`
                        ${translate("_HEADER_POINTS_SINCE_LAST_LOGIN_TEXT_ONE_", "Siden sidst har du optjent")} 
                        ${pointsConvertor(brandData, props.pointsUpdate?.pointsFromLastLogin, pointSuffix)}.
                        ${translate("_HEADER_POINTS_SINCE_LAST_LOGIN_TEXT_TWO_", "Du har nu i alt")} 
                        ${pointsConvertor(brandData, userData.walletAmountPoints, pointSuffix)}.
                    `}
                </Alert>
            </Snackbar>
            
            {/* Confirm log out modal */}
            <Modal show={showConfirmLogout} onHide={() => setShowConfirmLogout(false)} style={{ margin: 'auto' }} centered>
                <Modal.Header className="pb-0 border-0" closeButton style={{ fontSize: 9 }}>
                    <Headline6 translationKey="_CONFIRM_LOGOUT_">Bekræft log ud </Headline6>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <ContentSmall translationKey="_CONFIRM_LOGOUT_TEXT_">Er du sikker på at du vil logge ud? </ContentSmall>
                    <div style={{
                        display: 'flex',
                        paddingTop: '1rem',
                        justifyContent: 'space-around',
                    }}>
                        <ButtonMedium1 showIcon={false} translationKey="_YES_" fallbackText="Ja" onClick={() => clearSession()} />
                        <ButtonMedium4 showIcon={false} translationKey="_NO_" fallbackText="Nej" onClick={() => setShowConfirmLogout(false)} />
                    </div>
                </Modal.Body>
            </Modal>
        </ThemeProvider>
    );
}

const BasketButton = () => {
    const { styleGuide, setshowBasketQuickView, basket } = useContext(AppContext);
    const basketTotalAmount = basket ? basket.reduce((totalAmount, basketEntry) => basketEntry.amount + totalAmount, 0) : 0

    return (
        <div style={{position: 'relative'}}>
            <IconButton color="inherit" onClick={(e) => {
                e.stopPropagation()
                setshowBasketQuickView(true)
            }}>
                <ShoppingCartOutlined />
            </IconButton>
            {basketTotalAmount > 0 && 
                <div style={{
                    width: 17,
                    height: 17,
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    background: styleGuide.color16,
                    padding: 0,
                    margin: 0,
                    lineHeight: 0,
                    borderRadius: 5,
                    fontSize: '11px',
                    fontWeight: '500',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    {basketTotalAmount}
                </div>
            }
        </div>
    )
}

const AccountButtonDesktop = (props) => {
    const navigate = useNavigate()
    const { brandData, styleGuide, userData } = useContext(AppContext)
    const [activatePoints, setActivatePoints] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClose = () => { setAnchorEl(null) }
    const handleClick = (event) => {
        if(open) {
            setAnchorEl(null)
        } else {
            setAnchorEl(event.currentTarget)
        }
    };

    const fetchActivePoints = async () => {
        let response = await callAPI("/user/getWalletActivationProfileStats", "GET");
        if (response) {
            setActivatePoints(response)
        }
    }

    useEffect(() => {
        if(brandData?.features?.enableWalletActivation) {
            fetchActivePoints()
        }
    }, [])

    return <>
        <IconButton
            color="inherit"
            onClick={handleClick}
            sx={{display: 'flex', gap: 1}}
            style={{borderRadius: styleGuide.buttonBorderRadius}}
        >
            <AccountCircle />

            {props.showPoints &&
                <Box display={'flex'} alignItems={'center'} fontSize={18} fontFamily={styleGuide.secondaryFontFamily} fontWeight={400}>
                    {brandData?.features?.enableWalletActivation
                        ? pointsConvertor(brandData, activatePoints?.walletBalance + activatePoints?.walletActivationBalance || '0', translate('_HEADER_POINTS_', 'point'))
                        : pointsConvertor(brandData, userData.walletAmountPoints, translate('_HEADER_POINTS_', 'point'))
                    }
                    <KeyboardArrowDown style={{marginLeft: 2}} />
                </Box>
            }
        </IconButton>

        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            disableScrollLock={true}
        >
            <Box style={{padding: 20, display: 'flex', flexDirection: 'column', width: 310, gap: 10}}>
                <AccountMenu
                    selectedCustomerNumber={props.selectedCustomerNumber}
                    setCurrentAccount={props.setCurrentAccount}
                    customers={props.customers}
                    showPoints={props.showPoints}
                    activatePoints={activatePoints}
                    closeMenu={() => { setAnchorEl(null)}}
                />

                <MenuButton
                    onClick={() => {
                        setAnchorEl(null)
                        Activity({ activityType: 'click', activityName: 'menu', activityArgument: 'Profile', activityArgumentID: '' })
                        navigate('/profile')
                    }}
                >
                    {translate("_MENU_MY_PROFILE_", "Din Profil / Point")}
                </MenuButton>
                <MenuButton
                    onClick={() => {
                        setAnchorEl(null)
                        Activity({ activityType: 'click', activityName: 'menu', activityArgument: 'Profile', activityArgumentID: '' })
                        navigate('/profile', {state: {scrollToSettings: true}})
                    }}
                >
                    {translate("_MENU_PROFILE_SETTINGS_", "Indstillinger")}
                </MenuButton>
                <MenuButton
                    onClick={() => {
                        setAnchorEl(null)
                        Activity({activityType: 'click',activityName: 'menu',activityArgument: 'Logout',activityArgumentID: ''})
                        props.onLogOutClick()
                    }}
                >
                    {translate("_MENU_LOG_OUT_", "Log ud")}
                </MenuButton>
            </Box>
        </Menu>
    </>
}

const AccountButtonMobile = (props) => {
    const {brandData} = useContext(AppContext)
    const [activatePoints, setActivatePoints] = useState(null)
    const navigate = useNavigate()
    const handleClick = () => { props.setShowDrawer(!props.showDrawer) };
    const handleClose = () => { props.setShowDrawer(false) };

    const fetchActivePoints = async () => {
        let response = await callAPI("/user/getWalletActivationProfileStats", "GET");
        if (response) {
            setActivatePoints(response)
        }
    }

    useEffect(() => {
        if(!!brandData?.features?.enableWalletActivation) {
            fetchActivePoints()
        }
    }, [])

    return <>
        <IconButton
            color="inherit"
            onClick={handleClick}
        >
            { props.showDrawer ? <Close /> : <AccountCircle /> }
        </IconButton>

        <Drawer
            open={props.showDrawer}
            onClose={handleClose}
            sx={{ zIndex: (theme) => theme.zIndex.appBar - 1}}
        >
            <Box sx={{width: 900, padding: 4, paddingTop: 17}}>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, width: 300}}>
                    <AccountMenu
                        selectedCustomerNumber={props.selectedCustomerNumber}
                        setCurrentAccount={props.setCurrentAccount}
                        customers={props.customers}
                        showPoints={props.showPoints}
                        activatePoints={activatePoints}
                        closeMenu={() => { props.setShowDrawer(false)}}
                    />

                    <div style={{height: 40}} />

                    <BurgerMenu
                        onLogOutClick={props.onLogOutClick}
                        navigate={(path, options) => {props.setShowDrawer(false); navigate(path, options);}}
                        teamAccess={props.teamAccess}
                    />
                </Box>
            </Box>
        </Drawer>
    </>
}

const AccountMenu = (props) => {
    const navigate = useNavigate()
    const { brandData, userData} = useContext(AppContext)

    const isStarkBrand = brandData && brandData.BrandID === 87;
    
    return (
        <Box style={{display: 'flex', flexDirection: 'column', gap: 10}}>
            {isStarkBrand && props.customers?.length > 1 ?
                <CustomSelectHeader2
                    value={props.selectedCustomerNumber}
                    options={props.customers}
                    onChange={(e) => props.setCurrentAccount(e.target.value)}
                    wrapperStyle={{ marginBottom: 10 }}
                />
                :
                <CustomSelectHeader2
                    disabled
                    value={1}
                    options={[{value: 1, label: userData.userName}]}
                    wrapperStyle={{ marginBottom: 10 }}
                />
            }

            { props.showPoints &&
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                    {!!brandData?.features?.enableWalletActivation ?
                        <Box display={'flex'} justifyContent={'space-between'} >
                            <ContentNormal style={{color: 'inherit'}} >
                                { translate("_HEADER_ACTIVATED_POINTS_", "Aktiverede point") }:
                            </ContentNormal>
                            <ContentNormal style={{color: 'inherit'}} >
                                { pointsConvertor(brandData, props.activatePoints?.walletBalance || '0', ' ') }
                            </ContentNormal>
                        </Box>
                        :
                        <Box display={'flex'} justifyContent={'space-between'} >
                            <ContentNormal style={{color: 'inherit'}} >
                                { translate("_PROFILE_MENU_POINTS_", "Point") }:
                            </ContentNormal>
                            <ContentNormal style={{color: 'inherit'}} >
                                { pointsConvertor(brandData, userData.walletAmountPoints || '0', ' ') }
                            </ContentNormal>
                        </Box>
                    }

                    {!!brandData?.features?.enableWalletActivation &&
                        <Box display={'flex'} justifyContent={'space-between'} >
                            <ContentNormal style={{color: 'inherit'}} >
                                {translate("_HEADER_AWAITING_POINTS_", "Ventende point")}:
                            </ContentNormal>
                            <ContentNormal style={{color: 'inherit'}} >
                                {pointsConvertor(brandData, props.activatePoints?.walletActivationBalance || '0', ' ')}
                            </ContentNormal>
                        </Box>
                    }

                    {brandData?.features?.claims &&
                        <MenuButton
                            showArrow
                            onClick={() => {
                                props.closeMenu()
                                Activity({ activityType: 'click', activityName: 'menu', activityArgument: 'Report', activityArgumentID: '' })
                                navigate('/indberetning')
                            }}
                        >
                            {translate("_MENU_REPORTING_", "Indberetning")}
                        </MenuButton>
                    }

                    {!!brandData?.features?.enableWalletActivation &&
                        <Box style={{marginTop: 10, marginBottom: 10}}>
                            <ButtonLarge1 style={{width: '100%'}} onClick={() => {
                                props.closeMenu()
                                Activity({ activityType: 'click', activityName: 'menu', activityArgument: 'Profile', activityArgumentID: '' })
                                navigate('/profile', { state: { scrollToActivationSales: true } })
                            }}>
                                { translate("_HEADER_ACTIVATE_POINTS_", "Aktivér dine point")}
                            </ButtonLarge1>
                        </Box>
                    }
                </Box>
            }
        </Box>
    )
}

const BurgerButton = (props) => {
    const { langCode, accessibleShops } = useContext(AppContext);
    const navigate = useNavigate()
    const handleClick = () => { props.setShowDrawer(!props.showDrawer) };
    const handleClose = () => { props.setShowDrawer(false) };

    const mainShop = accessibleShops.find(s => s.moduleName === 'Shop')
    const otherShops = accessibleShops.filter(s => s.moduleName !== 'Shop')

    return <>
        <IconButton color="inherit" onClick={handleClick}>
            { props.showDrawer ? <Close /> : <MenuIcon /> }
        </IconButton>
        <Drawer
            open={props.showDrawer}
            onClose={handleClose}
            sx={{ zIndex: (theme) => theme.zIndex.appBar - 1 }}
        >
            <Box sx={{display: 'flex', flexDirection: 'column', width: 900, padding: 4, paddingTop: 17, gap: 1}}>
                {mainShop &&
                    <MenuButton style={{fontWeight: 600}} key={mainShop.ShopID} onClick={() => {
                        props.setShowDrawer(false)
                        Activity({activityType: 'click', activityName: 'menu', activityArgument: 'Shop', activityArgumentID: mainShop.ShopID})
                        navigate(`shop/${mainShop.ShopID}`)
                    }} >
                        {mainShop?.translations?.find((translation) => translation.languageKey === langCode)?.moduleName ?? mainShop.moduleName}
                    </MenuButton>
                }
                {otherShops.map(s => (
                    <MenuButton key={s.ShopID} onClick={() => {
                        props.setShowDrawer(false)
                        Activity({activityType: 'click', activityName: 'menu', activityArgument: 'Shop', activityArgumentID: s.ShopID})
                        navigate(`shop/${s.ShopID}`)
                    }} >
                        {s?.translations?.find((translation) => translation.languageKey === langCode)?.moduleName ?? s.moduleName}
                    </MenuButton>
                ))}
                {props.hofs.map(hof => {
                    const link = `/halloffame/${hof.HalloffameID}`
                    const title = hof.translations?.find((translation) => translation.languageKey === langCode)?.moduleName ?? hof.moduleName
                    return (
                        <MenuButton key={title}
                            onClick={() => {
                                props.setShowDrawer(false);
                                Activity({ activityType: 'click', activityName: 'menu', activityArgument: 'Halloffame', activityArgumentID: hof.HalloffameID })
                                navigate(link);
                            }}
                        >
                            {title}
                        </MenuButton>
                    )
                })}
                <Box marginTop={7} width={300}>
                    <BurgerMenu
                        onLogOutClick={props.onLogOutClick}
                        navigate={(path, options) => {props.setShowDrawer(false); navigate(path, options);}}
                        teamAccess={props.teamAccess}
                    />
                </Box>
            </Box>
        </Drawer>
    </>
}

const BurgerMenu = (props) => {
    const { brandData, styleGuide } = useContext(AppContext)
    const isStarkBrand = brandData && brandData.BrandID === 87;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <MenuButton
                showArrow={true}
                onClick={() => {
                    Activity({ activityType: 'click', activityName: 'menu', activityArgument: 'Profile', activityArgumentID: '' })
                    props.navigate('/profile')
                }}
            >
                {translate("_MENU_MY_PROFILE_", "Din Profil / Point")}
            </MenuButton>
            <MenuButton
                showArrow={true}
                onClick={() => {
                    Activity({ activityType: 'click', activityName: 'menu', activityArgument: 'Profile', activityArgumentID: '' })
                    props.navigate('/profile', {state: {scrollToSettings: true}})
                }}
            >
                {translate("_MENU_PROFILE_SETTINGS_", "Indstillinger")}
            </MenuButton>
            {props.teamAccess &&
                <MenuButton
                    onClick={() => {
                        Activity({ activityType: 'click', activityName: 'header', activityArgument: 'Team', activityArgumentID: '' })
                        props.navigate('/team')
                    }}
                >
                    Team
                </MenuButton>
            }
            <MenuButton
                onClick={() => {
                    Activity({activityType: 'click', activityName: 'menu', activityArgument: 'Help', activityArgumentID: ''})
                    props.navigate("/help", { state: { scrollToFaq: true } })
                }}
            >
                FAQ
            </MenuButton>
            <MenuButton 
                onClick={() => {
                    Activity({activityType: 'click', activityName: 'menu', activityArgument: 'Help', activityArgumentID: ''})
                    props.navigate("/help", { state: { scrollToContact: true } })
                }}
            >
                {translate("_MENU_CONTACT_", "Kontakt")}
            </MenuButton>

            {isStarkBrand ?
                <MenuButton
                    style={{ color: styleGuide.color16 }}
                    onClick={() => {
                        Activity({ activityType: 'click', activityName: 'header', activityArgument: 'goToStark', activityArgumentID: '' });
                        window.location.href = 'https://www.stark.dk';
                    }}
                >
                    STARK.dk
                </MenuButton>
                :
                <Box height={20}></Box>
            }
            <MenuButton style={{ color: styleGuide.color16 }}
                onClick={() => {
                    Activity({activityType: 'click', activityName: 'menu', activityArgument: 'Logout', activityArgumentID: ''})
                    props.onLogOutClick()
                }}
            >
                {translate("_MENU_LOG_OUT_", "Log ud")}
            </MenuButton>
        </Box>
    )
}

const WishlistButton = () => {
    const navigate = useNavigate()
    return (
        <IconButton 
            color="inherit" 
            onClick={() => {
                Activity({activityType: 'click',activityName: 'header',activityArgument: 'Wishlist',activityArgumentID: ''})
                navigate('/profile', {state:{scrollToWishlist:true}})
            }}
        >
            <FavoriteBorder />
        </IconButton>
    )
}

const SubtleHeaderButton = (props) => {
    const { styleGuide } = useContext(AppContext)
    const headerButtonStyle = {
        fontFamily: styleGuide.secondaryFontFamily,
        fontSize: 14,
        fontStyle: 'normal',
        cursor: 'pointer',
    }

    return (
        <Typography
            color="inherit"
            onClick={props.onClick}
            style={{...headerButtonStyle, ...props.style}}
        >
            <Translate translationKey={props.translationKey}>
                {props.fallbackText || props.children}
            </Translate>
        </Typography>
    )
}

const MenuButton = (props) => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between', cursor: 'pointer'}} onClick={props.onClick}>
            <ContentNormal style={{color: 'inherit', ...props.style}}>
                <Translate translationKey={props.translationKey}>
                    {props.fallbackText || props.children}
                </Translate>
            </ContentNormal>
            { props.showArrow && <KeyboardArrowRight style={{marginRight: -5}} /> }
        </Box>
    )
}

export default Header5;
