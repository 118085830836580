import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import Headline4 from "components/elements/Headline4"
import ContentNormal from 'components/elements/ContentNormal';
import ButtonLarge1 from 'components/elements/ButtonLarge1';
import ReactMarkdown from 'react-markdown';
import PhoneNumberPrompt from './phoneNumberPrompt';
import AutomaticActivationPrompt from './automaticActivationPrompt';
import { TaxPercentageForm } from '../taxPercentageForm/TaxPercentageForm';
import RoundIconButton2 from 'components/elements/RoundIconButton2';
import { AppContext } from 'contexts/AppContext';
import ArrowLeft from 'components/icons/ArrowLeft';
import ArrowRight from 'components/icons/ArrowRight';
import { useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import ContentSmall from 'components/elements/ContentSmall';
import { translate } from 'utils/Translate';

const UserPrompt = (props) => {
    const { styleGuide, brandData } = useContext(AppContext)
    const [page, setPage] = useState(0)
    const [userPrompts, setUserPrompts] = useState([])
    const navigate = useNavigate()
    const localStorageKey = 'hiddenUserPromptIds'

    const prevPage = () => {
        if(page > 0) {
            setPage(page-1)
        }
    }

    const nextPage = () => {
        if(page < userPrompts.length-1) {
            setPage(page+1)
        }
    }

    const onButtonClick = (link) => {
        props.close()
        navigate(link)
    }

    const onConfirm = () => {
        if(page < userPrompts.length-1) {
            nextPage()
        } else {
            props.close()
        }
    }

    const loadHiddenUserPromptIds = () => {
        return JSON.parse(localStorage.getItem(localStorageKey) || '[]')
    }

    const handleDontShowAgain = (userPromptId, checked) => {
        let hiddenUserPromptIds = loadHiddenUserPromptIds()
        if(checked) {
            hiddenUserPromptIds.push(userPromptId)
        } else {
            hiddenUserPromptIds = hiddenUserPromptIds.filter(id => id !== userPromptId)
        }
        localStorage.setItem(localStorageKey, JSON.stringify(hiddenUserPromptIds))
        setUserPrompts(userPrompts.map(up => up.UserPromptID === userPromptId ? {...up, checked} : up))
    }

    useEffect(() => {
        let prompts_ = props.prompts ?? []

        const enableMobileNumberUpdates = brandData?.features?.enableMobileNumberUpdates
        if(!enableMobileNumberUpdates) {
            prompts_ = prompts_.filter(p => p.promptType !== 'PHONE')
        }
    
        const disableAutoActivation = brandData?.features?.disableAutoActivateFeature
        if(disableAutoActivation) {
            prompts_ = prompts_.filter(p => p.promptType !== 'AUTOMATIC_ACTIVATION')
        }
        
        const hiddenUserPromptIds = loadHiddenUserPromptIds()
        prompts_ = prompts_.filter(up => !hiddenUserPromptIds.includes(up.UserPromptID))

        prompts_.sort((a, b) => a.poolOrder - b.poolOrder)
        
        setUserPrompts(prompts_)
    }, [props.prompts])

    if(userPrompts.length <= 0) {
        return <></>
    }

    const userPrompt = userPrompts[page]
    const canGoPrevPage = page > 0
    const canGoNextPage = page < userPrompts.length-1
    return (
        <Modal show={props.show} onHide={props.close} centered size='lg' cl>
            <Modal.Header className="pb-0 border-0" closeButton style={{ fontSize: 9 }} />
            <Modal.Body>
                <Box display={'flex'} justifyContent={'space-between'} minHeight={200} >
                    {userPrompts.length > 1 &&
                        <Box alignContent={'center'} width={80}>
                            {userPrompts.length > 1 &&
                                <RoundIconButton2
                                    onClick={prevPage}
                                    style={{cursor: canGoPrevPage ? 'pointer' : 'default', backgroundColor: styleGuide.color4, opacity: canGoPrevPage ? 1 : 0.5 }}
                                    icon={<ArrowLeft fill={styleGuide.color1} />}
                                />
                            }
                        </Box>
                    }

                    <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} minHeight={150} width={'100%'} paddingLeft={2} paddingRight={2} paddingBottom={2} >
                        <Box>
                            <Headline4>{userPrompt.title}</Headline4>
                            <Box marginTop={3}>
                                <ContentNormal>
                                    <ReactMarkdown>
                                        {userPrompt.bodyText}
                                    </ReactMarkdown>
                                </ContentNormal>
                            </Box>
                        </Box>

                        { userPrompt.promptType === 'CUSTOM' && userPrompt.buttonText && userPrompt.buttonLink &&
                            <Box marginTop={2} >
                                <ButtonLarge1 onClick={() => onButtonClick(userPrompt.buttonLink)} style={{width: 250}}>
                                    {userPrompt.buttonText}
                                </ButtonLarge1>
                            </Box>
                        }

                        { userPrompt.promptType === 'PHONE' &&
                            <PhoneNumberPrompt onConfirm={onConfirm} />
                        }

                        { userPrompt.promptType === 'AUTOMATIC_ACTIVATION' &&
                            <AutomaticActivationPrompt onConfirm={onConfirm} />
                        }

                        { userPrompt.promptType === 'TAX_PERCENTAGE' &&
                            <Box display={'flex'} alignItems={'center'} gap={2}>
                                <ContentNormal style={{fontWeight: 'bold'}} >
                                    { translate('_ACTIVATION_WITHDRWAL_PERCENTAGE_TEXT_', `Indtast din trækprocent:`) }
                                </ContentNormal>
                                <TaxPercentageForm onConfirm={onConfirm} hideText={true} />
                            </Box>
                        }
                    </Box>
                    {userPrompts.length > 1 &&
                        <Box justifyItems={'end'} alignContent={'center'} width={80} >
                            {userPrompts.length > 1 &&
                                <RoundIconButton2 
                                    onClick={nextPage}
                                    style={{
                                        cursor: canGoNextPage ? 'pointer' : 'default',
                                        backgroundColor: styleGuide.color4,
                                        opacity: canGoNextPage ? 1 : 0.5,
                                        
                                    }}
                                    icon={<ArrowRight fill={styleGuide.color1} />} 
                                />
                            }
                        </Box>
                    }
                </Box>

            </Modal.Body>
            {(userPrompt.isHideable === 1 ||  userPrompts.length > 1) &&
                <Modal.Footer style={{justifyContent: 'space-between'}}>
                    <Grid container>
                        <Grid item xs={4} >

                        </Grid>
                        <Grid item xs={4} alignContent={'center'} >
                            {userPrompts.length > 1 &&
                                <Box display={'flex'} gap={1} justifySelf={'center'}>
                                    {userPrompts.map((_, i) => {
                                        const color = i === page ? styleGuide.color7 : styleGuide.color4
                                        return <div 
                                            style={{borderRadius: '100%', backgroundColor: color, height: 10, width: 10, cursor: 'pointer'}}
                                            onClick={() => setPage(i)}
                                            key={i}
                                        />
                                    })}
                                </Box>
                            }
                        </Grid>
                        <Grid item xs={4} >
                            {userPrompt.isHideable === 1 &&
                                <Box display={'flex'} gap={1} justifySelf={'end'}>
                                    <input type='checkbox' checked={userPrompt.checked} onChange={v => handleDontShowAgain(userPrompt.UserPromptID, v.target.checked)} />
                                    <ContentSmall>
                                        Vis ikke igen
                                    </ContentSmall>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Modal.Footer>
            }
        </Modal>
    )
}

export default UserPrompt;
