import React, { useContext } from 'react'
import './css/ButtonStyles.css'
import { AppContext } from 'contexts/AppContext';
import {createUseStyles} from 'react-jss'
import CircularIndeterminate from "components/blocks/progressBar/progressBar";

const jss = createUseStyles({
    iconButtonLarge: {
        backgroundColor: (styleGuide) => styleGuide.color1,
        color: (styleGuide) => styleGuide.color1,
        borderRadius: (styleGuide) => styleGuide.buttonBorderRadius,
        height: (styleGuide) => styleGuide.buttonLargeHeight,
        width: (styleGuide) => styleGuide.buttonLargeHeight, // height = width
        fontWeight: '600',
        border: (styleGuide) => `1px solid ${styleGuide.color4}`,
        lineHeight: (styleGuide) => styleGuide.buttonLargeLineHeight,
        padding: '13px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        
        '&:hover':{
            backgroundColor: (styleGuide) => styleGuide.color12,
            border: 'none',
            '& path': {
                fill: (styleGuide) => styleGuide.color1,
                stroke: (styleGuide) => styleGuide.color1,
            }
        }
    },
    iconButtonLargeInverse: {
        backgroundColor: (styleGuide) => styleGuide.color12,
        color: (styleGuide) => styleGuide.color1,
        borderRadius: (styleGuide) => styleGuide.buttonBorderRadius,
        height: (styleGuide) => styleGuide.buttonLargeHeight,
        width: (styleGuide) => styleGuide.buttonLargeHeight, // height = width
        fontWeight: '600',
        lineHeight: (styleGuide) => styleGuide.buttonLargeLineHeight,
        padding: '13px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        '& path': {
            fill: (styleGuide) => styleGuide.color1,
            stroke: (styleGuide) => styleGuide.color1,
        },
        '&:hover':{
            backgroundColor: (styleGuide) => styleGuide.color1,
            border: (styleGuide) => `1px solid ${styleGuide.color4}`,
            '& path': {
                stroke: (styleGuide) => styleGuide.color7,
            }
        }
    }
})


const IconButtonLarge = (props) => {
    const { styleGuide } = useContext(AppContext)
    const classes = jss(styleGuide)
    const inverse = (typeof props.inverse === 'boolean') ? props.inverse : false

    return (
        <button
            onClick={props.onClick}
            style={{...props.style}}
            disabled={props.disabled}
            className={!inverse ? classes.iconButtonLarge : classes.iconButtonLargeInverse}
        > 
            {props.loading
                ? <CircularIndeterminate size={16} color={styleGuide.color4} />
                : props.icon
            }
        </button>
    )
}

export default IconButtonLarge;