import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";
import Box from '@mui/material/Box';

export default function CircularIndeterminate(props) {
  const { styleGuide } = useContext(AppContext);
    
  return (
    <Box sx={{ ...props.style, display: 'flex' }}>
          <CircularProgress
              disableShrink
              size={props.size}
              sx={{
                color: props.color || styleGuide.color16,
              }}
          />
    </Box>
  );
}
