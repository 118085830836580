import { AppContext } from 'contexts/AppContext'
import React, { useContext } from 'react'

// export const pointsConvertor = (val, pointSuffix = "pt") => (+val.split(" ")[0] > 999) ? (+val.split(" ")[0] / 1000).toFixed(3) + ` ${pointSuffix}` : +val.split(" ")[0] + ` ${pointSuffix}`;

export const pointsConvertor = (brandData, val, pointSuffix = "pt") => {
    const {disablePoints, enableOnePointUsers} = brandData?.features

    return !!disablePoints ? " " : !!enableOnePointUsers ? val < 1 ? `0 ${pointSuffix}` : `1 ${pointSuffix}` : !val ? `0 ${pointSuffix}` : `${(Number(`${val}`.split(" ")[0])).toLocaleString('da-DK')} ${pointSuffix}`
}


export const scrollToDiv = (id) => {
    if (!id || !document.getElementById(id)) return;
    window.scrollTo({
        top: document.getElementById(id).getBoundingClientRect().top + window.pageYOffset - 150,
        behavior: 'smooth',
    });
}

export const scrollToRef = (element) => {
    if (!element?.current) return;
    window.scrollTo({
        top: element.current.getBoundingClientRect().top + window.pageYOffset - 150,
        behavior: 'smooth',
    });
}

export const newLineToBreakTag = (str) => {
    const newlineRegex = /(\r\n|\n\r|\r|\n)/g

    if(!str) return ''
    if (typeof str === 'number') {
        return str
    } else if (typeof str !== 'string') {
        return str || ''
    }

    return str.split(newlineRegex).map(function (line, index) {
        if (line.match(newlineRegex)) {
            return React.createElement('br', { key: index })
        }
        return line
    })
}

export const parseLinks = (str) => {
    const parseLinksRegex = /(https?:\/\/[^\s]+)/g
    const newlineRegex = /(\r\n|\n\r|\r|\n)/g

    if(!str) return ''
    if (typeof str === 'number') {
        return str
    } else if (typeof str !== 'string') {
        return ''
    }

    return str.split(newlineRegex).map(function (line, index) {
        if (line.match(newlineRegex)) {
            return React.createElement('br', { key: index })
        } else {
            return line.split(parseLinksRegex).map(function (line) {
                if (line.match(parseLinksRegex)) {
                    return React.createElement('a', {href: line, target: "_blank"}, line)
                }
                return line
            })
        }
    })
}

export const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email)

export const isNumber = (text) => /^\d+$/.test(text)

export const isPhoneOrEmail = (text) => /^[\w-+.]+@[\w-+]+\.[a-zA-Z]{2,4}|^(\+45|0045)?[ ]*[\d]{8}$/.test(text)

export const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const truncateWithEllipses = (text, max) => text?.length > max ? `${text.substr(0, max)}...` : text;

export const isInViewport = (cssSelector) => {
    if(!cssSelector) return false

    const box = document.querySelector(cssSelector);
    if(!box) return false

    const rect = box.getBoundingClientRect();
    
    const isInViewport = rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    
    return isInViewport;
}

export const scrollInterceptorForImageLoad = () => {

    document.addEventListener("DOMContentLoaded", function () {
        console.log('scrollInterceptorForImageLoad')
        let lazyBackgrounds = [].slice.call(document.querySelectorAll(".lazy-background"));
        console.log(lazyBackgrounds)
        if ("IntersectionObserver" in window) {
            let lazyBackgroundObserver = new IntersectionObserver(function (entries, observer) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("lazy-visible");
                        entry.target.classList.remove("lazy-placeholder");
                        lazyBackgroundObserver.unobserve(entry.target);
                    }
                });
            });

            lazyBackgrounds.forEach(function (lazyBackground) {
                lazyBackgroundObserver.observe(lazyBackground);
            });
        }
    });
}

export const numericRangeArray = (start, stop, step) => Array.from(
    { length: (stop - start) / step + 1 },
    (_value, index) => start + index * step
);

// export const validatePhoneNumber = (phoneNumber, countryCode) => {
//     phoneNumber = phoneNumber.replace(/ /g, '');
//     if (phoneNumber.search(/^\+(44|45|46|47)[\d]{8,}$/) > -1) {
//         return phoneNumber;
//     } else if (phoneNumber.search(/^(44|45|46|47)[\d]{8,}$/) > -1) {
//         return '+' + phoneNumber;
//     } else if (phoneNumber.search(/^[\d]{8,}$/) > -1) {
//         return countryCode + phoneNumber;
//     } else {
//         return countryCode + phoneNumber;
//     }
// }

export const isValidPhoneNumber = (countryCode, numberOfCiphers, phoneNumber) => {
    if(
        phoneNumber &&
        phoneNumber?.length === numberOfCiphers
    ){
        return true
    }
    else {
        return false
    }
}

export const RawHTML = ({children, className = ""}) => 
<div className={className} dangerouslySetInnerHTML={{ __html: children?.replace(/(\r\n|\n\r|\r|\n)/g, '<br />')}} />