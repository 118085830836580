import React from 'react'
import { AppContext } from "contexts/AppContext";
import { useEffect, useContext, useState } from 'react'
import RegisterForm from './RegisterForm'
import RegisterSuccess from './RegisterSuccess'
import RegisterError from './RegisterError'
import "./Register.css"
import RegisterCreated from './RegisterCreated';
import RegisterPageBanner1 from 'components/blocks/registerPageBanner/RegisterPageBanner1';
import { Box, Grid } from '@mui/material';
import RegisterInfo from './RegiserInfo';

const Register = () => {
    const { brandData, langCode } = useContext(AppContext);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    let brandSlug = brandData && brandData.brandSlug;
    const isOkBrand = brandData && brandData.BrandID === 187 // for OK brand
    
    let {frontPageBannerColor,frontPageBannerContent,frontPageBannerTitle,frontPageTextColorBlack, enableFrontPageFAQButton=false}= brandData && brandData.features
    return (
        <div>
            <Grid size={12}>
                <RegisterPageBanner1
                    title={frontPageBannerTitle && typeof frontPageBannerTitle === 'object' ?
                        frontPageBannerTitle.hasOwnProperty(langCode) ? frontPageBannerTitle[langCode] : '' : frontPageBannerTitle ? frontPageBannerTitle : ''}
                    text={frontPageBannerContent && typeof frontPageBannerContent === 'object' ?
                        frontPageBannerContent.hasOwnProperty(langCode) ?  frontPageBannerContent[langCode] : '' : frontPageBannerContent ? frontPageBannerContent : ''}
                />
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                >
                <Grid item xs={12} sm={6} >
                        {!success && !error && <RegisterForm setError={setError} setSuccess={setSuccess} />}
                        {success && !isOkBrand && <RegisterSuccess />}
                        {success && !!isOkBrand && <RegisterCreated />}
                        {error && <RegisterError
                        errorMessage='Du er allerede oprettet i klubben.'
                        subMessage={`Venligst log ind på <a class='link' href='http://${brandSlug}.upgrader.club'>${brandSlug}.upgrader.club</a>`}/>}
                </Grid>
                {!!isOkBrand && 
                    <Grid item xs={12} sm={6}>
                        <RegisterInfo />
                    </Grid>
                }
                
            </Grid>
            
        </div>
    )
}

export default Register