import React, { useContext } from 'react'
import { Select, MenuItem, FormControl, Checkbox, ListItemText, Box } from '@mui/material';
import './css/InputStyles.css'
import { AppContext } from 'contexts/AppContext';
import { KeyboardArrowDown } from '@mui/icons-material';
import ContentNormal from './ContentNormal';

const CustomSelectHeader2 = (props) => {
    const { styleGuide } = useContext(AppContext)

    const selectStyle = {
        fontFamily: styleGuide.secondaryFontFamily,
        borderBottom: '0px solid white !important',
        "&:hover:before": {
            borderBottom : `0px solid white !important`,
        },
        "&:hover:after": {
            borderBottom : `0px solid white !important`,
        },
        "&:before": {
            borderBottom : `0px solid white !important`,
        },
        "&:after": {
            borderBottom : `0px solid white !important`,
        },
    }

    const menuItemStyle = {
        fontFamily: styleGuide.secondaryFontFamily,
        ".MuiPaper-root": {
            borderRadius : `${styleGuide.selectDropdownBorderRadius}`,
        },
        "ul": {
            padding: '0 0 0 0'
        },
        "li:hover": {
            backgroundColor: `${styleGuide.color16} !important`,
            color: `white`
        },
        "svg": {
            color: `white`
        }
    }
    
    return (
        <div style={props.wrapperStyle}>
            {props.disabled ?
                <Box backgroundColor={'#efefef'} padding={'11px 13px'} style={{borderRadius: styleGuide.buttonBorderRadius}} >
                    <ContentNormal>
                        {props.options.length ? props.options[0].label : ''}
                    </ContentNormal>
                </Box>
            :
                <FormControl fullWidth variant="filled" error={props.error} hiddenLabel={true}>
                    <Select
                        labelId="select-label"
                        style={{borderRadius: styleGuide.buttonBorderRadius, height: 45, ...props.style}}
                        value={props.value}
                        className={props.className}
                        onChange={props.onChange}
                        IconComponent={KeyboardArrowDown}
                        sx={selectStyle}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    ...menuItemStyle,
                                    minWidth: 'auto',
                                    width: 'auto' 
                                }
                            },
                        }}
                    >
                        {(props.options && props.options.length)
                            ? props.options.map(elem => {
                                    return (
                                        <MenuItem key={elem.value} value={elem.value} style={{fontFamily: styleGuide.secondaryFontFamily, ...props.customMenuStyle}}>
                                            { props.multiple 
                                                ?   <>
                                                        <Checkbox checked={!!props.value && props.value.indexOf(elem.value) > -1} />
                                                        <ListItemText primary={elem.label} />
                                                    </>
                                                : elem.label}
                                        </MenuItem>
                                    )
                                })
                            : null 
                        }
                    </Select>
                </FormControl>
            }
        </div>
    )
}

export default CustomSelectHeader2;
