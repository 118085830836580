import Translate from "utils/Translate";
import React, { useContext } from 'react'
import ArrowRightAlt from 'components/icons/ArrowRightAlt';
import {createUseStyles} from 'react-jss'
import { AppContext } from 'contexts/AppContext';

const jss = createUseStyles({
    buttonSmall1: {
        backgroundColor: (styleGuide) => styleGuide.color16,
        color: (styleGuide) => styleGuide.color1,
        borderRadius: (styleGuide) => styleGuide.buttonBorderRadius-4,
        fontFamily: (styleGuide) => styleGuide.secondaryFontFamily,
        height: (styleGuide) => styleGuide.buttonSmallHeight,
        fontSize: (styleGuide) => styleGuide.buttonSmallFontSize,
        lineHeight: (styleGuide) => styleGuide.buttonSmallLineHeight,
        fontWeight: '600',
        border: 'none',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        
        '&:hover':{
            backgroundColor: (styleGuide) => styleGuide.color17,
        },
        
        '&:disabled':{
            backgroundColor: (styleGuide) => styleGuide.color16,
            opacity: 0.7,
            cursor: 'not-allowed'
        }
    }
})

const ButtonSmall1 = (props) => {
    const { styleGuide } = useContext(AppContext)
    const classes = jss(styleGuide)
    const showIcon = (typeof props.showIcon === 'boolean') ? props.showIcon : true
    
    return (
        <button
            title={props.title || ""}
            onClick={props.onClick}
            style={{ ...props.style, backgroundColor: styleGuide.color16 }}
            disabled={props.disabled}
            className={classes.buttonSmall1}
        >
            {props.configuredLabel ? <span>{props.configuredLabel}</span> : <Translate translationKey={props.translationKey}>{props.fallbackText || props.children}</Translate>}
            {showIcon 
                ?   props.icon 
                        ? props.icon
                        : <ArrowRightAlt style={{ marginLeft:'34px' }}  fill={styleGuide.color1} />
                :   null
            }
        </button>
    )
}

export default ButtonSmall1;