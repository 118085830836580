import { AppContext } from 'contexts/AppContext';
import { useContext } from 'react'
import IconButtonMedium from "components/elements/IconButtonMedium";
import ButtonMedium1 from 'components/elements/ButtonMedium1';
import Headline6 from 'components/elements/Headline6';
import CaptionText from 'components/elements/CaptionText';
import Image from 'components/elements/Image';
import Heart2 from 'components/icons/Heart2';
import LabelTag1 from './LabelTag1';
import {createUseStyles} from 'react-jss'
import ExplainerTextTiny from './ExplainerTextTiny';
import { translate } from 'utils/Translate';
import { useNavigate } from 'react-router-dom';
import { setupBasketData } from 'utils/setup';
import { pointsConvertor } from "../../utils/general";
import Activity from "utils/Activity";


const jss = createUseStyles({
    ProductWrapper: {
        height: (styleGuide) => styleGuide.productLargeHeightMobile,
        width: (styleGuide) => styleGuide.productLargeWidthMobile,
    },
    '@media screen and (min-width: 768px)': {
        ProductWrapper: {
            height: (styleGuide) => styleGuide.productLargeHeightTab,
            width: (styleGuide) => styleGuide.productLargeWidthTab
        },
    },
    '@media screen and (min-width: 1280px)': {
        ProductWrapper: {
            height: (styleGuide) => styleGuide.productLargeHeight,
            width: (styleGuide) => styleGuide.productLargeWidth,
        },
    }
})

const productInfoHeight = 100

const ProductLarge = (props) => {
    const { styleGuide, userData, setBasket, brandData } = useContext(AppContext)
    const navigate = useNavigate()
    const classes = jss(styleGuide)
    const showTag = !!props.showTag
    const hideLike = !!props.hideLike || brandData?.features?.disableWishlist
    const imageHeight = styleGuide.productInfoAsOverlay ? `100%` : `calc(100% - ${productInfoHeight}px)`
    const {
        points,
        text1,
        text2,
        wishlistCallback,
        wishlistIconStyles,
        imageSrc,
        imageStyles,
        text1Styles,
        text2Styles,
        pointsStyles,
        tagOptions,
        tagStyles,
        liked,
        storeScrollPosition,
        imageBackgroundColor,
    } = props
    const productInfoStrip = <ProductInfo {...{text1, text1Styles, text2, text2Styles, points, pointsStyles}}/>
    const isLikedProduct = (typeof liked === 'boolean') ? liked : false
    const likeUnlike = async (e) => {
        if(wishlistCallback) await wishlistCallback(e)
        await setupBasketData({userData, setBasket})
    }

    const onClick = (event) => {
        if (props.productID) {
            Activity({ activityType: 'click', activityName: props.activityName, activityArgument: 'Product', activityArgumentID: props.productID });
            if(!event.ctrlKey && event.button !== 1){
                onGoToProduct()
            }
        }
    }

    const onGoToProduct = () => {
        if(storeScrollPosition === true) {
            sessionStorage.setItem('scrollPosition', `${window.scrollY || ''}`);
        }
    }

    return (
        <div style={{display: 'flex', position: 'relative'}}>
            {!hideLike
                ?   <IconButtonMedium
                        inverse={isLikedProduct ? true : false}
                        style={{
                            position: 'absolute',
                            top: 52,
                            right: 24,
                            zIndex: 1,
                            ...wishlistIconStyles
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                            likeUnlike(e)}
                        }
                        icon={
                            <Heart2
                                fill='white'
                                style={{ height: 10, width: 10 }}
                                stroke={styleGuide.color7}
                                strokeWidth="1.5px"
                            />
                        }
                    />
                :   null 
            }
            <a
                className={`${classes.ProductWrapper} ${props.className ? props.className : ''}`}
                style={{
                    display: 'flex',
                    position: 'relative',
                    flexDirection: 'column',
                    cursor: 'pointer',
                    ...props.style
                }}
                onClick={(event) => {
                    onClick(event)
                }}
                onAuxClick={(event) => {
                    if (event.button === 1) {
                        onClick(event)
                    }
                }}
                href={`/product/${props.productID}`}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: showTag ? 'space-between' : 'flex-end'
                }}
                >
                    {
                        showTag
                            ? <LabelTag1 {...tagOptions}
                                style={{
                                    alignSelf: 'flex-start',
                                    marginTop: '20px',
                                    marginLeft: '20px',
                                    zIndex: 1,
                                    ...tagStyles
                                }} />
                            : null
                    }
                </div>
                
                <Image
                    src={imageSrc}
                    style={{
                        // alignSelf: 'center',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 0,
                        height: imageHeight,
                        width: '100%',
                        backgroundColor: imageBackgroundColor || styleGuide.productInfoBackground,
                        ...imageStyles
                    }}
                >
                    { !!styleGuide.productInfoAsOverlay ? productInfoStrip : null }
                </Image>
                { !styleGuide.productInfoAsOverlay ? productInfoStrip : null }
            </a>
        </div>
    )
}

const ProductInfo = (props) => {
    const { styleGuide, userData,brandData, langCode } = useContext(AppContext)
    const {
        points,
        text1,
        text2,
        text1Styles,
        text2Styles,
        pointsStyles,
    } = props

    const productPrice = points.match(/\d/g).join('')
    const walletAmountPoints = userData && !isNaN(userData.walletAmountPoints) ? Number(userData.walletAmountPoints) : 0
    const deficitPoints = !isNaN(productPrice) ? productPrice - walletAmountPoints : 0
    const isDeficit = !!brandData?.features?.disablePoints || !!brandData?.features?.skipWalletForOrders || brandData?.features?.enableOnePointUsers ? false : deficitPoints > 0 ? true : false
    const pointSuffix = translate("_POINTS_SHORT_", "pt")
    const taxProduct = userData.taxPercentage ? Math.ceil(points / (brandData.brandPointRatio || 1) * userData.taxPercentage / 100) : null

    return (
        <div 
            style={{
                position: 'absolute',
                bottom: 0,
                zIndex: 1,
                width: '100%',
                height: '100px',
                background: styleGuide.productInfoBackground,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '30px 40px',
                
            }}
        >
            {!!taxProduct && points &&
                <div
                    style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: styleGuide.productInfoBackground,
                        textAlign: "end",
                        borderTop: `1px solid ${styleGuide.color4}`,
                        height: 18
                    }}
                >
                    <ExplainerTextTiny
                        style={{ verticalAlign: "text-top", marginRight: 16, paddingTop: 0, paddingBottom: 0, lineHeight: '12px' }}
                        fallbackText={`${translate('_PRODUCT_TAXATION_TEXT_', 'Beskatning kr.')} ${pointsConvertor(brandData,taxProduct, '')}`}
                    />
                </div>
            }
            <div style={{ display: 'flex', flexDirection: 'column'}}>
                <Headline6 style={{ color: styleGuide.productInfoText1Color, ...text1Styles}}>
                    { text1 || '\u00A0' }
                </Headline6>
                <CaptionText style={{ color: styleGuide.productInfoText2Color, ...text2Styles }}>
                    { text2 || '\u00A0' }
                </CaptionText>
            </div>
            {brandData &&brandData.features && brandData.features.points === false ? null : <div>
                <ButtonMedium1
                    showIcon={false}
                    style={pointsStyles}>
                    {!!brandData?.features?.disablePrice ? brandData?.features?.productButtonText[langCode] : pointsConvertor(brandData,points, pointSuffix)}
                </ButtonMedium1>
            </div>}
            {
                !!isDeficit && 
                <div style={{position: 'absolute', bottom: 0, left: 0, right: 0, backgroundColor: styleGuide.color3, textAlign: 'center'}}>
                    <ExplainerTextTiny style={{verticalAlign: 'middle' }} fallbackText={`${translate('_USER_POINT_DEFICIENT_FOR_PRODUCT_TEXT_', 'Du mangler')} ${pointsConvertor(brandData,deficitPoints, translate("_POINTS_", "point"))}`} />
                </div>
            }
        </div>
    )
}

export default ProductLarge;