import Image from "components/elements/Image"
import { AppContext } from "contexts/AppContext"
import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import ExplainerText from "components/elements/ExplainerText"
import Headline3 from "components/elements/Headline3"
import WishlistDemo from "components/icons/WishlistDemo.svg"
import { translate } from "utils/Translate";
import ButtonLarge1 from "components/elements/ButtonLarge1"


const GoToProductsBanner = (props) => {
  const { accessibleShops, brandData } = useContext(AppContext);
  const ShopID = accessibleShops?.length > 0 ? accessibleShops[0].ShopID : null
  const navigate = useNavigate();
  const GoToProductsBannerImage = brandData.features.profileProductsInfoBannerImage
  const buttonText = translate('_GO_TO_PRODUCT_', 'Gå til produkter')


  return !GoToProductsBannerImage
    ? null
    : <div id={props?.id || ""} ref={props.wishlistRef} style={{ display: "flex", flexDirection: "column" }} className={props.customClass}>
      <Image
        src={`url(${GoToProductsBannerImage})`}
        style={{
          height: 'auto',
          width: 1800,
          maxWidth: '100%'

        }}
      >
        <div className="ProfileProductBanner" style={{ display: 'flex', flexDirection: 'column', gap: '1rem', maxWidth: 550 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ExplainerText>{translate('_PRODUCT_BANNER_NAME_', 'Tilføj til ønskeliste')}</ExplainerText>
            <img src={WishlistDemo} />
          </div>
          <Headline3>{translate('_PRODUCT_BANNER_DESCRIPTION_', 'Vi har samlet en masse nye og spændende produkter til dig')}</Headline3>
          <ButtonLarge1
            style={{ maxWidth: 255 }}
            onClick={() => navigate(`/shop/${ShopID}`)} >
            {buttonText}</ButtonLarge1>
        </div>
      </Image>
    </div>
}

export default GoToProductsBanner
