export const config = {
    ThemeONE:{},
    ThemeTWO:{},
    ThemeTHREE:{},
    ThemeFOUR:{
        disableShop: true,
        disablePoints: true,
        disableBasket:true,
        disableWishlist: true,
        disableOrder: true,
        disableStatistic:true,
        hideHomeCategoriesBlock: true,
        hideOthersLookingBlock: true,
        hideRecentlyViewedBlock: true,
        hideHighlightedProductsBlock: true,
        hideHaveYouSeenBlock: true,
        disablePopularProduct: true,
        hidePostFeatureProducts: true,
        disableProfileProductBanner: true,
        homeInformationBlock: true,
        enableProfileStatusBlock: true
    },
}