import { AppContext } from "contexts/AppContext";
import { useCallback, useContext, useEffect, useRef, useState } from "react"

const HoverableImage = (props) => {
  const { styleGuide } = useContext(AppContext)
  const [showHoverImage, setShowHoverImage ] = useState(false);
  
  return (
    <div
      onMouseEnter={() => {
        if(props.hoverSrc){
          setShowHoverImage(true)
        }
      }}
      onMouseLeave={() => {
        if(props.hoverSrc){
          setShowHoverImage(false)
        }
      }}
    >
      <BackgroundImage 
        onClick={props.onClick}
        size={props.size}
        className={props.className}
        src={props.src} 
        style={{...props.style, opacity: showHoverImage ? "0" : "1", backgroundColor: props.backgroundColor || styleGuide.productImageBackgroundColor }}
      >
        { props.children }
      </BackgroundImage>
      
      { props.hoverSrc && 
        <BackgroundImage
          onClick={props.onClick}
          size={props.size}
          className={props.className}
          src={props.hoverSrc}
          style={{...props.style, opacity: showHoverImage ? "1" : "0", backgroundColor: props.hoverBackgroundColor || styleGuide.productImageBackgroundColor }}
        >
          { props.children }
        </BackgroundImage>
      }
    </div>
  )
  }

const BackgroundImage = (props) => {
  const {className, onClick, src, position, size, style, children, lazy} = props
  const [source, setSource] = useState("");
  const imageRef = useRef(null);

  const callback = useCallback((entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setSource(src);
    }
  }, [src]);

  useEffect(() => {
    
    const observer = new IntersectionObserver(callback, {rootMargin: "150px"});
    observer.observe(imageRef.current);
    return () => {
      observer.disconnect();
    };
  }, [callback, imageRef]);

  return (
    <div 
    ref={imageRef}
    onClick={onClick}
    className={className || ''}
    style={{
      backgroundRepeat: 'no-repeat',
      backgroundImage: source,
      backgroundPosition: position || 'center 0',
      backgroundSize: size || 'cover',
      ...style
    }}>
      {children}
    </div>
  )
}

export default HoverableImage;