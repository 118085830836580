import { useContext, useEffect, useState } from "react";
import { AppContext } from "contexts/AppContext";
import Dash from "components/icons/Dash";
import Plus from "components/icons/Plus";

import ExplainerText from "./ExplainerText";
import { Alert } from "@mui/material";

const QuantityInput = (props) => {
    const { styleGuide } = useContext(AppContext);
    const {quantity, setQuantity, disabled, readOnly, showExplainer, variantionStock, shopLimit} = props;
    const [selectedQuantityText, setSelectedQuantityText] = useState(quantity);
    const [stockError, setStockError] = useState(null);
    const handleQuantityTextChange = (e) => {
        setSelectedQuantityText(e.target.value);
    };

    const handleQuantityTextBlur = async (e) => {
        const value = parseInt(e.target.value, 10);
        if (variantionStock && value > variantionStock) {
            setQuantity(variantionStock);
            setSelectedQuantityText(variantionStock);
            setStockError("Fejl: Kun " + variantionStock +  " på lager")
            return
        }
        if (shopLimit && value > shopLimit) {
            setQuantity(shopLimit);
            setSelectedQuantityText(shopLimit);
            setStockError("Fejl: Kun " + shopLimit +  " må vælges")
            return
        }
        if (!isNaN(value) && value > 0) {
            setSelectedQuantityText(e.target.value);
            await setQuantity(value);
        } else {
            setSelectedQuantityText('1');
            await setQuantity(1);
        }
    };

    const handleIncrementQuantity = async () => {
        const newQuantity = quantity+1;
        if (variantionStock && variantionStock < newQuantity) {
            setStockError("Fejl: Kun " + variantionStock +  " på lager")
            return
        }
        if (shopLimit && shopLimit < newQuantity) {
            setStockError("Fejl: Kun " + shopLimit +  " må vælges")
            return
        }
        await setQuantity(quantity+1);
    };

    const handleDecrementQuantity = async () => {
        if(quantity > 1) {
            
            await setQuantity(quantity-1);
        }
    };

    useEffect(() => {
        setSelectedQuantityText(quantity.toString());
    }, [quantity])

    useEffect(() => {
        if (stockError) {
            const timer = setTimeout(() => {
                setStockError(null);
            }, 4000); 

            return () => clearTimeout(timer);
        }
    }, [stockError]);

    return (

        <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {!readOnly && 
                <div 
                    style={!disabled ? { cursor: 'pointer' } : {cursor:'not-allowed'}}
                    onClick={!disabled ? handleDecrementQuantity : null}
                >
                    <Dash />
                </div>
            }
            {!!showExplainer && <ExplainerText style={{ color: styleGuide.color5 }} translationKey="_QUANTITY_" fallbackText="Antal" />}
            <input
                type="number"
                readOnly={!!readOnly || !!disabled}
                value={selectedQuantityText}
                onChange={handleQuantityTextChange}
                onBlur={handleQuantityTextBlur}
                style={{
                    width: '40px',
                    fontSize: '16px',
                    border: 'none',
                    textAlign: 'center',
                    fontWeight: 600,
                    fontStyle: 'normal',
                }}
            />
            {!readOnly && 
                <div
                    style={!disabled ? {cursor: 'pointer' } : {cursor:'not-allowed'}} 
                    onClick={!disabled ? handleIncrementQuantity : null }
                    title={!disabled ? null : 'Du kan ikke vælge flere'}
                >
                    <Plus />
                </div>
            }
            </div>
            {stockError && 
                <div style={{width: "200px", marginTop: "10px"}}>
                    <Alert severity="error"> {stockError}</Alert>
                </div>
            }
        </div>
    )
}

export default QuantityInput;
