import { AppContext } from "contexts/AppContext";
import { useContext, useState } from "react";
import IconButtonMedium from "components/elements/IconButtonMedium";
import ButtonMedium1 from "components/elements/ButtonMedium1";
import Headline6 from "components/elements/Headline6";
import CaptionText from "components/elements/CaptionText";
import Heart2 from "components/icons/Heart2";
import LabelTag1 from "./LabelTag1";
import { createUseStyles } from "react-jss";
import ExplainerTextTiny from "./ExplainerTextTiny";
import { translate } from "utils/Translate";
import { callTagManagerEvent, setupBasketData } from "utils/setup";
import Headline5 from "./Headline5";
import { pointsConvertor } from "../../utils/general";
import LabelSticker from "./LabelSticker";
import Activity from "utils/Activity";
import HoverableImage from "./HoverableImage";
import { Link } from "react-router-dom";
import ButtonSmall1 from "components/elements/ButtonSmall1";
import IconButtonSmall from "components/elements/IconButtonSmall";
import { useMediaQuery } from "@mui/material";

const jss = createUseStyles({
  ProductWrapper: {
    height: (styleGuide) => styleGuide.productNormalHeightMobile,
    width: (styleGuide) => styleGuide.productNormalWidthMobile,
  },
  ProductInfo: {
    height: 100,
  },
  ProductInfoMainTextContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: '3px 20px',
    maxWidth: '100%',
  },
  ProductNormalImage: {
    height: (styleGuide) =>
      !!styleGuide.productInfoAsOverlay ? `100%` : `calc(100% - 100px)`,
  },
  WishlistIcon: {
    position: 'absolute',
    top: 29,
    right: 6,
    zIndex: 1,
  },
  "@media screen and (min-width: 768px)": {
    ProductWrapper: {
      height: (styleGuide) => styleGuide.productNormalHeightTab,
      width: (styleGuide) => styleGuide.productNormalWidthTab,
    },
    WishlistIcon: {
      top: 52,
      right: 24,
    },
  },
  "@media screen and (min-width: 1280px)": {
    ProductWrapper: {
      height: (styleGuide) => styleGuide.productNormalHeight,
      width: (styleGuide) => styleGuide.productNormalWidth,
    },
  },
  "@media screen and (max-width: 768px)": {
    ProductInfo: {
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "start",
      height: 84,
    },
    ProductInfoMainTextContainer : {
      padding: "2px 10px",
    },
    ProductNormalImage: {
      height: (styleGuide) =>
        !!styleGuide.productInfoAsOverlay ? `100%` : `calc(100% - 84px)`,
    },
    TextAlignCenter: {
      textAlign: "center",
    },
  },
});

const ProductNormal = (props) => {
  const { styleGuide, userData, setBasket, brandData, defaultTheme, langCode } = useContext(AppContext);
  const [reactionLoading, setReactionLoading] = useState(false)
  const classes = jss(styleGuide);
  const showTag = !!props.showTag && !brandData?.features?.disablePoints;
  const hideLike = !!props.hideLike || brandData?.features?.disableWishlist ;
  const pointSuffix = translate("_POINTS_SHORT_", "pt")
  const isMobile = useMediaQuery('(max-width:768px)');
  const {
    points,
    text1,
    text2,
    wishlistCallback,
    wishlistIconStyles,
    imageSrc,
    imageStyles,
    text1Styles,
    text2Styles,
    pointsStyles,
    tagOptions,
    tagStyles,
    liked,
    outOfStock,
    sticker,
    storeScrollPosition,
    hoverImageSrc,
    imageBackgroundColor,
    hoverImageBackgroundColor,
  } = props; 
  const showSticker = !brandData?.features?.disablePoints && !!sticker
  const taxProduct = userData.taxPercentage ? Math.ceil(points/(brandData.brandPointRatio || 1) * userData.taxPercentage/100) : null
  const productInfoStrip = (
    <ProductInfo
      {...{ text1, text1Styles, text2, text2Styles, points, pointsStyles, taxProduct, isMobile }}
    />
  );
  const isLikedProduct = typeof liked === "boolean" ? liked : false;
  const likeUnlike = async (e) => {
    setReactionLoading(true)
    if (wishlistCallback) await wishlistCallback(e);
    await setupBasketData({ userData, setBasket });
    setReactionLoading(false)
  };

  const isUserAdmin = () => {
    if (userData?.userPrivilege === "MANAGE") {
        return true;
    } else {
        return false;
    }
  }

  const onClick = (event) => {
    event.stopPropagation()
    if (!outOfStock && props.productID) {
      Activity({ activityType: 'click', activityName: props.activityName, activityArgument: 'Product', activityArgumentID: props.productID});
      if(!event.ctrlKey && event.button !== 1){
        onGoToProduct()
      }
    } else if(props.packageID) {
      Activity({ activityType: 'click', activityName: props.activityName, activityArgument: 'Package', activityArgumentID: props.packageID});
      if(!event.ctrlKey && event.button !== 1){
        onGoToPackage()
      }
    }
  }
  const onGoToProduct = () => {
    if(storeScrollPosition === true) {
      sessionStorage.setItem('scrollPosition', `${window.scrollY || ''}`);
    }
    callTagManagerEvent({
      dataLayer: {
        event: 'view_item',
        ecommerce: {
          currency: 'DKK',
          value: points,
          items: [{
              item_name: text2,
              item_id: props.ProductID,
              item_brand: text1,
              item_category: '',
              item_variant: '',
              quantity: 1,
              price: points
          }]
        }
      },
    })
  }
  const onGoToPackage = () => {
    if(storeScrollPosition === true) {
      sessionStorage.setItem('scrollPosition', `${window.scrollY || ''}`);
    }
  }

  const likeButtonProps = {
    className: `${classes.WishlistIcon}`,
    inverse: isLikedProduct ? true : false,
    loading: reactionLoading,
    style: wishlistIconStyles,
    onClick: (e) => {
      if (!outOfStock) {
        e.stopPropagation();
        likeUnlike(e);
      }
    },
    icon:
      <Heart2
        fill="white"
        style={{ height: 10, width: 10 }}
        stroke={styleGuide.color7}
        strokeWidth="1.5px"
      />
  }

  const pointsText = !!brandData?.features?.disablePrice ? brandData?.features?.productButtonText[langCode] : pointsConvertor(brandData, points, pointSuffix)

  return (
    <div style={{display: 'flex', position: 'relative'}}>
      {!hideLike && (isMobile
        ? <IconButtonSmall {...likeButtonProps} />
        : <IconButtonMedium {...likeButtonProps} />
      )}
      <Link
        className={`${classes.ProductWrapper} ${props.className ? props.className : ""}`}
        style={{
          display: "flex",
          position: "relative",
          flexDirection: "column",
          cursor: "pointer",
          ...props.style,
        }}
        onClick={(event) => {
          onClick(event)
        }}
        onAuxClick={(event) => {
          if(event.button === 1) {
            onClick(event)
          }
        }}
        to={!outOfStock && props.productID ? `/product/${props.productID}` : props.packageID ? `/package/${props.packageID}` : '#'}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: showTag ? "space-between" : "flex-end",
          }}
        >
          {showTag ? (
            <LabelTag1
              {...tagOptions}
              style={{
                alignSelf: "flex-start",
                marginTop: "20px",
                marginLeft: "20px",
                zIndex: 1,
                ...tagStyles,
              }}
            />
          ) : null}
        </div>
        { showSticker ? (
          <LabelSticker
            type={sticker}
            price={points}
            style={isMobile ? {
              padding: '5px 10px',
              left: -8,
              bottom: 89,
            } : undefined}
          />
        ) : null }
        <HoverableImage
          src={imageSrc}
          hoverSrc={hoverImageSrc}
          backgroundColor={imageBackgroundColor}
          hoverBackgroundColor={hoverImageBackgroundColor}
          className={`${classes.ProductNormalImage}`}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 0,
            width: "100%",
            backgroundColor: styleGuide.productInfoBackground,
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            transition: "all .3s ease-in-out",
            ...imageStyles,
          }}
        >
          {((brandData?.features?.onlyAdminOrderEnabled) && !isUserAdmin()) ? <div></div> : 
          brandData?.features?.points === false || brandData?.features?.disablePoints ? null : points && 
          <div className={classes.buttonMobile} style={{display: "flex",flexDirection: "row"}}>
            {isMobile ?
              <ButtonSmall1
                showIcon={false}
                style={{
                  ...pointsStyles,
                  padding: '0px 10px',
                  justifyContent: "center",
                  marginRight: 5,
                  marginBottom: 5,
                }}
              >
                {pointsText}
              </ButtonSmall1>
              :
              <ButtonMedium1
                showIcon={false}
                style={{
                  ...pointsStyles,
                  minWidth: 101,
                  justifyContent: "center",
                  marginRight: 15,
                  marginBottom: defaultTheme === "ThemeTWO" ? 115 : 15,
                }}
              >
                {pointsText}
              </ButtonMedium1>
            }
          </div>}
          {!!styleGuide.productInfoAsOverlay ? productInfoStrip : null}
        </HoverableImage>
        
        {outOfStock && (
          <div className="outOfStock">
            <Headline5 style={{ color: "white" }}>
              {translate("_PRODUCT_SOLD_OUT_TEXT_", "Produktet er udsolgt")}
            </Headline5>
            <ButtonMedium1
              onClick={(e) => {
                e.stopPropagation();
                likeUnlike(e);
              }}
              showIcon={false}
              style={{
                padding: 5,
                maxWidth: 175,
                color: "black",
                backgroundColor: "white",
              }}
            >
              {translate("_REMOVE_FROM_WISHLIST_", "Fjern fra ønskeliste")}
            </ButtonMedium1>
          </div>
        )}
        {!styleGuide.productInfoAsOverlay ? productInfoStrip : null}
      </Link>
    </div>
  );
};

const ProductInfo = (props) => {
  const { styleGuide, userData,brandData } = useContext(AppContext);
  const classes = jss(styleGuide);
  const { points, text1, text2, text1Styles, text2Styles, taxProduct } =
    props;
  const productPrice = points && points.match(/\d/g).join("");
  const walletAmountPoints =
    userData && !isNaN(userData.walletAmountPoints)
      ? Number(userData.walletAmountPoints)
      : 0;
  const deficitPoints = !isNaN(productPrice)
    ? productPrice - walletAmountPoints
    : 0;
  const isDeficit = !!brandData?.features?.disablePoints || !!brandData?.features?.skipWalletForOrders || brandData?.features?.enableOnePointUsers ? false : deficitPoints > 0 ? true : false
  const showTaxPrice = !!taxProduct && !!points
  return (
    <div
      className={classes.ProductInfo}
      style={{
        position: "absolute",
        bottom: 0,
        zIndex: 1,
        width: "100%",
        background: styleGuide.productInfoBackground,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          padding: props.isMobile ? "2px 6px" : "2px 10px",
          backgroundColor: styleGuide.productInfoBackground,
          textAlign: "end",
          borderBottom: showTaxPrice ? `1px solid ${styleGuide.color4}` : undefined,
          height: 20,
          width: '100%',
        }}
        >
        {showTaxPrice && (
          <ExplainerTextTiny
            style={{ verticalAlign: "text-top", paddingTop: 0, paddingBottom: 0, lineHeight: '12px', fontSize: props.isMobile ? 9 : 10 }}
            fallbackText={`${translate('_PRODUCT_TAXATION_TEXT_', 'Beskatning kr.')} ${pointsConvertor(brandData,taxProduct,'')}`}
          />
        )}
      </div>
      <div className={classes.ProductInfoMainTextContainer} >
        <Headline6
          title={text1}
          style={{
            color: styleGuide.productInfoText1Color,
            ...text1Styles,
            whiteSpace: "break-spaces",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: props.isMobile ? 14 : 16,
          }}
        >
          {text1 || "\u00A0"}
        </Headline6>
        <CaptionText
          title={text2}
          style={{
            color: styleGuide.productInfoText2Color,
            ...text2Styles,
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: props.isMobile ? 12 : 14,
          }}
        >
          {text2 || "\u00A0"}
        </CaptionText>
      </div>
      {!!isDeficit && points && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: 20,
            padding: 6,
            backgroundColor: styleGuide.color3,
            textAlign: "center",
          }}
        >
          <ExplainerTextTiny
            style={{ verticalAlign: "top", fontSize: props.isMobile ? 9 : 10 }}
            fallbackText={`${translate(
              "_USER_POINT_DEFICIENT_FOR_PRODUCT_TEXT_",
              "Du mangler"
            )} ${pointsConvertor(brandData,deficitPoints, translate("_POINTS_", "point"))}`}
          />
        </div>
      )}
    </div>
  );
};

export default ProductNormal;
