import Headline2 from 'components/elements/Headline2'
import React, { useContext, useEffect, useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, InputLabel, Switch } from "@mui/material";
import RoundIconButton1 from 'components/elements/RoundIconButton1';
import CaretDown from 'components/icons/CaretDown';
import Headline4 from 'components/elements/Headline4';
import { AppContext } from 'contexts/AppContext';
import Sales from 'components/blocks/Sales/Sales';
import {Dialog,DialogActions,DialogContent,DialogContentText} from "@mui/material";
import { callAPI } from 'utils/API';
import { translate } from 'utils/Translate';
import ButtonLarge1 from 'components/elements/ButtonLarge1';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import ButtonMedium4 from 'components/elements/ButtonMedium4';
import { setupUserData } from "utils/setup";
import { Modal } from "react-bootstrap";
import ButtonMedium1 from "components/elements/ButtonMedium1";
import Headline6 from "components/elements/Headline6";
import ContentSmall from "components/elements/ContentSmall";

function ActivationSales({setActivateComplete,activationSalesRef, ...props}) {
  const { styleGuide, langCode, brandData, userData } = useContext(AppContext);
  const [activate,setActivate]=useState(userData.enableAutoWalletActivation === 1 ? true : false );
  const [Check,setCheck]=useState(false);
  const activationPointsSectionLabels = brandData.features?.upgraderClubConfigurableLabels && brandData.features.upgraderClubConfigurableLabels[langCode]?.profilePageLabels?.activationPointsSectionLabels
     ? brandData.features.upgraderClubConfigurableLabels[langCode].profilePageLabels.activationPointsSectionLabels : null
  const contextState = useContext(AppContext);
  const [showModal, setShowModal] = useState(false)
  const [sales, setSales] = useState([])
  const [_, setLoading] = useState(false)
  const [selectAll, setSelectAll] = useState(false)

  const handleActivation=(e)=>{
    setActivate(e.target.checked)
    setCheck(true)
  }

  const activatePoints = async () => {
    const WalletActivationIDs = sales.filter(s => !!s.checked).map(s => s.WalletActivationID)
    if(WalletActivationIDs.length){
      const request = await callAPI(`/walletactivation/updateStatusUser`, "POST", {body: {WalletActivationIDs}});
      if(request) {
          setShowModal(false)
          setActivateComplete({pointBox: true, pointOverview: true, activationOverview: true, activationGraph: true})
          setupUserData(contextState, setLoading)
          fetchWalletActivation()
          setSales(sales.map(sale => sale.checked = false))
      }
    }
  };

  const fetchWalletActivation = async () => {
    const userWalletLines = await callAPI(`/walletactivation/userPool`);
    if (!userWalletLines || !userWalletLines.length){
      setSales([]);
    }else{
      setSales(userWalletLines);
    }
  };

  const getPointsBalance = () => {
    return sales.filter(s => s.status === 'WAITING' || s.status === 'COMPENSATION').reduce((sum,s) => sum + s.walletAmountPoints, 0)
  }

  const getModalContentText = () => {
    const selectedSales = sales.filter(s => !!s.checked)
    const totalPoints = selectedSales.reduce((sum, a) => sum + a.walletAmountPoints, 0)
    if(!selectedSales.length){
      return `Please select at least one item`
    }else{
      const balanceAmount = getPointsBalance()
      if(balanceAmount > 0){
        if(totalPoints <= balanceAmount){
          return activationPointsSectionLabels?.popupContentText ? activationPointsSectionLabels.popupContentText.replace("{{walletAmountPoints}}",totalPoints).replace("{{totalSales}}", selectedSales.length) :
             `Er du sikker på, at du vil aktivere ${totalPoints} point?`
        }else if(selectedSales.length === 1){
          return activationPointsSectionLabels?.popupContentTextCompensationAllowed ? activationPointsSectionLabels.popupContentTextCompensationAllowed.replace("{{walletAmountPoints}}",balanceAmount) :
          `Du kan kun aktivere ${balanceAmount} point p.g.a. udligningspoint. Ønsker du at aktivere ${balanceAmount} point?`
        }else{
          return activationPointsSectionLabels?.popupContentTextCompensationNotAllowed ? activationPointsSectionLabels.popupContentTextCompensationNotAllowed :
          `Den valgte sum af point er større end din totale sum. Vælg venligst kun 1 linje og fortsæt.`
        }
      }else{
        return activationPointsSectionLabels?.popupContentTextCompensation ? activationPointsSectionLabels.popupContentTextCompensation :
          `Din sum af point til aktivering er 0 p.g.a. udligningspoint og du kan derfor ikke aktivere flere point.`
      }
    }
  }

  const getSelectedSales=()=>{
    return sales ? sales.filter(s => !!s.checked) : []
  }

  const getSelectAllStatus = (sales) => {
    const checkedSales = sales.filter(s => s.checked === true).length
    const eligibleSales = sales.filter(s => s.status === "WAITING").length
    return  eligibleSales && eligibleSales === checkedSales
  }

  useEffect(() => {
    fetchWalletActivation()
  },[])
    
  useEffect(() => {
    if(sales.filter(s => s.checked === true).length === sales.filter(s => s.status === "WAITING").length){
      setSelectAll(true)
    }
    if(!sales.filter(s => s.checked === true).length){
      setSelectAll(false)
    }
  },[sales])

  return (
    <>
      <div
          id={props?.id || ""}
          ref={activationSalesRef}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
            marginTop: 100,
            // marginBottom: 100,
          }}
        >
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: 20,
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <Headline2
              configuredLabel={activationPointsSectionLabels?.titleText}
              translationKey="_PROFILE_PAGE_SALES_"
              fallbackText="Aktivering af point"
            />
            
          </div>
          
        {!brandData?.features?.disableAutoActivateFeature && <span style={{ display: 'flex' }}>
          <div style={{ display: 'flex',alignItems:'center' }}>
            <InputLabel htmlFor='activate_checkbox'>
              {activationPointsSectionLabels?.automaticActivationRemarkText ? activationPointsSectionLabels.automaticActivationRemarkText : translate('_ACTIVATE_POINTS_AUTOMATIC_CHECKBOX_', 'Aktivér altid point automatisk:')}</InputLabel>           
            <Switch
              id = 'activate_checkbox'
              checked = {activate}
              onClick={(e) => handleActivation(e)}
            />
          </div>
          <div style={{display:'flex',alignItems:'center'}}>
            <Row>
              <Link to={'/activationDetails'} target="_blank"
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  fontSize: 14, fontFamily: styleGuide.secondaryFontFamily
                }}>
                <i>{activationPointsSectionLabels?.automaticActivationLinkText ? activationPointsSectionLabels.automaticActivationLinkText : translate('_WHAT_DOES_THIS_MEAN_', 'Hvad betyder dette?')}</i>
              </Link>
            </Row>
          </div>
        </span>}
        {Check ?
          <DialogBox
            activate={activate}
            setActivate={setActivate}
            setCheck={setCheck}
            Check={Check} /> :
          <DialogBox
            activate={activate}
            setActivate={setActivate}
            setCheck={setCheck}
            Check={Check} />}
            {brandData && brandData.features && brandData.features.walletActivationText && 
            <div 
                dangerouslySetInnerHTML={{__html: brandData.features.walletActivationText}} 
                style={{
                    fontFamily: styleGuide.fontFamily,
                    fontSize: styleGuide.headline4FontSize,
                    fontStyle: 'normal',
                    lineHeight: styleGuide.headline3LineHeight,
                    color: styleGuide.color7,
                    marginTop: 10,
                    marginBottom: 15
                }}>
            </div>}
            <div id="show_points_section">
                <Accordion
                    defaultExpanded={true}
                    style={{
                    borderRadius: "14px",
                    overflow: "hidden",
                    boxShadow:
                        "0px 0px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                    }}
                >
                    <AccordionSummary
                    expandIcon={<RoundIconButton1 icon={<CaretDown fill="white" />} />}
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        padding: "16px",
                    }}
                    >
                    {!brandData?.features?.hidePointsExpiry ? (
                        <>
                        <PointStatus
                            circleColor={"rgb(79, 158, 94)"}
                            points={-4}
                            expireMonthText={"Udløber om mere end 6 måneder"}
                        />
                        <PointStatus
                            circleColor={"rgb(239, 224, 123)"}
                            points={0}
                            expireMonthText={"Udløber om 6 måneder"}
                        />
                        <PointStatus
                            circleColor={"rgb(199, 61, 61)"}
                            points={0}
                            expireMonthText={"Udløber om 3 måneder"}
                        />
                        <PointStatus
                            circleColor={"rgb(7,7,7)"}
                            points={0}
                            expireMonthText={"Udløber i denne måned"}
                        />
                        </>
                    ) : (
                      <div style={{flexDirection: 'column'}}>
                        <Headline4
                        configuredLabel={activationPointsSectionLabels?.sectionBoxTitleText}
                        translationKey="_PROFILE_PAGE_POINT_APPROVAL_CAPTION_"
                        fallbackText="Point til aktivering"
                        style={{ padding: "30px 20px" }}
                        />
                        <ButtonMedium4 showIcon={false} 
                          onClick={(e) => {
                            e.stopPropagation()
                            setSales(sales.map(sale => {
                              if(sale.status === "WAITING"){
                                sale.checked = !selectAll
                              }
                              return sale
                            }))
                            setSelectAll(!selectAll)
                          }}
                          style={{marginLeft: 20, marginTop: 16, backgroundColor: getSelectAllStatus(sales) ? styleGuide.color16: styleGuide.color1, 
                            color: getSelectAllStatus(sales) ? styleGuide.color1: styleGuide.color18}}
                          translationKey="_PROFILE_MULTIPLE_ACTIVATE_BUTTON_"
                          fallbackText="Markér alle"
                        ></ButtonMedium4>
                      </div>
                    )}
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                          padding: "30px",
                          borderTop: `1px solid ${styleGuide.color4}`,
                          overflow: 'scroll'
                      }}
                      className="hide-scroll"
                    >
                    <Sales setActivateComplete={setActivateComplete} sales={sales} setSales={setSales} refreshData={fetchWalletActivation} setShowModal={setShowModal}/>
                    </AccordionDetails>
                </Accordion>
                <Modal
                  show={showModal}
                  onHide={() => setShowModal(false)}
                  style={{ margin: "auto" }}
                  centered
                >
                  <Modal.Header
                    className="pb-0 border-0"
                    closeButton
                    style={{ fontSize: 9 }}
                  >
                    <Headline6 translationKey="_CONFIRM_ACTIVATE_SALES_HEADING_">
                      {activationPointsSectionLabels?.popupTitleText ? activationPointsSectionLabels.popupTitleText : 'Bekræft aktivering'}
                    </Headline6>
                  </Modal.Header>
                  <Modal.Body className="pt-0">
                    <ContentSmall style={{whiteSpace: "pre-line"}} translationKey="_CONFIRM_ACTIVATE_SALES_TEXT_">
                      {getModalContentText()}
                    </ContentSmall>
                    <div
                      style={{
                        display: "flex",
                        padding: "1rem",
                        justifyContent: "space-around",
                      }}
                    >
                      {getPointsBalance() > 0  && !!getSelectedSales().length && (getSelectedSales().reduce((sum, a) => sum + a.walletAmountPoints, 0) <= getPointsBalance() || getSelectedSales().length === 1) && 
                      <ButtonMedium1
                        showIcon={false}
                        translationKey="_YES_"
                        fallbackText="Ja"
                        onClick={() => activatePoints()}
                      />}
                      <ButtonMedium4
                        showIcon={false}
                        translationKey="_CLOSE_"
                        fallbackText="Fortryd"
                        onClick={() => {
                          setSales(sales.map(s => {
                            s.checked = false
                            return s
                          }))
                          setShowModal(false)
                        }}
                      />
                    </div>
                  </Modal.Body>
                </Modal>
            </div>
        </div>
    </>
  )
}

export const PointStatus = (props) => {
    const { circleColor, points, expireMonthText } = props;
    return (
      <>
        <div className="point-col-lg">
          <div
            style={{
              padding: "10px",
              height: "35px",
              width: "35px",
              borderRadius: "50px",
              backgroundColor: circleColor,
              float: "left",
              marginRight: "15px",
            }}
          >
            <div
              style={{
                height: "15px",
                width: "15px",
                borderRadius: "50px",
                backgroundColor: "white",
              }}
            >
              &nbsp;
            </div>
          </div>
          <div>
            <div>{points} point</div>
            <div>{expireMonthText}</div>
          </div>
        </div>
      </>
    );
};
  

export const DialogBox = (props) => {
  const [open, setOpen] = useState(false);
  const { activate, setActivate, setCheck, Check } = props;
  const { userData, setUserData } = useContext(AppContext);

  useEffect(() => {
    if (Check) {
      setOpen(true);
    }
  }, [activate]);

  const handleClose = () => {
    setOpen(false);
    setActivate(!activate);
    setCheck(false)
  };

  const handleAgree = async () => {
    let response = await callAPI("/user/toggleAutoWalletActivation", "POST");
    if (response) {
      setUserData({...userData, enableAutoWalletActivation: userData.enableAutoWalletActivation === 1 ? 0 : 1})
    }
    setOpen(false);
    setCheck(false)
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {activate
              ? translate('_AUTOMATIC_ACTIVATION_OF_SALES_', 'Vil du aktivere alle point automatisk? Bemærk at aktiverede point indberettes til Skattestyrelsen')
              : translate('_MANUAL_ACTIVATION_OF_SALES_', 'Vil du ændre din indstilling til manuel aktivering af dine point?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <ButtonLarge1 showIcon={false} translationKey="_CANCEL_" fallbackText="Afvis"  onClick={handleClose}/>
          <ButtonLarge1 showIcon={false} translationKey="_ACCEPT_" fallbackText="Godkend"  onClick={handleAgree} />
        </DialogActions>
      </Dialog>
    </div>
  );
};


export default ActivationSales
