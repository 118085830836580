import { callAPI } from 'utils/API';
import TagManager from 'react-gtm-module'

const GTM_CODES = {
    "santander": "GTM-NDDRN3FR",
    "tryg": "GTM-N67T3W75",
    "trygpointshop": "GTM-K33P9HM2",
    "ok": "GTM-P3BGFLBG",
    "alka": "GTM-KQPRSL5T",
    "skadedk": "GTM-MVWBMLGB"
}
const domains = window.location.hostname.split('.').filter(domain => ["localhost","dev","alpha"].includes(domain.toLowerCase()))
const isProduction = !domains.length

export const setupBrandData = async (context, setIsLoading) => {
    const { setBrandData } = context
    const brandInfo = await callAPI('/brand/setup', 'GET')
    if(GTM_CODES[brandInfo.brandSlug] && !!isProduction){
        const tagManagerArgs = {
        gtmId: GTM_CODES[brandInfo.brandSlug]
      }
      TagManager.initialize(tagManagerArgs)
    }
    setBrandData(brandInfo && brandInfo.BrandID ? brandInfo : null)
    setupWeb(brandInfo)
    setIsLoading(false)
    return
}

export const callTagManagerEvent = (eventObj) => {
    if(!!isProduction){
        TagManager.dataLayer(eventObj)
    }
}

export const setMaintenanceData = async (context) => {
    const { setMaintenance, maintenance, downForMaintenance, setDownForMaintenance, userData } = context
    if (!userData) {
        return
    }
    const maintenanceInfo = await callAPI('/check-maintenance', 'GET')
    if (maintenanceInfo.shutDownAllBrands !== maintenance) {
        setMaintenance(maintenanceInfo.shutDownAllBrands)
    }
    if (maintenanceInfo.downForMaintenances !== downForMaintenance) {
        setDownForMaintenance(maintenanceInfo.downForMaintenances)
    }
    return
}

export const setupUserData = async (context, setIsLoading) => {
    const { setUserData } = context
    const token = localStorage.getItem(`accessToken`)
    const msalToken = localStorage.getItem('msalToken')
    if(!token) {
        setIsLoading(false)
        return null
    }
    const userInfo = await callAPI('/session/fetchUserBySessionID', 'GET')
    userInfo.walletAmountPoints = isNaN(userInfo.walletAmountPoints) || !userInfo.walletAmountPoints ? 0 : userInfo.walletAmountPoints
    setUserData(userInfo && userInfo.UserID ? userInfo : null)
    setIsLoading(false)
    return
}

export const setupMenuData = async (context) => {
    const { setMenuData, userData } = context
    if(!userData || !userData.UserID) {
        setMenuData(null)
        return
    }

    let menuInfo = await callAPI("/menu/get-user-menu", "GET");
    if (menuInfo && menuInfo.length > 0) {
        if (menuInfo.length > 1) {
            menuInfo = menuInfo.sort((a, b) => a.poolOrder - b.poolOrder)
        }
        menuInfo.map(menuItem => {
            if (menuItem.pool && Array.isArray(menuItem.pool)) {
                menuItem.pool = menuItem.pool.sort((a, b) => a.poolOrder - b.poolOrder)
            }
            return menuItem
        })
        setMenuData(menuInfo);
    } else {
        setMenuData(null)
    }
    return
}

export const setupUserPrompts = async (context) => {
    const { setUserPrompts, userData } = context
    if(!userData || !userData.UserID) {
        return
    }

    let userPrompts = await callAPI("/session/get-user-prompts", "GET");
    setUserPrompts(userPrompts)
}

export const setupBlogData = async (context) => {
    const { menuData, setAccessibleBlogs, userData } = context
    if(!userData || !userData.UserID) {
        setAccessibleBlogs([])
        return null
    }

    if(!menuData || !(menuData.length > 0)) {
        setAccessibleBlogs([])
        return null
    }

    const blogPool = menuData.filter(pool => pool.poolName.toLowerCase() === 'blog')
    const blogs = blogPool.length > 0 ? blogPool[0].pool : []

    setAccessibleBlogs(blogs.length ? blogs : [])
    return
}

export const setupShopData = async (context) => {
    const { menuData, setAccessibleShops, userData } = context
    if(!userData || !userData.UserID) {
        setAccessibleShops([])
        return null
    }

    if(!menuData || !(menuData.length > 0)) {
        setAccessibleShops([])
        return null
    }

    const shopPool = menuData.filter(pool => pool.poolName.toLowerCase() === 'shop')
    const shopsInPool = shopPool.length > 0 ? shopPool[0].pool : []
    let shops = []
    for(const s of shopsInPool) {
        const orderCount = await callAPI(`/shop/${s.ShopID}/getOrderCount`, "GET")
        let shop = {...s, orderCount}
        shops.push(shop)
    }
    setAccessibleShops(shops.length ? shops : [])
    return
}

export const setupHOFData = async (context) => {
    const { menuData, setAccessibleHOFs, userData } = context
    if(!userData || !userData.UserID) {
        setAccessibleHOFs([])
        return null
    }

    if(!menuData || !(menuData.length > 0)) {
        setAccessibleHOFs([])
        return null
    }

    const hofPool = menuData.filter(pool => pool.poolName.toLowerCase() === 'halloffame' && new Date(pool.timeEnd) > new Date())
    const hofs = hofPool.length > 0 ? hofPool[0].pool : []

    setAccessibleHOFs(hofs.length ? hofs : [])
    return
}

export const setupBasketData = async (context) => {
    const {userData, setBasket} = context
    if(!userData || !userData.UserID) {
        setBasket([])
        return
    }
    const basketInfo = await callAPI('/basket', 'GET')
    setBasket(basketInfo && basketInfo.length ? basketInfo : [])
    return
}

const setupWeb = (brand) => {
    document.title = brand && brand.brandName
    if (brand && brand.faviconImageURL) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = brand.faviconImageURL
    }
}