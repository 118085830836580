import React from 'react'
import { AppContext } from "contexts/AppContext";
import { useEffect, useContext, useState } from 'react'
import "./Register.css"
import { Grid } from '@mui/material';

const RegisterInfo = () => {
    const { brandData } = useContext(AppContext);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    let brandSlug = brandData && brandData.brandSlug;
    const isOkBrand = brandData && brandData.BrandID === 187 // for OK brand
    const registerInfo = brandData && brandData.features?.registerInfo && brandData.features?.registerInfo;
    return (
        <div style={{ minHeight: "100vh" }} className="register_div">
            {!!registerInfo.title && <div>
                <div style={{ width: "45px", height: "2px", margin: "auto", marginBottom: "1rem", marginTop: "1.5rem" }}></div>
                <h6 className='register_headline'>
                    <span style={{fontSize: "25px"}}>{registerInfo.title}</span>
                </h6>
            </div>}
            {registerInfo.sections.map(item => {
                    return <>
                        <label className="label_style" style={{fontWeight: "600"}}>{item.header}</label>
                        <label className="description_message">{item.text}</label>
                    </>
            })}
        </div>
    )
}

export default RegisterInfo