import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import Translate, { translate } from "utils/Translate";
import { AppContext } from "contexts/AppContext";

import { useNavigate } from "react-router-dom";

const RegisterPageBanner1 = (props) => {
    const { styleGuide, brandData } = useContext(AppContext);
    const { title, text, textColor } = props;
    const brandLogoHeight = brandData && brandData.features && brandData.features.brandLogoHeight ? `${brandData.features.brandLogoHeight}px` : '35px';
    const brandLogoWidth = brandData && brandData.features && brandData.features.brandLogoWidth ? `${brandData.features.brandLogoWidth}px` : 'auto';
    const headerBgColor = brandData && brandData.features && brandData.features.headerBackground ? brandData.features.headerBackground : 'white';
    const navigate = useNavigate();

    const htmlDecode = (input) => {
        let e = document.createElement("div");
        e.innerHTML = input;
        return e.childNodes[0].nodeValue;
    };

    return (
        <Box display="flex" flexDirection="column" width="100%">
            <Box width="100%" display="flex" justifyContent="start" sx={{ backgroundColor: headerBgColor, padding: "16px 0 0 50px" }}>
                <img
                    className="clubTopHeaderLogo"
                    style={{ height: brandLogoHeight, width: brandLogoWidth, cursor: 'pointer' }}
                    src={brandData.brandLogo}
                    onClick={() => navigate("/")}
                />
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                alignItems="start"
                sx={{
                    backgroundColor: styleGuide.color14,
                    width: "100%",
                    padding: "120px 30px 80px 80px"
                }}
            >
                <Typography
                    component="span"
                    sx={{
                        fontFamily: styleGuide.fontFamily,
                        fontWeight: {
                            xs: styleGuide.headline3FontWeight,
                            sm: styleGuide.headline2FontWeight,
                            md: styleGuide.headline1FontWeight,
                        },
                        fontSize: {
                            xs: styleGuide.headline3FontSize,
                            sm: styleGuide.headline2FontSize,
                            md: styleGuide.headline1FontSize,
                        },
                        lineHeight: {
                            xs: styleGuide.headline3LineHeight,
                            sm: styleGuide.headline2LineHeight,
                            md: styleGuide.headline1LineHeight,
                        },
                        color: textColor,
                    }}
                    dangerouslySetInnerHTML={{
                        __html: htmlDecode(translate("_REGISTER_BANNER_HEADLINE_", title)),
                    }}
                />
                <Typography
                    component="span"
                    sx={{
                        fontFamily: styleGuide.secondaryFontFamily,
                        fontWeight: styleGuide.contentNormalFontWeight,
                        fontSize: styleGuide.contentNormalFontSize,
                        lineHeight: styleGuide.contentNormalLineHeight,
                        color: textColor,
                        whiteSpace: "pre-line",
                        marginTop: "15px"
                    }}
                    dangerouslySetInnerHTML={{
                        __html: htmlDecode(translate("_REGISTER_BANNER_CONTENT_", text)),
                    }}
                />
            </Box>
        </Box>
    );
};

export default RegisterPageBanner1;
