import { useContext, useEffect, useState } from "react";
import { callAPI } from "utils/API";
import { translate } from "utils/Translate";
import { AppContext } from "contexts/AppContext";
import HorizontalScrollBlock from "components/blocks/horizontalScrollBlock/HorizontalScrollBlock";
import { pointsConvertor } from "utils/general";
import { useNavigate } from "react-router-dom";
import { createUseStyles } from "react-jss";
import ProductNormal from "components/elements/ProductNormal";
import ButtonMedium4 from "components/elements/ButtonMedium4";
import { setupProductImages } from "utils/productImageHelper";

const jss = createUseStyles({
    seeMoreButton: {
        position: "relative",
        marginTop: 30,
    },
});

const ProductsScrollBlock = (props) => {
    const classes = jss();
    const { brandData, accessibleShops, langCode } = useContext(AppContext);
    const navigate = useNavigate()
    const [products, setProducts] = useState([])
    const ShopID = accessibleShops && accessibleShops.length > 0 ? accessibleShops[0].ShopID : null

    const fetchProducts = async () => {
        let fetchedProducts = await props.fetchProducts()
        if (!fetchedProducts?.length) return
        fetchedProducts.forEach(product => {            
            setupProductImages(product, false)
        })

        setProducts(fetchedProducts)
    }

    const productReaction = async (product) => {
        const ReactionID = product.reaction ? product.reaction.ReactionID : null
        await callAPI("/product/reaction", "POST", { body: { ReactionTargetID: product.ProductID, reactionData: JSON.stringify({ ShopID: product.ShopID }), ...(ReactionID && { ReactionID }) } })
        fetchProducts()
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    return (
        products.length > 0 &&
        <div>
            <HorizontalScrollBlock caption={props.caption}>
                {products.map(item => (
                    <ProductNormal
                        key={item.ProductID || item.ProductPackageID}
                        productID={item.ProductID}
                        showTag={item.enableDiscount ? true : false}
                        tagOptions={{
                            fallbackText: `${translate('_PRODUCT_DISCOUNT_TAG_TEXT_', 'Spar')} ${pointsConvertor(brandData, item.productPointComputed - item.discountPointComputed, translate("_POINTS_SHORT_", "pt"))}`,
                            showClose: false,
                        }}
                        tagStyles={{ borderRadius: 8 }}
                        points={`${item.discountPointComputed ? item.discountPointComputed : item.productPointComputed}`}
                        liked={item.reaction && item.reaction.ReactionID ? true : false}
                        className="productlarge item"
                        style={{ boxShadow: `0px 0px 3px 1px rgba(34, 34, 34, 0.15)` }}
                        text2={!!item.translations &&
                            !!item.translations.length
                            ? item.translations.find(
                                (translation) => translation.languageKey === langCode
                            ) ? item.translations.find(
                                (translation) => translation.languageKey === langCode
                            ).elementTitle
                                : item.elementTitle
                            : item.elementTitle}
                        text1={item.manufacturer}
                        wishlistCallback={() => productReaction(item)}
                        imageSrc={`url(${item.image?.src})`}
                        imageBackgroundColor={item.image?.backgroundColor}
                        activityName='home'
                    />
                ))}
            </HorizontalScrollBlock>
            <div className={classes.seeMoreButton}>
                <ButtonMedium4
                    style={{ margin: "auto" }}
                    showIcon={false}
                    translationKey="_HOME_POPULAR_PRODUCTS_SEE_MORE_BUTTON_"
                    fallbackText="Se alle produkter"
                    onClick={ShopID ? () => {
                        if(props.onSeeMoreClicked){
                            props.onSeeMoreClicked()
                        }
                        navigate(`shop/${ShopID}`)
                    } : null}
                />
            </div>
        </div>
    )
}

export default ProductsScrollBlock