import React from 'react'
import Success from 'components/icons/Success.js'

export default function RegisterSuccess() {
  return (
    <div className='register-form__success' data-cy='register.sucess'>
     <div style={{margin:'auto',marginTop:'10%',alignSelf:'center',width:'100px'}}><Success/></div>
      <h4 className='mt-5 mb-3 text-center'>Tak. Din anmodning er nu sendt</h4>
      <p className='text-center'>Før du kan logge ind skal en administrator godkende din bruger. Du modtager en email når dette er sket.</p>
    </div>
  )
}
