import React, { useEffect, useState, useContext } from "react";
import { callAPI } from "utils/API";
import { Col, Row, Alert, Modal } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { AppContext } from "contexts/AppContext";
import Menu from "../../icons/Menu";
import Search from "../../icons/Search";
import Account2 from "../../icons/Account2";
import FavouriteBorder from "../../icons/FavouriteBorder";
import Shop from "../../icons/shop";
import ShoppingCart from "../../icons/ShoppingCart";
import Headline6 from "components/elements/Headline6"
import ContentSmall from "components/elements/ContentSmall"
import { useNavigate } from "react-router-dom"
import ButtonMedium1 from "components/elements/ButtonMedium1";
import ButtonMedium4 from "components/elements/ButtonMedium4";
import Drawer from "components/blocks/drawer/Drawer";
import Close from "components/icons/Close";
import './Header.css'
import ContentLarge from "components/elements/ContentLarge";
import Activity from "utils/Activity";
import { translate } from "utils/Translate";
import { pointsConvertor } from "utils/general";

function Header1(props) {
  const { style, className, pointsUpdate, hidePointsUpdate } = props
  const [show, setShow] = useState(false);
  const [hofURL, setHOFURL] = useState(null);
  const [teamAccess, setTeamAccess] = useState();
  const [announcements, setAnnouncements] = useState();
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const handleClose = () => setShow(false);
  const { brandData, menuData, styleGuide, userData, setStatusscroll, themeConfig, langCode } = useContext(AppContext);
  const navigate = useNavigate();
  const brandLogoHeight = brandData && brandData.features && brandData.features.brandLogoHeight ? `${brandData.features.brandLogoHeight}px` : '35px';
  const brandLogoWidth = brandData && brandData.features && brandData.features.brandLogoWidth ? `${brandData.features.brandLogoWidth}px` : 'auto';
  const headerBgColor = brandData && brandData.features && brandData.features.headerBackground ? brandData.features.headerBackground : 'white';
  const clearSession = () => {
    localStorage.clear()
    navigate('/logout')
  }

  const NewsMenu = () => {
    if (!menuData || !menuData.length) return null

    const blogMenuItem = menuData.filter(menu => menu.poolName.toLowerCase() === 'blog')
    if (!blogMenuItem || !blogMenuItem.length) return null

    const blogs = blogMenuItem[0].pool
    const newsBlogs = blogs.filter(blog => blog.blogType === 'NEWS')

    if (!newsBlogs || !newsBlogs.length) return null

    if (newsBlogs.length === 1) {
      const link = '/posts'
      const title = !!blogMenuItem[0] && !!blogMenuItem[0].translations && !!blogMenuItem[0].translations.length > 0
      ? blogMenuItem[0].translations.find(
              (translation) => translation.languageKey === langCode
      ) 
      ? blogMenuItem[0].translations.find(
          (translation) => translation.languageKey === langCode
      ).menuTitle
          : blogMenuItem[0].menuTitle
      : blogMenuItem[0].menuTitle
      return <Nav.Link
        key='single-newsBlog-link'
        style={menuItem}
        onClick={() => {
          handleClose();
          Activity({activityType: 'click',activityName: 'menu',activityArgument: 'Blog',activityArgumentID: ''})
          navigate(`${link}`);
        }}
      >
        {title}
      </Nav.Link>
    }

    if (newsBlogs.length > 1) {
      const menuItems = newsBlogs.map(blog => {
        const link = `/posts/${blog.BlogID}`
        const title = !!blog && !!blog.translations && !!blog.translations.length > 0
          ? blog.translations.find(
                  (translation) => translation.languageKey === langCode
          ) 
          ? blog.translations.find(
              (translation) => translation.languageKey === langCode
          ).moduleName
              : blog.moduleName
          : blog.moduleName
        return <Nav.Link
          key={blog.BlogID}
          style={subMenuItem}
          onClick={() => {
            handleClose();
            Activity({activityType: 'click',activityName: 'menu',activityArgument: 'Blog',activityArgumentID: blog.BlogID})
            navigate(`${link}`);
          }}
        >
          - {title}
        </Nav.Link>
      })

      const title = !!blogMenuItem[0] && !!blogMenuItem[0].translations && !!blogMenuItem[0].translations.length > 0
      ? blogMenuItem[0].translations.find(
              (translation) => translation.languageKey === langCode
      ) 
      ? blogMenuItem[0].translations.find(
          (translation) => translation.languageKey === langCode
      ).menuTitle
          : blogMenuItem[0].menuTitle
      : blogMenuItem[0].menuTitle

      return <>
        <Nav.Link
          key='shop-wrapper-link'
          style={menuItem}
          onClick={() => {
            Activity({activityType: 'click',activityName: 'menu',activityArgument: 'Blog',activityArgumentID: ''})
            navigate(`/posts`)
          }}
        >
          {title}
        </Nav.Link>
        {menuItems}
      </>
    }
  }

  const HOFMenu = () => {
    if (!menuData || !menuData.length) return null

    const hofMenuItem = menuData.filter(menu => menu.poolName.toLowerCase() === 'halloffame')
    if (!hofMenuItem || !hofMenuItem.length) return null

    const hofs = hofMenuItem[0].pool
    // console.log(hofs)
    if (hofs.length === 1) {
      const link = `/halloffame/${hofs[0].HalloffameID}`
      const title = !!hofMenuItem[0] && !!hofMenuItem[0].translations && !!hofMenuItem[0].translations.length > 0
      ? hofMenuItem[0].translations.find(
              (translation) => translation.languageKey === langCode
      ) 
      ? hofMenuItem[0].translations.find(
          (translation) => translation.languageKey === langCode
      ).menuTitle
          : hofMenuItem[0].menuTitle
      : hofMenuItem[0].menuTitle
      return <Nav.Link
        key='single-shop-link'
        style={menuItem}
        onClick={() => {
          handleClose();
          Activity({activityType: 'click',activityName: 'menu',activityArgument: 'Halloffame',activityArgumentID: hofs[0].HalloffameID})
          navigate(`${link}`);
        }}
      >
        {title}
      </Nav.Link>
    }
    if (hofs.length > 1) {
      const menuItems = hofs.map(hof => {
        const link = `/halloffame/${hof.HalloffameID}`
        const title = !!hof && !!hof.translations && !!hof.translations.length > 0
          ? hof.translations.find(
                  (translation) => translation.languageKey === langCode
          ) 
          ? hof.translations.find(
              (translation) => translation.languageKey === langCode
          ).moduleName
              : hof.moduleName
          : hof.moduleName
        return <Nav.Link
          key={hof.HalloffameID}
          style={subMenuItem}
          onClick={() => {
            handleClose();
            Activity({activityType: 'click',activityName: 'menu',activityArgument: 'Halloffame',activityArgumentID: hof.HalloffameID})
            navigate(`${link}`);
          }}
        >
          - {title}
        </Nav.Link>
      })
      // console.log(hofURL)

      const title = !!hofMenuItem[0] && !!hofMenuItem[0].translations && !!hofMenuItem[0].translations.length > 0
      ? hofMenuItem[0].translations.find(
              (translation) => translation.languageKey === langCode
      ) 
      ? hofMenuItem[0].translations.find(
          (translation) => translation.languageKey === langCode
      ).menuTitle
          : hofMenuItem[0].menuTitle
      : hofMenuItem[0].menuTitle

      return <>
        <Nav.Link
          key='shop-wrapper-link'
          style={menuItem}
          onClick={() => {
            Activity({activityType: 'click',activityName: 'menu',activityArgument: 'Halloffame',activityArgumentID: ''})
            navigate(`${hofURL}`);
          }}
        >
          {title}
        </Nav.Link>
        {menuItems}
      </>
    }
  }

  const getTeamAccess = async () => {
    if (brandData?.features?.groupsHavingTeamMenuAccess?.length) {
      let groupNames = brandData.features.groupsHavingTeamMenuAccess
      let getAccess = await callAPI('/group/checkAccess', "GET", { query: { groupNames } });
      setTeamAccess(getAccess)
    }
}

  const getAnnouncements = async () => {
    let response = await callAPI('/announcements/web-pool', "GET");
    if(response?.length) {
      setAnnouncements(response)
    }
  } 
  
  useEffect(() => {
    getTeamAccess()
    getAnnouncements()
  }, [])
  
  useEffect(() => {
    if (menuData && !!menuData.length) {
      menuData.map((e, i) => {
        if (e.poolName.toLowerCase() === "halloffame") {
          let url = `/${e.poolName.toLowerCase()}`;
          if (!e.pool || e.pool.length === 0) return url;

          const key = Object.keys(e.pool[0]).find(
            (x) => x.toLowerCase() === `${e.poolName}ID`.toLowerCase()
          );
          setHOFURL(`${url}/${e.pool[0][key]}`);
        }
      });
    }
  }, [menuData])

  const badgeStyle = {
    width: 17,
    height: 17,
    position: 'absolute',
    top: -10,
    right: -10,
    background: styleGuide.color16,
    padding: 0,
    margin: 0,
    lineHeight: 0,
    // textAlign: 'center',
    // verticalAlign: 'middle',
    borderRadius: 5,
    fontSize: '11px',
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
  const pointSuffix = translate("_POINTS_SHORT_", "pt")
  return (
    <>
      <div style={{position: "absolute", top: 0, zIndex: 1, width: "100%"}}>
        {
          announcements?.length ? announcements.map((announcement) => {
            return (
              <div key={announcement.AnnouncementID} style={{width: '100%', background: announcement.messageType === "INFO" ? styleGuide.color18 : announcement.messageType === "SYSTEM" ? styleGuide.color13 : styleGuide.color19, display: 'flex', alignItems: "center", height: 50, justifyContent: "center"}}>
                <marquee behavior={"scroll"} direction={"left"} style={{ color: "#ffffff", width: "80%",  fontWeight: "bold", textTransform: 'capitalize' }}>{announcement.message}</marquee>
              </div>
            )
          }):null
        }
      </div>
      <Container fluid style={{ ...HeaderPosition, ...style, top: !!announcements ? announcements.length * 50 : 0 }} className={className}>
          <Row style={{ lineHeight: "50px", height: '100%' }}>
            <Col sm={12} className="d-none d-sm-inline header-left" style={{ backgroundColor: headerBgColor }}>
              <div style={headerLogo}>
                <img
                  className="clubTopHeaderLogo"
                  style={{ height: brandLogoHeight, width: brandLogoWidth, cursor: 'pointer' }}
                  src={brandData.brandLogo}
                  onClick={() => navigate('/')}
                />
              </div>
            </Col>
            <Col sm={12}
              style={{ paddingRight: 0, paddingLeft: 0 }}
              className="header-right"
            >
              <div style={{height: "100%"}}>
                <Navbar bg={"dark"} variant={"dark"} style={{height: "100%"}}>
                  <Container>
                    <Navbar.Brand className="d-none d-sm-inline">
                      <span
                        style={{
                          ...iconCSS,
                          color: styleGuide.color1,
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          Activity({activityType: 'click',activityName: 'menu',activityArgument: 'Profile',activityArgumentID: ''})
                          navigate('/profile')
                        }}
                      >
                        <Account2 fill={styleGuide.color1} />
                      </span>
                    {brandData?.features?.points === false || !!brandData?.features?.hideUserPointBalance ? null : (!themeConfig.disablePoints && <span style={{ fontSize: "18px", fontWeight: 500 }}>
                      {`${pointsConvertor(brandData,userData.walletAmountPoints, translate('_HEADER_POINTS_', 'point'))}`}
                    </span>)}
                    </Navbar.Brand>

                    <Navbar.Brand className="d-inline-flex d-sm-none mobile-header-logo" style={{ backgroundColor: headerBgColor }}>
                      <img
                        style={{ objectFit: "contain" }}
                        width={100}
                        height={60}
                        src={brandData.brandLogo}
                        onClick={() => navigate('/')}
                      />
                    </Navbar.Brand>
                    <Nav className="me-auto"></Nav>
                    <Nav className="mobileNav">
                      <Nav.Link className="d-inline d-sm-none">
                        <span
                          style={{
                            color: styleGuide.color1,
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            Activity({activityType: 'click',activityName: 'header',activityArgument: 'Profile',activityArgumentID: ''})
                            navigate('/profile')}}
                        >
                          <Account2 fill={styleGuide.color1} />
                        </span>
                      </Nav.Link>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {teamAccess && <Nav.Link
                        title="Team"
                        onClick={() => {
                          Activity({ activityType: 'click', activityName: 'header', activityArgument: 'Team', activityArgumentID: '' })
                          navigate('/team')
                        }}
                        className="d-sm-inline"
                      >
                        <span
                          style={{
                            ...iconCSS,
                            color: styleGuide.color1,
                            fontWeight: '500',
                            fontSize: '20px'
                          }}
                        >
                          Team
                        </span>
                      </Nav.Link>}
                        <Nav.Link onClick={() => {
                          Activity({activityType: 'click',activityName: 'header',activityArgument: 'Search',activityArgumentID: ''})
                          navigate('/search')}}>
                          <span
                            style={{
                              ...iconCSS,
                              color: styleGuide.color1,
                            }}
                          >
                            <Search fill={styleGuide.color1} />
                          </span>
                        </Nav.Link>
                        <Nav.Link eventKey={2} onClick={(e) => {
                          e.stopPropagation()
                          setShow(true)
                          }}>
                          <span
                            style={{
                              color: styleGuide.color1,
                            }}
                          >
                            <Menu fill={styleGuide.color1} />
                          </span>
                        </Nav.Link>
                      </div>
                    </Nav>
                  </Container>
                </Navbar>
              </div>
              {
                (!!pointsUpdate && pointsUpdate.showAlert && !!pointsUpdate.pointsFromLastLogin) &&
                <div className="points-notification-block" style={{ background: styleGuide.color16, color: styleGuide.color1 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                    <div style={{ display: 'flex', gap: 4 }}>
                      <Headline6 style={{ color: styleGuide.color1 }} translationKey="_HEADER_POINTS_SINCE_LAST_LOGIN_HI_" fallbackText={`Hej`} />
                      <Headline6 style={{ color: styleGuide.color1 }} fallbackText={userData.userName} />
                    </div>
                    <Close fill={styleGuide.color1} onClick={hidePointsUpdate} style={{ cursor: 'pointer' }} />
                  </div>
                  <div style={{ display: 'flex', gap: 4 }}>
                    <ContentLarge style={{ color: styleGuide.color1 }} translationKey="_HEADER_POINTS_SINCE_LAST_LOGIN_TEXT_ONE_" fallbackText={`Siden sidst har du optjent`} />
                    <ContentLarge style={{ color: styleGuide.color1 }} fallbackText={`${pointsConvertor(brandData,pointsUpdate.pointsFromLastLogin, pointSuffix)}`} />
                  </div>
                  <div style={{ display: 'flex', gap: 4 }}>
                    <ContentSmall style={{ color: styleGuide.color1 }} translationKey="_HEADER_POINTS_SINCE_LAST_LOGIN_TEXT_TWO_" fallbackText={`Du har nu i alt`} />
                    <ContentSmall style={{ color: styleGuide.color1 }} fallbackText={`${pointsConvertor(brandData,userData.walletAmountPoints, pointSuffix)}`} />
                  </div>
                </div>
              }
              <Drawer visible={show} closeHandler={handleClose}>
                <div style={{ ...menuPosition, paddingRight: 40, minHeight: !!announcements && announcements.length ? '110vh' : '100vh', display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: 20 }}>
                    <Close fill={styleGuide.color7} onClick={() => handleClose()} style={{ cursor: 'pointer' }} />
                  </div>
                  <div>
                    <p style={{ fontSize: "14px" }}>Menu</p>
                    <NewsMenu />
                    <HOFMenu />
                  </div>

                  <div style={{ paddingTop: "5vw", marginTop: 100, marginBottom: 50 }}>
                    <p style={{ fontSize: "14px", lineHeight: '18px' }}>
                      {
                        translate(
                          "_MENU_OTHER_LINKS_CAPTION_",
                          "Andet"
                        )
                      }
                    </p>
                    <Nav.Link
                      style={subMenuItem}
                      href="#"
                      onClick={() => {
                        Activity({activityType: 'click',activityName: 'menu',activityArgument: 'Profile',activityArgumentID: ''})
                        navigate('/profile')}}
                    >
                      {
                        translate(
                          "_MENU_MY_PROFILE_",
                          "Din profil"
                        )
                      }
                    </Nav.Link>
                    <Nav.Link
                      style={subMenuItem}
                      href="#"
                      onClick={() => { handleClose(); setStatusscroll(true);  Activity({activityType: 'click',activityName: 'menu',activityArgument: 'Profile',activityArgumentID: ''})
                      navigate('/profile')}}
                    >
                      {
                        translate(
                          "_MENU_PROFILE_SETTINGS_",
                          "Indstillinger"
                        )
                      }
                    </Nav.Link>
                    {brandData && brandData.features && brandData.features.claims && <Nav.Link
                      style={subMenuItem}
                      href="#"
                      onClick={() => {
                        Activity({activityType: 'click',activityName: 'menu',activityArgument: 'Report',activityArgumentID: ''})
                        navigate('/indberetning')}}
                    >
                      {
                        translate(
                          "_MENU_REPORTING_",
                          "Indberetning"
                        )
                      }
                    </Nav.Link>}
                    <Nav.Link
                      style={subMenuItem}
                      href="#"
                      onClick={() => { handleClose(); Activity({activityType: 'click',activityName: 'menu',activityArgument: 'Help',activityArgumentID: ''})
                       navigate("/help", {state:{scrollToContact:true}}) }}
                    >
                      {
                        translate(
                          "_MENU_CONTACT_",
                          "Kontakt"
                        )
                      }
                    </Nav.Link>
                    <Nav.Link
                      style={subMenuItem}
                      href="#"
                      onClick={() => { handleClose(); Activity({activityType: 'click',activityName: 'menu',activityArgument: 'Help',activityArgumentID: ''})
                      navigate("/help",{state:{scrollToFaq:true}}) }}
                    >
                      FAQ
                    </Nav.Link>
                    <Nav.Link style={subMenuItem} href="#" onClick={() => {
                      Activity({activityType: 'click',activityName: 'menu',activityArgument: 'Logout',activityArgumentID: ''})
                      setShowConfirmLogout(true)}}>
                      {
                        translate(
                          "_MENU_LOG_OUT_",
                          "Log ud"
                        )
                      }
                    </Nav.Link>
                  </div>
                </div>
              </Drawer>
            </Col>
          </Row>
      </Container>
      <Modal show={showConfirmLogout} onHide={() => setShowConfirmLogout(false)} style={{ margin: 'auto' }} centered>
        <Modal.Header className="pb-0 border-0" closeButton style={{ fontSize: 9 }}>
          <Headline6 translationKey="_CONFIRM_LOGOUT_">Bekræft log ud </Headline6>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <ContentSmall translationKey="_CONFIRM_LOGOUT_TEXT_">Er du sikker på at du vil logge ud? </ContentSmall>
          <div style={{
            display: 'flex',
            padding: '1rem',
            justifyContent: 'space-around',
          }}>
            <ButtonMedium1 showIcon={false} translationKey="_YES_" fallbackText="Ja" onClick={() => clearSession()} />
            <ButtonMedium4 showIcon={false} translationKey="_NO_" fallbackText="Nej" onClick={() => setShowConfirmLogout(false)} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const HeaderPosition = {
  // position: "absolute",
  // top: 0,
  height: "100px",
  display: "flex",
  flexDirection: "column",
};

const menuPosition = {
  paddingBottom: "20px",
  paddingLeft: "50px",
  paddingTop: "30px",
};

const menuItem = {
  fontWeight: "600",
  padding: "4px 0px",
  color: "black",
  fontSize: "30px",
  lineHeight: '45px'
};

const subMenuItem = {
  fontSize: "18px",
  fontWeight: "600",
  padding: "2px 0px",
  color: "black",
  lineHeight: '28px'
};
const iconCSS = {
  marginRight: "8px",
};

const headerLogo = {
  margin: "16px 80px",
};
export default Header1;
