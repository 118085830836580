import React from 'react'
import Error from 'components/icons/Error.js'

export default function RegisterError(props) {
  return (
    <div className='register-form__error'>
    <div style={{margin:'auto',marginTop:'10%',alignSelf:'center',width:'100px'}}><Error/></div>
      <h4 className='mt-5 mb-3 text-center'>{props.errorMessage}</h4>
      {props.subMessage && <h6 className='text-center' dangerouslySetInnerHTML={{ __html: props.subMessage }}></h6>}
    </div>
  )
}
