import { AppContext } from "contexts/AppContext";
import React, { useContext, useEffect, useState } from "react";
import CustomInput from "components/elements/CustomInput";
import Search from "components/icons/Search";
import { Row, Col } from "react-bootstrap";
import { translate } from "utils/Translate";
import "./Search.css";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { callAPI } from "utils/API";
import HorizontalScrollBlock from "components/blocks/horizontalScrollBlock/HorizontalScrollBlock";
import ProductNormal from "components/elements/ProductNormal";
import ButtonMedium4 from "components/elements/ButtonMedium4";
import PostCard from "components/elements/PostCard";
import { getVideoType } from "components/elements/Video";
import { Grid2 as Grid } from "@mui/material";
import Headline5 from "components/elements/Headline5";
import ButtonMedium1 from "components/elements/ButtonMedium1";
import CircularIndeterminate from "components/blocks/progressBar/progressBar";
import Headline2 from "components/elements/Headline2";
import CountDownBlock from "components/blocks/countDownBlock/CountDownBlock";
import { pointsConvertor } from "utils/general";
import moment from "moment";
import { setupProductImages } from "utils/productImageHelper";

const maxLengthForNewsTitle = 25;
const maxLengthForNewsDescription = 75;
const jss = createUseStyles({
  searchTabs: {
    "& .MuiToggleButtonGroup-grouped":{
      color: (styleGuide) => styleGuide.color16,
    },
  },
  seeMoreButton: {
    position: "relative",
    marginTop: 30,
  },
  "@media screen and (min-width: 1280px)": {
    seeMoreButton: {
      position: "absolute",
      marginTop: 0,
      top: 15,
      right: 0,
    },
  },
});
const SearchComponent = () => {
  const pointSuffix = translate("_POINTS_SHORT_", "pt")
  const initialStateForUserEnteredParams = () => {
    const userEnteredParams = JSON.parse(localStorage.getItem('userEnteredParams') || '{}')
    return !userEnteredParams.search 
      ? { keyword: "", resultType: "showAll"}
      : { keyword: userEnteredParams.search.keyword || "", resultType: userEnteredParams.search.resultType || "showAll"}
  }
  const { styleGuide, brandData, langCode } = useContext(AppContext);
  const { keyword, resultType } = initialStateForUserEnteredParams();
  const [searchString, setSearchString] = useState(keyword);
  const [searchResults, setSearchResults] = useState([]);
  const [alignment, setAlignment] = useState(resultType);
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [products, setProducts] = useState([]);
  const [loadMoreProducts, setLoadMoreProducts] = useState(6);
  const [loadMorePosts, setLoadMorePosts] = useState(6);
  const [loadMoreCompetitions, setLoadMoreCompetitions] = useState(6);
  const navigate = useNavigate();
  const [likedProducts, setLikedProducts] = useState([]);
  const classes = jss(styleGuide);
  const filtersRef = React.createRef();

  const getResultCount = {
    showall: Number(products.length) + Number(posts.length) + Number(competitions.length),
    products: Number(products.length),
    posts: Number(posts.length),
    competitions: Number(competitions.length)
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    getSearchResults(searchString);
  };

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
      // color: "#E66B2E !important",
      fontWeight: "600 !important",
      fontSize: "14px !important",
      border: "0px !important",
      borderRadius: "14px !important",
      "&.Mui-selected:hover": {
        backgroundColor: "#E8E8E8 !important",
      },
      "&.Mui-selected": {
        backgroundColor: "#E8E8E8 !important",
        color: "black !important",
        fontWeight: "600 !important",
        border: "none !important",
      },
      "&:not(:last-child)": {
        borderRight: "none !important",
      },
      "&:first-of-type": {
        borderTopLeftRadius: "14px !important",
        borderBottomLeftRadius: "14px !important",
      },
      "&:last-child": {
        borderTopRightRadius: "14px !important",
        borderBottomRightRadius: "14px !important",
      },
    },
  }));

  const resetFilters = () => {
    setSearchResults([]);
    setSearchString("");
    setProducts([]);
    setPosts([]);
    setCompetitions([])
    setAlignment("showAll");
  };

  const getSearchResults = async (val) => {
    setLoading(true)
    const keyword = (val==='' || !!val) ? val : searchString || ''
    setSearchString(keyword);
    let results = await callAPI("/search/all", "GET", {
      query: { searchString: keyword },
    });
    setSearchResults(results);
    setPosts(results.posts && !!results.posts.length ? results.posts : []);
    if(brandData.features && brandData.features.profileMyCompetition){
      setCompetitions(results.competitions && !!results.competitions.length ? results.competitions : [])
    }
    const products =
      results.products && !!results.products.length ? results.products : [];
    let liked = [];
    if (products && !!products.length) {
      products.forEach((product) => {
        setupProductImages(product, true)
        if (
          product.reaction &&
          product.reaction.ReactionID &&
          product.reaction.ReactionID > 0
        ) {
          liked.push(product.ProductID);
        }
      });
    }
    setLikedProducts(liked);
    setProducts(products);
    setLoading(false)
  };

  const isVideo = (attachments) => {
    const videoAttachment = attachments.filter(
      (item) => item.LinkID && item.linkRef
    );
    return videoAttachment.length ? true : false;
  };

  const getVideoLink = (attachments) => {
    const videoAttachment = attachments.filter(
      (item) => item.LinkID && item.linkRef
    );
    const link = videoAttachment.length ? videoAttachment[0].linkRef : null;
    const videoInfo = getVideoType(link);
    return videoInfo ? videoInfo.url : null;
  };

  const productReaction = async (product) => {
    const ReactionID = product.reaction ? product.reaction.ReactionID : null;
    const reaction = await callAPI("/product/reaction", "POST", {
      body: {
        ReactionTargetID: product.ProductID,
        reactionData: JSON.stringify({ ShopID: product.ShopID }),
        ...(ReactionID && { ReactionID }),
      },
    });
    if (reaction && reaction.affectedRows) {
      getSearchResults(searchString);
    }
  };

  const retainUserEnteredParams = () => {
    const userEnteredParams = JSON.parse(localStorage.getItem('userEnteredParams') || '{}')
    userEnteredParams.search = { keyword: searchString, resultType: alignment }
    return localStorage.setItem('userEnteredParams', JSON.stringify(userEnteredParams))
  }

  useEffect(retainUserEnteredParams, [searchString, alignment])
  useEffect(getSearchResults, [])

  return (
    <>
      <div style={{ marginTop: "50px" }} ref={filtersRef}>
        <Row>
          <Col sm={4}>
            <CustomInput
              wrapperStyle={{ marginBottom: 10, padding: "0.5rem" }}
              label={translate(
                "_SEARCH_LABEL_",
                "Indtast dit søgeord"
              )}
              value={searchString}
              onChange={(e) => getSearchResults(e.target.value)}
              customIcon={<Search fill={styleGuide.color7} />}
            />
          </Col>
          <Col sm={8}>
            <div className="searchButtonGroup">
              <Paper
                elevation={0}
                sx={{
                  border: `1px solid rgba(0, 0, 0, 0.12)`,
                  borderRadius: "14px !important",
                }}
              >
                <Row sm={12}>
                  <StyledToggleButtonGroup
                    className={`${classes.searchTabs}`}
                    color="primary"
                    value={alignment}
                    exclusive
                    size="small"
                    onChange={handleChange}
                    fullWidth={true}
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                   <ToggleButton value="showAll" className="w-100">
                      {translate("_SEARCH_PAGE_VIEW_ALL_","Vis alle")}
                    </ToggleButton>
                    <ToggleButton value="products" className="w-100">
                      {translate("_SEARCH_PAGE_VIEW_PRODUCTS_","Produkter")}
                    </ToggleButton>
                    <ToggleButton value="posts" className="w-100">
                      {translate("_SEARCH_PAGE_VIEW_NEWS_","Nyheder")}
                    </ToggleButton>
                    {brandData.features && brandData.features.profileMyCompetition ? <ToggleButton value="competitions" className="w-100">
                      {translate("_SEARCH_PAGE_VIEW_COMPETITIONS_","Konkurrencer")}
                    </ToggleButton> : null}
                  </StyledToggleButtonGroup>
                </Row>
              </Paper>
            </div>
          </Col>
        </Row>
        <Grid container style={{ marginTop: 10, padding: "0.7rem" }}>
          {
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              size={{
                xs: 12,
                sm: 10
              }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {
                  searchString 
                  ?
                  loading 
                    ?
                      <CircularIndeterminate />
                    : 
                      <>
                        <Headline5
                          translationKey="_SEARCH_RESULTS_CAPTION_"
                          fallbackText={`${
                            getResultCount[alignment.toLowerCase()]
                          } resultater for`}
                        />
                        <span>&nbsp;</span>
                        <Headline5 fallbackText={` “${searchString}”`} />
                      </>
                :
                  <>
                    <Headline5
                      translationKey="_SEARCH_NO_RESULTS_CAPTION_"
                      fallbackText="Ingen resultater"
                    />
                  </>
                }
              </div>
            </Grid>
          }
          {((products && !!products.length) || (posts && !!posts.length)) || (competitions && !!competitions.length) && (
            <Grid
              className="resetFilterButton"
              style={{
                flexDirection: "row",
                alignItems: "center",
                display: "flex",
              }}
              size={{
                xs: 12,
                sm: 2
              }}>
              <ButtonMedium4
                onClick={resetFilters}
                showIcon={false}
                translationKey="_SEARCH_RESET_FILTERS_LABEL_"
                fallbackText="Ryd alt"
              />
            </Grid>
          )}
        </Grid>
        {alignment.toLowerCase() === "showall" && (
          <>
            {products && !!products.length && (
              <div style={{ position: "relative", marginTop: "30px" }}>
                <HorizontalScrollBlock caption="Produkter">
                  {products.map(item => {
                    const productTitle = item.translations?.find((translation) => translation.languageKey === langCode)?.elementTitle ?? item.elementTitle                
                    return <ProductNormal
                      key={item.ProductID || item.ProductPackageID}
                      productID={item.ProductID}
                      showTag={item.enableDiscount ? true : false}
                      tagOptions={{
                        fallbackText: `${translate(
                          "_PRODUCT_DISCOUNT_TAG_TEXT_",
                          "Spar"
                        )} ${pointsConvertor(brandData,item.productPointComputed - item.discountPointComputed, pointSuffix)}`,
                        showClose: false,
                      }}
                      tagStyles={{ borderRadius: 8 }}
                      points={`${item.discountPointComputed ? item.discountPointComputed : item.productPointComputed}`}
                      liked={item.reaction && item.reaction.ReactionID ? true : false}
                      className="productlarge item"
                      style={{ boxShadow: `0px 0px 3px 1px rgba(34, 34, 34, 0.15)` }}
                      text2={productTitle && productTitle.length > 20 ? `${productTitle.slice(0, 20)}...` : productTitle}
                      text1={item.manufacturer}
                      wishlistCallback={() => productReaction(item)}
                      imageSrc={`url(${item.image?.src})`}
                      imageBackgroundColor={item.image?.backgroundColor}
                      activityName='search'
                    />
                })}
                </HorizontalScrollBlock>
                <div className={classes.seeMoreButton}>
                  <ButtonMedium4
                    style={{ margin: "auto" }}
                    showIcon={false}
                    translationKey="_HOME_POPULAR_PRODUCTS_SEE_MORE_BUTTON_"
                    onClick={(e) => {
                      handleChange(e, "products");
                      filtersRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }}
                  >
                    Se alle produkter
                  </ButtonMedium4>
                </div>
              </div>
            )}
            {posts && !!posts.length && (
              <div style={{ position: "relative", marginTop: "100px" }}>
                <HorizontalScrollBlock caption="Nyheder">
                  {posts.map((post) => {
                    post.attachments.sort((a, b) => a.poolOrder - b.poolOrder);
                    const cardImage = post.cardImage || (post.attachments.length && post.attachments[0].imageOriginSource)
                      ? post.attachments[0].imageOriginSource
                      : "https://i.imgur.com/qtmXhnZ.jpg";
                    const video =
                      post.attachments.length && isVideo(post.attachments)
                        ? getVideoLink(post.attachments)
                        : null;

                    return (
                      <PostCard
                        style={{
                          boxShadow: `0px 1px 3px 0px black`,
                          minWidth: "580px",
                        }}
                        onClick={() => navigate(`posts/${post.PostID}`)}
                        title={
                          post.elementTitle.length > maxLengthForNewsTitle
                            ? post.elementTitle.substr(
                                0,
                                maxLengthForNewsTitle - 3
                              ) + "..."
                            : post.elementTitle || ""
                        }
                        description={
                          post.elementContent.substr(
                            0,
                            maxLengthForNewsDescription - 3
                          ) + "..." || ""
                        }
                        imageSrc={`url(${cardImage})`}
                        type={video ? "video" : "image"}
                        videoLink={video}
                      />
                    );
                  })}
                </HorizontalScrollBlock>
                <div className={classes.seeMoreButton}>
                  <ButtonMedium4
                    style={{ margin: "auto" }}
                    showIcon={false}
                    translationKey="_SEARCH_PAGE_POSTS_SEE_MORE_BUTTON_"
                    onClick={(e) => {
                      handleChange(e, "posts");
                      filtersRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }}
                  >
                    Se alle nyheder
                  </ButtonMedium4>
                </div>
              </div>
            )}
            {competitions && !!competitions.length && (
              <div style={{ position: "relative", marginTop: "100px" }}>
                <div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Headline2 translationKey="_SEARCH_PAGE_COMPETITION_HEADING_" fallbackText="Konkurrencer" />
                  </div>
                  {
                    competitions && competitions.length < 2 ?
                    <>
                      {competitions.map((competition, idx) => (
                        <CountDownBlock key={idx} competition={competition} styles={{marginTop: 50}} buttonTitle="Deltag i konkurrencen"/>
                      ))}
                    </> :
                    <div className="countDownHorizontalItems">
                      <HorizontalScrollBlock>
                        {competitions.map((competition, idx) => (
                          <CountDownBlock key={idx} competition={competition} buttonTitle="Deltag i konkurrencen"/>
                        ))}
                      </HorizontalScrollBlock>
                    </div>
                  }
                </div>
                <div className={classes.seeMoreButton}>
                  <ButtonMedium4
                    style={{ margin: "auto" }}
                    showIcon={false}
                    translationKey="_SEARCH_PAGE_COMPETITION_SEE_MORE_BUTTON_"
                    onClick={(e) => {
                      handleChange(e, "competitions");
                      filtersRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }}
                  >
                    Se alle konkurrencer
                  </ButtonMedium4>
                </div>
              </div>
            )}
          </>
        )}
        {alignment.toLowerCase() === "products" && (
          <>
            {!!products && !!products.length && (
              <Grid
                className="product-grid"
                container
                rowSpacing={1}
                columnSpacing={17}
              >
                {products.slice(0, loadMoreProducts).map(item => {
                  const productTitle = item.translations?.find((translation) => translation.languageKey === langCode)?.elementTitle ?? item.elementTitle
                  return <Grid
                    key={item.ProductID || item.ProductPackageID}
                    sm2={4}
                    size={{
                      xs: 12,
                      sm: 6
                    }}>
                    <ProductNormal
                      productID={item.ProductID}
                      showTag={item.enableDiscount ? true : false}
                      tagOptions={{
                        fallbackText: `${translate(
                          "_PRODUCT_DISCOUNT_TAG_TEXT_",
                          "Spar"
                        )} ${
                          item.productPointComputed - item.discountPointComputed
                        } ${pointSuffix}`,
                        showClose: false,
                      }}
                      tagStyles={{ borderRadius: 8 }}
                      points={`${item.discountPointComputed ? item.discountPointComputed : item.productPointComputed}`} 
                      liked={likedProducts.includes(item.ProductID)}
                      className="productlarge item"
                      style={{ boxShadow: `0px 0px 100px rgba(34, 34, 34, 0.15)` }}
                      text2={productTitle && productTitle.length > 20 ? `${productTitle.slice(0, 20)}...` : productTitle}
                      text1={item.manufacturer}
                      wishlistCallback={() => productReaction(item)}
                      imageSrc={`url(${item.image?.src})`}
                      imageBackgroundColor={item.image?.backgroundColor}
                      activityName='search'
                    />
                  </Grid>
                })}
              </Grid>
            )}
            <Row>
              {Number(loadMoreProducts) <
                Number(products && products.length) && (
                <div>
                  <ButtonMedium1
                    onClick={() => setLoadMoreProducts(loadMoreProducts + 3)}
                    style={{ margin: "auto" }}
                    showIcon={false}
                    translationKey="_SEARCH_PRODUCTS_LOAD_MORE_BUTTON_"
                  >
                    Indlæs flere
                  </ButtonMedium1>
                </div>
              )}
            </Row>
          </>
        )}
        {alignment.toLowerCase() === "posts" && (
          <>
            <div>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {posts &&
                  !!posts.length &&
                  posts.slice(0, loadMorePosts).map((post) => {
                    post.attachments.sort((a, b) => a.poolOrder - b.poolOrder);
                    const cardImage = post.cardImage || (post.attachments.length && post.attachments[0].imageOriginSource)
                      ? post.attachments[0].imageOriginSource
                      : "https://i.imgur.com/qtmXhnZ.jpg";
                    const video =
                      post.attachments.length && isVideo(post.attachments)
                        ? getVideoLink(post.attachments)
                        : null;

                    return (
                      <Grid
                        key={post.PostID}
                        size={{
                          xs: 12,
                          md: 6
                        }}>
                        <PostCard
                          onClick={() => navigate(`/posts/${post.PostID}`)}
                          style={{ marginBottom: "25px" }}
                          title={
                            post.elementTitle.length > maxLengthForNewsTitle
                              ? post.elementTitle.substr(
                                  0,
                                  maxLengthForNewsTitle - 3
                                ) + "..."
                              : post.elementTitle || ""
                          }
                          description={
                            post.elementContent.substr(
                              0,
                              maxLengthForNewsDescription - 3
                            ) + "..." || ""
                          }
                          imageSrc={`url(${cardImage})`}
                          type={video ? "video" : "image"}
                          videoLink={video}
                        />
                      </Grid>
                    );
                  })}
              </Grid>

              {Number(loadMorePosts) < Number(posts && posts.length) && (
                <div>
                  <ButtonMedium1
                    onClick={() => setLoadMorePosts(loadMorePosts + 4)}
                    style={{ margin: "20px auto" }}
                    showIcon={false}
                    translationKey="_HOME_NEWS_LOAD_MORE_BUTTON_"
                  >
                    Indlæs flere
                  </ButtonMedium1>
                </div>
              )}
            </div>
          </>
        )}
        {alignment.toLowerCase() === "competitions" && (
          <>
            <div>
              {competitions && !!competitions.length &&
                competitions.slice(0, loadMoreCompetitions).map((competition, idx) => {
                  return <CountDownBlock key={idx} competition={competition} styles={{marginTop: 50}} buttonTitle="Deltag i konkurrencen"/>
              })}

              {Number(loadMoreCompetitions) < Number(competitions && competitions.length) && (
                <div>
                  <ButtonMedium1
                    onClick={() => setLoadMoreCompetitions(loadMoreCompetitions + 4)}
                    style={{ margin: "20px auto" }}
                    showIcon={false}
                    translationKey="_HOME_COMPETITIONS_LOAD_MORE_BUTTON_"
                  >
                    Indlæs flere
                  </ButtonMedium1>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SearchComponent;
