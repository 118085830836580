
import React, { useContext, useState } from 'react'
import { callAPI } from 'utils/API';
import { translate } from 'utils/Translate';
import { AppContext } from 'contexts/AppContext';
import { Dialog, DialogActions, DialogContent, DialogContentText, Switch } from '@mui/material';
import ButtonMedium1 from 'components/elements/ButtonMedium1';
import ButtonMedium2 from 'components/elements/ButtonMedium2';
import ContentNormal from 'components/elements/ContentNormal';

const AutomaticActivationPrompt = (props) => {
    const context = useContext(AppContext)
    const { userData, brandData, langCode, setUserData } = context
    const [activate, setActivate] = useState(userData.enableAutoWalletActivation === 1)
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)

    const activationPointsSectionLabels = brandData.features?.upgraderClubConfigurableLabels && brandData.features.upgraderClubConfigurableLabels[langCode]?.profilePageLabels?.activationPointsSectionLabels
        ? brandData.features.upgraderClubConfigurableLabels[langCode].profilePageLabels.activationPointsSectionLabels : null

    const handleActivationToggle = (e) => {
        setActivate(e.target.checked)
        setShowConfirmDialog(true)
    }

    const handleAccept = async () => {
        let response = await callAPI("/user/toggleAutoWalletActivation", "POST");
        if (response) {
            setUserData({ ...userData, enableAutoWalletActivation: userData.enableAutoWalletActivation === 1 ? 0 : 1 })
        }
        setShowConfirmDialog(false)
        props.onConfirm()
    }

    const handleClose = async () => {
        setActivate(!activate)
        setShowConfirmDialog(false)
    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <ContentNormal htmlFor='activate_checkbox' style={{fontWeight: 'bold'}} >
                    {activationPointsSectionLabels?.automaticActivationRemarkText ? activationPointsSectionLabels.automaticActivationRemarkText : translate('_ACTIVATE_POINTS_AUTOMATIC_CHECKBOX_', 'Aktivér altid dine point automatisk:')}
                </ContentNormal>
                <Switch
                    id='activate_checkbox'
                    checked={activate}
                    onClick={(e) => handleActivationToggle(e)}
                />
            </div>
            <ActivationDialogBox show={showConfirmDialog} activate={activate} handleClose={handleClose} handleAccept={handleAccept} />
        </div>
    )
}

export const ActivationDialogBox = (props) => {
    const { activate, handleClose, handleAccept, show } = props;
  
    return (
      <div>
        <Dialog
          open={show}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {activate
                ? translate('_AUTOMATIC_ACTIVATION_OF_SALES_', 'Vil du aktivere alle point automatisk? Bemærk at aktiverede point indberettes til Skattestyrelsen')
                : translate('_MANUAL_ACTIVATION_OF_SALES_', 'Vil du ændre din indstilling til manuel aktivering af dine point?')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonMedium2 showIcon={false} translationKey="_CANCEL_" fallbackText="Afvis"  onClick={handleClose}/>
            <ButtonMedium1 showIcon={false} translationKey="_ACCEPT_" fallbackText="Godkend"  onClick={handleAccept} />
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  

export default AutomaticActivationPrompt;




        
