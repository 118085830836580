import { Outlet } from "react-router-dom"
import Footer from "components/blocks/footer/Footer";
import Header from "components/blocks/header/Header";
import "./Page.css"
import { AppContext } from "contexts/AppContext";
import { useContext, useEffect } from "react";
import { CPRPopUp } from "components/blocks/cprPopUp/CPRPopUP";
import { createUseStyles } from "react-jss";

const jss = createUseStyles({
    ContentWrapper: {
        marginTop: (styleGuide) => styleGuide.headerHeight,
    },
    "@media screen and (max-width: 900px)": {
        ContentWrapper: {
            marginTop: (styleGuide) => styleGuide.headerHeightMobile,
        },
    },
});

const Page = (props) => {
    const { styleGuide, brandData } = useContext(AppContext);
    const classes = jss(styleGuide);
    const existsCPR = localStorage.getItem("cprExists")

    useEffect(() => {
        const body = document.getElementsByTagName('body')[0]
        body.style.backgroundColor = brandData.features && brandData.features.largeScreenBackground 
        ?   brandData.features.largeScreenBackground
        :   styleGuide.color3

        return () => {
            const body = document.getElementsByTagName('body')[0]
            body.style.backgroundColor = ''
        }
    }, [brandData]);
    
    return <>
        <div className="page-wrapper" >
            <Header style={headerPosition} />
            <div className={`${classes.ContentWrapper} content-wrapper`}>
                {brandData && brandData.features && brandData.features.LaunchCPRPopupAfterLogin && !parseInt(existsCPR) && <CPRPopUp />}
                <Outlet />
            </div>
            <Footer style={footerPosition} />
        </div>
    </>
}

const headerPosition = {
    position: 'absolute',
    width: '100%',
    top: 0
}

const footerPosition = {
    position: 'absolute',
    width: '100%',
    bottom: 0
}

export default Page