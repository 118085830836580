import 'bootstrap/dist/css/bootstrap.min.css';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from "contexts/AppContext";
import { callAPI } from "utils/API";
import UpgraderClubRouter from 'Routes';
import NoBrandData from 'components/elements/NoBrandData';
import { setupBrandData, setupMenuData, setupBlogData, setupShopData, setupUserData, setupBasketData, setupHOFData,setMaintenanceData, setupUserPrompts} from 'utils/setup'
import UnderMaintenance from 'modules/session/underMaintenance';
import { useLocation } from 'react-router-dom';

function App() {
  const contextState = useContext(AppContext);
  const { userData, brandData, menuData, themeConfig, langCode, setLangCode, isSuperAdmin, setIsSuperAdmin } = contextState
  const location = useLocation()
  const queryString = new URLSearchParams(location.search)
  const langFromUrl = queryString.get('lang') || langCode

  const [isLoadingBrand, setIsLoadingBrand] = useState(true)
  const [isLoadingUser, setIsLoadingUser] = useState(true)

  const adminToken = localStorage.getItem('adminToken') || ""
  const adminUser = localStorage.getItem('adminUser') || ""
  
  const adminTokenFromUrl = location.search ? new URLSearchParams(location.search).get('adminToken') : null
  if(!!adminTokenFromUrl) localStorage.setItem('adminToken', adminTokenFromUrl)
  
  const adminUserFromUrl = location.search ? new URLSearchParams(location.search).get('adminUser') : null
  if(!!adminUserFromUrl) localStorage.setItem('adminUser', adminUserFromUrl)

  const validateAdminToken = (token, UserID) => {
    if(!token || !UserID) return false
    const expectedToken = Buffer.from(`${UserID}_system_manage`).toString('base64')
    return expectedToken === token
  }

  useEffect(() => {
    if(!isSuperAdmin){
      const adminValidation = !adminUser || isNaN(adminUser) ? false : validateAdminToken(adminToken, Number(adminUser))
      setIsSuperAdmin(adminValidation)
    }
  },[adminToken, adminUser])

  useEffect(() => {
    setupBrandData(contextState, setIsLoadingBrand)
    if(!userData || !userData.UserID) {
      setupUserData(contextState, setIsLoadingUser)
    }
  }, [])

  useEffect(() =>{
    if(langFromUrl !== langCode) {
      setLangCode(langFromUrl)
    }
  },[langFromUrl])

  const updateStyleSheet = (data) =>{
    if (!data) {
      if (document.getElementById('UpgraderDynamicCSS')) {
        let elm = document.getElementById('UpgraderDynamicCSS')
        elm.parentNode.removeChild(elm)
      }
      return false
    }
    if (!document.getElementById('UpgraderDynamicCSS')) {
      let $style = document.createElement('style')
      $style.id = 'UpgraderDynamicCSS'
      $style.type = 'text/css'
      document.head.appendChild($style)
    }
    document.getElementById('UpgraderDynamicCSS').innerHTML = data
  }

  useEffect(() => {
      setupMenuData(contextState)
      !themeConfig.disableBasket && setupBasketData(contextState)
  }, [userData])

  useEffect(() => {
      setupUserPrompts(contextState)
  }, [userData?.UserID])

  useEffect(() => {
      setupBlogData(contextState)
      !themeConfig.disableShop && setupShopData(contextState) 
      setupHOFData(contextState) 
  }, [menuData])

  useEffect(() => {
    if (brandData && Object.keys(brandData).length > 0) {
      updateStyleSheet(brandData.customCSS);
    }
  }, [brandData]);

  useEffect(() => {
    if(userData && userData.UserID){
      setInterval(() => {
        setMaintenanceData(contextState)
      }, 30000)
    }
  },[userData])

  if(isLoadingBrand || isLoadingUser) return <div />
  return (!brandData || !brandData.BrandID ) ? <NoBrandData /> : ((!!brandData?.features?.shutDownAllBrands||!!brandData?.features?.isDownForMaintenance) && !isSuperAdmin) ? <UnderMaintenance/> : <UpgraderClubRouter />
}

export default App;
