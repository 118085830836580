import CustomCarousel from "components/blocks/customCarousel/CustomCarousel";
import { AppContext } from "contexts/AppContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { callAPI } from "utils/API";
import './Package.css'
import _ from 'lodash'
import Image from 'components/elements/Image'
import ArrowRight from 'components/icons/ArrowRight'
import ArrowLeft from 'components/icons/ArrowLeft'
import { translate } from "utils/Translate";
import ButtonMedium4 from "components/elements/ButtonMedium4";
import { ScrollProductsSection } from "modules/product/Product1";
import Headline2 from "components/elements/Headline2";
import Headline6 from "components/elements/Headline6";
import Playbutton from "components/icons/Playbutton";
import Pausebutton from "components/icons/Pausebutton";
import CaptionText from "components/elements/CaptionText";
import { Grid2 as Grid, Tab, Tabs, } from "@mui/material"; 
import {TabContext, TabPanel} from "@mui/lab"
import Tick from "components/icons/Tick";
import Close from "components/icons/Close";
import ContentNormal from "components/elements/ContentNormal";
import ContentSmall from "components/elements/ContentSmall";
import IconButtonLarge from "components/elements/IconButtonLarge";
import Heart2 from "components/icons/Heart2";
import { createUseStyles } from "react-jss";
import Cart1 from "components/icons/Cart1";
import ButtonLarge1 from "components/elements/ButtonLarge1";
import ButtonInactive from "components/elements/ButtonInactive";
import { parseLinks, pointsConvertor } from "utils/general";
import { addPackageToBasket } from "utils/basket";
import { setupBasketData } from "utils/setup";
import ButtonMedium1 from "components/elements/ButtonMedium1";
import ExplainerText from "components/elements/ExplainerText";
import ExplainerTextTiny from "components/elements/ExplainerTextTiny";
import { setupProductImages } from "utils/productImageHelper";

const jss = createUseStyles({
    TabStyle: {
        color: (styleGuide) => styleGuide.color16,
        '&:hover': {
            backgroundColor: (styleGuide) => styleGuide.color2,
        },
        '&:focus': {
            color: (styleGuide) => styleGuide.color7,
        },
        '&.Mui-selected': {
            backgroundColor: (styleGuide) => styleGuide.color3,
            color: (styleGuide) => styleGuide.color7,
        }
    },
    InactiveButtonStyle: {
        backgroundColor: '#e8e8e8',
        borderRadius: (styleGuide) => styleGuide.buttonBorderRadius,
        height: (styleGuide) => styleGuide.buttonLargeHeight,
        fontSize: (styleGuide) => styleGuide.buttonLargeFontSize,
        fontFamily: (styleGuide) => styleGuide.secondaryFontFamily,
        lineHeight: (styleGuide) => styleGuide.buttonLargeLineHeight,
        fontWeight: '600',
        border: 'none',
        padding: '14px 16px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'not-allowed'
    }
})

function Package2() {
    const { PackageID } = useParams()
    const navigate = useNavigate();
    const { styleGuide, accessibleShops, basket, userData, setBasket, brandData, defaultTheme } = useContext(AppContext)
    const defaultCarouselInterval = 1000
    const videoRef = useRef();
    const classes = jss(styleGuide)
    const ShopIDs = accessibleShops.map(item => item.ShopID)
    const summaryContentLength = 200

    const [packageDetails, setPackageDetails] = useState({})
    const [interval, setinterval] = useState(defaultCarouselInterval);
    const [productAddedToCart, setproductAddedToCart] = useState(false);
    const [activeIndex, setactiveIndex] = useState(null);
    const [recentlyViewedProducts, setRecentlyViewedProducts] = useState([])
    const [relatedProducts, setRelatedProducts] = useState([])
    const [contentContainerHeight, setContentContainerHeight] = useState(0);
    const [videoHeight,setVideoHeight]=useState()
    const [viewMore, setViewMore] = useState(false);
    const [play, setPlay] = useState(true);
    const [ShopID, setShopID] = useState(accessibleShops && accessibleShops.length > 0 ? accessibleShops[0].ShopID : null)
    const [productVariations, setproductVariations] = useState([])
    const pointSuffix = translate("_POINTS_SHORT_", "pt")

    let productsWithVariations = []
    let productsWithoutVariations = {}
    if(packageDetails && packageDetails.products && packageDetails.products.length){
        for(let product of packageDetails.products){
            if(product.isSingleVariation){
                if(!productsWithoutVariations[product.ProductID]) productsWithoutVariations[product.ProductID] = []
                productsWithoutVariations[product.ProductID].push(product)
            }else{
                productsWithVariations.push(product)
            }
        }
    }

    const playButton = () => {
        let pause_time = document.getElementById("pause_button_time");
        if (play) {
            videoRef.current.play();
            setPlay(false);
            setTimeout(() => {
            pause_time.style.color = "transparent";
            },5000)
        }
        else {
            videoRef.current.pause();
            pause_time.style.color="white";
            setPlay(true)
        }
    }

    const fetchPackageDetails = async () => {
        if (!PackageID) return;
        let packageInfo = await callAPI(`/shop/${ShopID}/productPackages/${PackageID}`, "GET");
        if(packageInfo && packageInfo.ProductPackageID) {
            let images = packageInfo.image ? [packageInfo.image] : []
            packageInfo.products.forEach((item) => {
                if(!!item.images.length) {
                    item.images.forEach((im) => {
                        images.push(im)
                    })
                }
            })
            packageInfo.images = images
            let variationsArrObj = {}
            packageInfo.products.map((item) => {
                let sizes = []
                let colors = []
                let titles = []
                if(!item || !item.variations || !item.variations.length) {
                    return
                }
                item.variations.forEach(variation => {
                    variation.ImageID = variation.images && variation.images.length ? variation.images.map(img => img.ImageID)[0] : null
                    variationsArrObj[variation.VariationID] = variation
                });
                
                item.variations.sort((a,b) => a.poolOrder - b.poolOrder).map(vr => {
                    const available = !vr.variationStockable || !!vr.stockOverride ? true : !vr.variationStock ? false : true
                    const colorName = vr.colorName
                    if(!sizes.filter(v => v.variant === vr.variationSize).length) !!vr.variationSize && sizes.push({variant: vr.variationSize, available })
                    if(!colors.filter(v => v.variant === vr.colorHexcode).length) !!vr.colorHexcode && colors.push({variant: vr.colorHexcode, available, colorName: colorName })
                    if(!titles.filter(v => v.variant === vr.variationTitle).length) !!vr.variationTitle && titles.push({variant: vr.variationTitle, available})
                })
                if(item.variations.length === 1) {
                    item["selectedVariation"] = item.variations[0]
                }
                item.isSingleVariation = item.variations.length === 1
                item.variations = {sizes, colors, titles}
            })
            setproductVariations(Object.values(variationsArrObj))
            setPackageDetails(packageInfo || {})
        }
        return;
    };

    const addToCart = () => {
        const selectedProductLength = !!packageDetails && !!packageDetails.products && !!packageDetails.products.length && packageDetails.products.filter((product) => !!product.selectedVariation).length
        const productsLength = !!packageDetails && !!packageDetails.products && packageDetails.products.length
        if(selectedProductLength !== productsLength) return

        setproductAddedToCart(true)
        packageDetails && packageDetails.products && packageDetails.products.length && 
        packageDetails.products.map( async (product) => {
            if(product && product.selectedVariation) {
                if(!product.selectedVariation.stockOverride && product.selectedVariation.variationStockable === 1 && product.selectedVariation.variationStock < 1) return 
                
                await addPackageToBasket(ShopID, packageDetails.ProductPackageID, product.ProductID, product.selectedVariation.VariationID, 1)

            }
            setupBasketData({userData, setBasket})
        })
    }

    const fetchRecentlyViewedProducts = async () => {
        let recentlyViewedProducts = await callAPI(`/product/recentlyViewedProducts`, "GET");
        if (recentlyViewedProducts && !!recentlyViewedProducts.length) {
            recentlyViewedProducts.forEach(product => {
                setupProductImages(product, true)
            });
            setRecentlyViewedProducts(recentlyViewedProducts.filter(p => p.ProductID !== +PackageID))
            return
        }
    }

    const fetchRelatedProducts = async () => {
        let relatedProducts = await callAPI(`/product/popularProducts`, "GET");
        if (relatedProducts && !!relatedProducts.length) {
            relatedProducts.forEach(product => {
                setupProductImages(product, true)
            });
            setRelatedProducts(relatedProducts)
            return
        }
    }

    const productReaction = async (product) => {
        const ReactionID = product.reaction ? product.reaction.ReactionID : null
        await callAPI("/product/reaction", "POST", { body: { ReactionTargetID: product.ProductID, reactionData: JSON.stringify({ ShopID: product.ShopID }), ...(ReactionID && { ReactionID }) } });
        fetchPackageDetails()
        fetchRelatedProducts()
        fetchRecentlyViewedProducts()
    }

    const basketHasRoomforMoreProducts = (basket, accessibleShops) => {
        let currentShop = accessibleShops.filter(e => e.ShopID === ShopID)
        let productShopSelectionLimit = currentShop && currentShop.length ? currentShop[0].shopSelectionLimit : 0 ;
        if (productShopSelectionLimit === 0) {
            return true
        } 
        if (productShopSelectionLimit > 0 && currentShop.length > 0) {
            const basketShop = basket.filter(basketItem => basketItem.ShopID === currentShop[0].ShopID)
            if (basketShop && basketShop.length >= productShopSelectionLimit) {
            return false
            }
        }
        return true
      }

    const resetVariationDetails = () => {
        setinterval(defaultCarouselInterval)
    }

    const updatePackageVariation = (value, ProductID, column, index) => {
        const products = packageDetails.products.filter(p => p.ProductID === ProductID)
        const product = products.length === 1 ? products[0] : products[index]
        product[column] = value

        let {selectedColor, selectedSize, selectedTitle} = product

        product["selectedVariation"] = null


        const variationByTitle = selectedTitle ? productVariations.filter(item => ProductID === item.ProductID && item.variationTitle === selectedTitle) : []
        const variationBySize = selectedSize ? productVariations.filter(item => ProductID === item.ProductID && selectedColor ? item.variationSize === selectedSize && item.colorHexcode === selectedColor : ProductID === item.ProductID && item.variationSize === selectedSize ) : []
        const variationByColor = selectedColor ? productVariations.filter(item => ProductID === item.ProductID && selectedSize ? item.variationSize === selectedSize && item.colorHexcode === selectedColor : ProductID === item.ProductID && item.colorHexcode === selectedColor) : []

        if(variationBySize.length === 1) {
            product["selectedVariation"] = variationBySize[0]
        }

        if(variationByColor.length === 1) {
            product["selectedVariation"] = variationByColor[0]
        }

        if(variationByTitle.length) {
            product["selectedVariation"] = variationByTitle[0]
        }

        if(!product["selectedVariation"]) {
            product["selectedVariationStock"] = null
        } else {
            product["selectedVariationStock"] = !product["selectedVariation"].stockOverride && product["selectedVariation"].variationStockable && product["selectedVariation"].variationStock >= 0 ? `${product["selectedVariation"].variationStock} stk. på lager` : null
        }

        setPackageDetails({...packageDetails, products: packageDetails.products})
    }

    const showBasketCard = () => {
        if(!productAddedToCart) return
        setTimeout(() => {
            document.getElementById('basket-item-card-slide') && document.getElementById('basket-item-card-slide').classList.add('show');
            setTimeout(() => {
                document.getElementById('basket-item-card-slide') && document.getElementById('basket-item-card-slide').classList.remove('show');
                setproductAddedToCart(false)
            }, 10000)
        }, 1)
    }

    const closeBasketCard = () => document.getElementById('basket-item-card-slide') && document.getElementById('basket-item-card-slide').classList.remove('show');

    useEffect(() => {
        productAddedToCart ? showBasketCard() : closeBasketCard()
    },[basket])

    const BasketItemCard = (props) => {
        const navigate = useNavigate()
        const pointSuffix = translate("_POINTS_SHORT_", "pt")
        return  <>
            <div id='basket-item-card-slide' className="basket-card-package" style={{ backgroundColor: styleGuide.color1, overflow: 'hidden', zIndex: 1}}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', padding: '20px 20px 0 20px', justifyContent: 'flex-end' }}>
                            <Close style={{ height: 13, width: 13, cursor: 'pointer' }} onClick={closeBasketCard} />
                    </div>
                        <div style={{ padding: '0 40px' }}>
                            <Headline6 fallbackText="Tilføjet til kurven" />
                        </div>
                        {
                            !!packageDetails && !!packageDetails.products && !!packageDetails.products.length &&
                            packageDetails.products.map((productDetails) => {
                                if(!productDetails.selectedVariation) return 

                                const {selectedVariation} = productDetails
                                const imageUrl = !!productDetails.images && productDetails.images[0].imageOriginSource
                                console.log(productDetails)
                                return <>
                                    <div style={{ display: 'flex', gap: '1rem', padding: '20px  35px 20px 35px' }} >
                                        {!!imageUrl && <div style={{display: 'flex' }}>
                                            <Image style={{ height: 100, width: 80 }} src={`url(${imageUrl})`} />
                                        </div>
                                        }
                                        <div style={{width:"100%"}}>
                                            <div style={{display: 'flex', justifyContent: 'space-between', flex: 1 }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                                                    { productDetails.manufacturer && <ExplainerText fallbackText={productDetails.manufacturer} /> }
                                                    { productDetails.elementTitle && <ExplainerTextTiny style={{ color: styleGuide.color5 }} fallbackText={productDetails.elementTitle} /> }
                                                    <div style={{display: 'flex', marginTop: '0.25rem', alignItems: 'center'}}>
                                                        {
                                                            !!selectedVariation && ((selectedVariation.colorHexcode && selectedVariation.variationSize) || selectedVariation.variationTitle) && <>
                                                                <ExplainerText 
                                                                    style={{ color: styleGuide.color5 }} 
                                                                    fallbackText={selectedVariation.variationSize ? `${selectedVariation.variationSize}` : `${selectedVariation.variationTitle}`}
                                                                />
                                                                { 
                                                                    selectedVariation.colorName 
                                                                        ? <ExplainerText style={{ color: styleGuide.color5 }} fallbackText={`, ${selectedVariation.colorName}`} />
                                                                        : null 
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div style={{width:"40%",display: 'flex',flexDirection: 'column', alignItems: 'flex-end'}}>
                                                    <CaptionText style={{ color: styleGuide.color16 }} fallbackText={`${pointsConvertor(brandData,packageDetails.discountPointComputed || packageDetails.productPointComputed , pointSuffix)}`} />
                                                </div>
                                            </div>
                                            <div style={{float:'right'}}>
                                            {
                                                    (!productDetails.reaction || !productDetails.reaction.ReactionID) && 
                                                    <div onClick={() => {
                                                        productReaction(productDetails)
                                                        closeBasketCard()
                                                        const basketItem = basket.filter(item => item.ProductPackageID === selectedVariation.ProductPackageID)
                                                        if(basketItem.length) {
                                                            basketItem.forEach((basket) => {
                                                                console.log(basket)
                                                                // removeProductFromBasket(basket.BasketID)
                                                                // setupBasketData({userData, setBasket})
                                                            })
                                                        }
                                                        }} style={{cursor: 'pointer',justifyContent: 'right',}}>
                                                        <ExplainerText fallbackText="Flyt til ønskelisten" />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            })
                        }
                        <div className='basket_buttons' style={{ display: 'flex', backgroundColor: styleGuide.color2, padding: '22px  40px', justifyContent: 'space-between' }}>
                            <ButtonMedium4 style={{ padding: '0 10px' }}  showIcon={false} translationKey="_CONTINUE_SHOPPING_" fallbackText="Shop videre"  onClick={closeBasketCard} />
                            <ButtonMedium1 style={{ padding: '0 10px' }}  showIcon={false} translationKey="_GO_TO_BASKET_" fallbackText="Gå til kurven"  onClick={() => {
                                navigate('/basket')}} />
                        </div>
                    </div>
                </div>
        </>
    }

    useEffect(() => {
        const packageContentContainer = document.getElementById('package-container')
        const heightOfContent = !!packageContentContainer ? packageContentContainer.offsetHeight : 0
        setContentContainerHeight(heightOfContent)
    }, [viewMore, packageDetails]);

    useEffect(() => {
            let videoWidth = window.screen.width
            setVideoHeight(videoWidth/2)
        window.addEventListener('resize', _.debounce(() => {
            const packageContentContainer = document.getElementById('package-container')
            const heightOfContent = !!packageContentContainer ? packageContentContainer.offsetHeight : 0
            setContentContainerHeight(heightOfContent)
        }, 200));
        return () => {
            window.removeEventListener('resize', _.debounce(() => {
                const packageContentContainer = document.getElementById('package-container')
                const heightOfContent = !!packageContentContainer ? packageContentContainer.offsetHeight : 0
                setContentContainerHeight(heightOfContent)
            }, 200));
        }
    }, []);

    useEffect(() => {
        window.addEventListener('resize', _.debounce(() => {
            const videoContainer = document.getElementById('video-div')
            const videoheight = !!videoContainer ? videoContainer.offsetHeight : 650
            setVideoHeight(videoheight)
            
        }, 200));
        return () => {
            window.removeEventListener('resize', _.debounce(() => {
                const videoContainer = document.getElementById('video-div')
                const videoheight = !!videoContainer ? videoContainer.offsetHeight : 650
                setVideoHeight(videoheight)
            }, 200));
        }
    },[packageDetails])

    useEffect(() => {
        setShopID(ShopIDs[0])
    },[ShopIDs])

    useEffect(() => {
        resetVariationDetails()
        if(ShopID) {
            fetchPackageDetails()
        }
        fetchRecentlyViewedProducts()
        fetchRelatedProducts()
    },[ShopID])

    if (isNaN(PackageID) || !packageDetails || !packageDetails.ProductPackageID) return null
    return <>
        <div style={{ display: 'flex', flexDirection: 'column', zIndex: 0 }}>
            <div className='package-container' id='package-container' style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                <div className='package-details-container-theme2'>
                    <div className='package-details-section'>
                        <Headline2 style={{ marginTop: 48, color: styleGuide.color7 }}>{packageDetails.title}</Headline2>
                        <Headline6 style={{ marginTop: 10, color: styleGuide.color6 }}>{packageDetails.shortDescription || <></>}</Headline6>
                        {!!Object.values(productsWithoutVariations).length &&
                        Object.values(productsWithoutVariations).map(products => {
                            return <div key={products[0].ProductID} className='package-variations' style={{flexDirection:'column', display:'flex', marginTop: 10, marginBottom: 10}}>
                                <ContentNormal fallbackText={`${products[0].elementTitle} x ${products.length}`} />
                                { products[0].manufacturer && <ExplainerText fallbackText={products[0].manufacturer} /> }
                            </div>                    
                        })}
                        {!!productsWithVariations.length && 
                        productsWithVariations.map(({variations, elementTitle, selectedSize, selectedTitle, selectedColor, selectedVariationStock, manufacturer, ProductID}, inx) => { 
                            return <div key={`${ProductID}_${inx}`} className='package-variations' style={{ flexDirection:'column', display:'flex', marginTop: inx === 0 ? 30 : 0, marginBottom: !variations.sizes.length && !variations.colors.length && !variations.titles.length ? 0 : 20}}>
                            <ContentNormal fallbackText={elementTitle} />
                            { !!manufacturer && <ExplainerText fallbackText={manufacturer} /> }
                            <Grid container style={{ marginLeft: 10, marginTop: 10}}>
                                {!!variations.sizes.length && <Grid
                                    size={{
                                        xs: 12,
                                        sm: 6
                                    }}>
                                    <CaptionText style={{color: styleGuide.color6 }} translationKey="_PRODUCT_VARIATION_SIZE_LABEL_" fallbackText="Størrelser" />
                                    <div style={{display: 'flex', flex: 0.5, gap: '0.5rem', marginTop: 10, flexWrap: 'wrap',}}>
                                        {
                                            variations.sizes.map((item,idx) => {
                                                const allowSelection = selectedSize === item.variant ? true : selectedColor ? productVariations.filter(v => v.variationSize === item.variant && v.colorHexcode === selectedColor).length ? true : false : true
                                                return(
                                                    !!item.variant &&
                                                    <div key={idx} 
                                                        className='size-variation'
                                                        onClick={()=> allowSelection ? updatePackageVariation(selectedSize === item.variant ? null : item.variant, ProductID, 'selectedSize', inx) : null }
                                                        style={{border: (selectedSize === item.variant) ? `0.1px solid ${styleGuide.color16}` : `0.1px solid ${styleGuide.color4}`,}}
                                                    >
                                                        <CaptionText style={{color: !!allowSelection ? styleGuide.color7 : styleGuide.color5, fontWeight: (selectedSize === item.variant) && "600"}}>{item.variant}</CaptionText>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div> 
                                </Grid>}
                                {!!variations.colors.length && <Grid
                                    size={{
                                        xs: 12,
                                        sm: 6
                                    }}>
                                    <CaptionText style={{color: styleGuide.color6 }} translationKey="_PRODUCT_VARIATION_COLOR_LABEL_" fallbackText="Farver" /> 
                                    <div style={{display: 'flex', flex: 0.5, gap: '0.5rem', marginTop: 10, flexWrap: 'wrap'}}>
                                        {
                                            variations.colors.map((item,idx) => {
                                                const allowSelection = selectedColor === item.variant 
                                                ? true 
                                                : selectedSize 
                                                    ? productVariations.map(v => { 
                                                        if (!v.variationStockable || !!v.stockOverride ? true : v.variationStock && v.variationStock > 0 ? true : false) {
                                                            return v
                                                        } 
                                                    }).filter(v => {
                                                        if(!v) return false
                                                        return v.colorHexcode === item.variant && 
                                                        v.variationSize === selectedSize                                                         
                                                    }).length 
                                                        ? true : false 
                                                    : true

                                                return(
                                                    !!item.variant &&
                                                    <div key={idx} 
                                                        data-tooltip={item.colorName}
                                                        className='color-variation'
                                                        onClick={()=> {
                                                            if(!allowSelection) return null
                                                            const color = selectedColor === item.variant ? null : item.variant
                                                            updatePackageVariation(color, ProductID, "selectedColor", inx)

                                                            const variation = color ? productVariations.filter(item => item.colorHexcode === color) : []
                                                            if(variation.length) {
                                                                const imageIndex = packageDetails.images.findIndex( elem => elem.ImageID === variation[0].ImageID)
                                                                setinterval(color && imageIndex > -1 ? null : defaultCarouselInterval)
                                                                setactiveIndex(color && imageIndex > -1 ? imageIndex : null)
                                                            } else {
                                                                setinterval(defaultCarouselInterval)
                                                                setactiveIndex(null)
                                                            }
                                                            
                                                            return
                                                        }}
                                                        style={{border: (selectedColor === item.variant) ? `0.1px solid ${styleGuide.color16}` : '0.1px solid transparent',}}
                                                    >
                                                        <div style={{width: 25, height: 25, backgroundColor: `${item.variant}`, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'}}>
                                                            { allowSelection ? <Tick fill={item?.variant?.toUpperCase() === "#FFFFFF" && styleGuide.color4}/> : <Close fill={item?.variant?.toUpperCase() === "#FFFFFF" ? styleGuide.color4 : styleGuide.color1} style={{width:9, height:9}}/>}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Grid>}
                                {!variations.sizes.length && !variations.colors.length && !!variations.titles.length && <Grid size={12}>
                                    <CaptionText style={{color: styleGuide.color6 }} translationKey="_PRODUCT_VARIATION_TITLE_LABEL_" fallbackText="Variationer" /> 
                                    <div style={{display: 'flex', flex: 0.5, gap: '0.5rem', marginTop: 10, flexWrap: 'wrap'}}>
                                        {
                                            variations.titles.map((item,idx) => 
                                            !!item.variant &&
                                            <div key={idx} 
                                                className='title-variation'
                                                onClick={()=> {
                                                    const title = selectedTitle === item.variant ? null : item.variant
                                                    updatePackageVariation(title, ProductID, "selectedTitle", inx)
                                                    const variation = title ? productVariations.filter(item => item.variationTitle === title) : []
                                                    if(variation.length) {
                                                        const imageIndex = packageDetails.images.findIndex( elem => elem.ImageID === variation[0].ImageID)
                                                        setinterval(title && imageIndex > -1 && imageIndex !== activeIndex ? null : defaultCarouselInterval)
                                                        setactiveIndex(title && imageIndex > -1 ? imageIndex : null)
                                                    } else {
                                                        setinterval(defaultCarouselInterval)
                                                        setactiveIndex(null)
                                                    }
                                                    
                                                    
                                                    return
                                                }}
                                                style={{border: (selectedTitle === item.variant) ? `1px solid ${styleGuide.color4}` : '1px solid transparent',}}
                                            >
                                                <CaptionText style={{color: styleGuide.color7}}>{item.variant}</CaptionText>
                                            </div>)
                                        }
                                    </div>
                                </Grid>}
                            </Grid>
                            <div style={{marginBottom: 10, marginLeft: 10 }}>
                                {
                                    !!selectedVariationStock ? <ContentSmall skipTranslation={true} style={{ color: styleGuide.color6 }}>{selectedVariationStock}</ContentSmall>
                                    :  <>&nbsp;</>
                                }
                            </div>
                        </div>})}
                        {!!packageDetails.enableDiscount && <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20, gap: 10 }}>
                            <ContentNormal skipTranslation={true} style={{ color: styleGuide.color6, textDecoration: 'line-through'}}>
                                {pointsConvertor(brandData,packageDetails.productPointComputed, pointSuffix)}
                            </ContentNormal>
                            <ContentNormal skipTranslation={true} style={{ color: styleGuide.color25,}}>Spar {pointsConvertor(brandData,packageDetails.productPointComputed - packageDetails.discountPointCompute, pointSuffix)}</ContentNormal>
                        </div>}
                        {brandData &&
                            brandData.features &&
                            brandData.features.retailPrice &&
                            packageDetails &&
                            packageDetails.retailPrice && (
                                <ContentNormal skipTranslation={true} style={{ color: styleGuide.color7, marginBottom: 20 }}>Vejl. udsalgspris: Kr. {packageDetails.retailPrice} inkl. moms</ContentNormal>
                            )
                        }
                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 80, gap: 20 }}>
                            <IconButtonLarge
                                inverse={packageDetails.reaction && packageDetails.reaction.ReactionID && packageDetails.reaction.ReactionID > 0}
                                icon={<Heart2 fill='white' stroke={styleGuide.color7} strokeWidth="1.5px" />}
                                // onClick={() => console.log("heart")}
                            />
                            {basketHasRoomforMoreProducts(basket, accessibleShops) ?
                            (brandData && brandData.features && brandData.features.points === false ?
                                    <button
                                        className={classes.InactiveButtonStyle}
                                        disabled={true} >
                                    <Cart1 fill='#000000' />
                                </button> :
                                <ButtonLarge1
                                onClick={addToCart}
                                icon={<Cart1 fill={styleGuide.color1} style={{ marginLeft: '64px' }} />}
                                fallbackText={`${pointsConvertor(brandData,packageDetails.discountPointComputed || packageDetails.productPointComputed , pointSuffix)}`}
                            /> ):
                                <ButtonInactive
                                buttonText='Du kan ikke vælge flere'
                                style={{ height: '45px', width: '80%' }}
                                disabled={true} />}
                        </div>
                        <TabContext className='package-info-tabs-container' value={0}>
                            <Headline6 style={{ color: styleGuide.color7 }}>
                                {translate('_PRODUCT_DESCRIPTION_TAB_CAPTION_', 'Beskrivelse')}
                            </Headline6>
                            <TabPanel value={0} style={{ display: 'flex', flexDirection: 'column' }}>
                                <ContentNormal skipTranslation={true}>{viewMore ? parseLinks(packageDetails.fullDescription) : parseLinks(_.truncate(packageDetails.fullDescription, { length: summaryContentLength }))}</ContentNormal>
                                {
                                    !!packageDetails.fullDescription && packageDetails.fullDescription.length > summaryContentLength && 
                                    <ButtonMedium4
                                        style={{ alignSelf: 'center', marginTop: 60 }}
                                        fallbackText={viewMore ? translate('_READ_LESS_BUTTON_TEXT_', 'Læs mindre') : translate('_READ_MORE_BUTTON_TEXT_', 'Læs mere')}
                                        showIcon={false}
                                        onClick={() => setViewMore(!viewMore)}
                                    />
                                }
                            </TabPanel>
                            <TabPanel value={1}>{ }</TabPanel>
                        </TabContext>
                        <BasketItemCard />
                    </div>
                </div>
                <div className='package-image-container-theme2'>
                    <CustomCarousel
                        id={`package-images ${interval}`}
                        activeIndex={activeIndex}
                        style={{ position: 'sticky', top: 0 }}
                        interval={interval}
                        indicators={true}
                        nextIcon={<ArrowRight fill={styleGuide.color1} />}
                        prevIcon={<ArrowLeft fill={styleGuide.color1} />}
                        children={
                            packageDetails?.images?.map(item => <Image
                                className='package-details-image'
                                // style={{ height: imageHeight }}
                                key={item.ImageID}
                                src={`url(${item.imageOriginSource})`} />
                            )
                        }
                    />
                </div>
            </div>
            <div style={{ display: 'flex', width: '100%', height:packageDetails.video ? videoHeight : null, flexDirection: 'column',marginTop:contentContainerHeight}}>
                {!!packageDetails.video ?
                    <>
                        <div onClick={playButton}>
                            <div style={{height:packageDetails.video ? videoHeight : null,position:'absolute', overflow: 'hidden',left:0,right:0}}>
                                <video 
                                    id='video-div'
                                    playsInline
                                    loop
                                    preload="auto"
                                    ref={videoRef}
                                    style={{  
                                        objectFit: 'fill',
                                        width:'100%',
                                        }} >
                                        <source src={packageDetails.video} />
                                </video>
                            </div>  
                            <div style={{height:packageDetails.video ? videoHeight : null}} id="pause_button_time" className="play_pause_button" onClick={playButton}>
                                <h1 id="play_pause" style={{ position: "absolute"}}>{play ? <Playbutton style={{height:'100%',width:'100%'}} /> : <Pausebutton style={{height:'100%',width:'100%'}} />}</h1>
                            </div>
                        </div>
                    </>
                : null}
            </div>
            <div className='package-recently-viewed' >
                {
                    recentlyViewedProducts.length > 0 &&
                    <ScrollProductsSection
                        caption={translate("_RECENTLY_VIEWED_PRODUCTS_CAPTION_", 'Seneste kigget på')}
                        backgroundColor={styleGuide.color2}
                        sliderVariant='large'
                        productReaction={productReaction}
                        products={recentlyViewedProducts}
                        style={{
                            position: "relative",
                            paddingTop: 100,
                            paddingBottom: 100,
                            backgroundColor: styleGuide.color2,
                        }}
                        button={
                            <ButtonMedium4
                                style={{ marginRight: '2rem', justifySelf: 'end' }}
                                showIcon={false}
                                translationKey="_HOME_POPULAR_PRODUCTS_SEE_MORE_BUTTON_"
                                onClick={() => navigate(`/shop/${ShopID}`)}
                            >
                                Se alle produkter
                            </ButtonMedium4>
                        }
                    />
                }
            </div>
            <div className="package-related">
                {
                    relatedProducts.length > 0 &&
                    <ScrollProductsSection
                        caption={translate("_FEATURED_PRODUCTS_CAPTION_", 'Fremhævede produkter')}
                        productReaction={productReaction}
                        products={relatedProducts}
                        style={{
                            position: "relative",
                            marginBottom: -100,
                            paddingTop: 100,
                            paddingBottom: 100,
                            backgroundColor: styleGuide.color3,
                        }}
                        button={
                            <ButtonMedium4
                                style={{ marginRight: '2rem', justifySelf: 'end' }}
                                showIcon={false}
                                translationKey="_HOME_POPULAR_PRODUCTS_SEE_MORE_BUTTON_"
                                onClick={() => navigate(`/shop/${ShopID}`)}
                            >
                                Se alle produkter
                            </ButtonMedium4>
                        }
                    />
                }
            </div>
        </div>
    </>

}

export default Package2;
