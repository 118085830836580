import React, { useMemo } from "react"
import ContentNormal from "components/elements/ContentNormal"
import Image from "components/elements/Image"
import { AppContext } from "contexts/AppContext"
import { useContext, useEffect, useState, useRef } from "react"
import { useLocation, useParams } from "react-router-dom"
import "./Shop.css"
import { callAPI } from "utils/API";
import {
    MenuItem,
    Select,
    Checkbox,
    ListItemText,
    Chip,
    FormControl,
    InputLabel,
    OutlinedInput,
    Box,
    Typography,
    Grid2 as Grid,
    Button,
    Snackbar,
    SnackbarContent,
    Slide,
  } from "@mui/material";
import ProductNormal from "components/elements/ProductNormal"
import { translate } from "utils/Translate";
import CustomSelect from "components/elements/CustomSelect";
import MultipleSelectCheckmarks from "components/elements/MultipleSelectCheckmarks"
import CustomInput from "components/elements/CustomInput"
import Search, { Lens } from "components/icons/Search"
import Playbutton from "components/icons/Playbutton"
import Pausebutton from "components/icons/Pausebutton"
import CaptionText from "components/elements/CaptionText"
import LabelTag2 from "components/elements/LabelTag2"
import ButtonMedium4 from "components/elements/ButtonMedium4"
import Headline5 from "components/elements/Headline5"
import { useNavigate } from "react-router-dom";
import { createUseStyles } from "react-jss";
import {Autocomplete} from "@mui/material"
import HorizontalScrollBlock from "components/blocks/horizontalScrollBlock/HorizontalScrollBlock";
import ArrowTopWithCircle from "components/icons/ArrowTopWithCircle"
import FilterIcon from "components/icons/FilterIcon"
import { Col } from "react-bootstrap";
import CircularIndeterminate from 'components/blocks/progressBar/progressBar'
import Activity from "utils/Activity"
import _, { debounce } from 'lodash'
import { pointsConvertor } from "utils/general"
import ButtonMedium1 from "components/elements/ButtonMedium1"
import { setupProductImages } from "utils/productImageHelper"


const jss = createUseStyles({
    seeMoreButton: {
    position: "relative",
    },
    shopHeadline: {
        fontWeight: (styleGuide) => styleGuide.headline1FontWeight,
        fontSize: (styleGuide) => styleGuide.headline1FontSize,
        lineHeight: (styleGuide) => styleGuide.headline1LineHeight
    },
    shopContent: {
        fontFamily: (styleGuide) => styleGuide.secondaryFontFamily,
        fontWeight: (styleGuide) => styleGuide.contentNormalFontWeight,
        fontSize: (styleGuide) => styleGuide.contentNormalFontSize,
        lineHeight: (styleGuide) => styleGuide.contentNormalLineHeight,
    },
    "@media screen and (min-width: 700px) and (max-width:1023px)": {
        shopHeadline: {
        fontWeight: (styleGuide) => styleGuide.headline2FontWeight,
        fontSize: (styleGuide) => styleGuide.headline2FontSize,
        lineHeight: (styleGuide) => styleGuide.headline2LineHeight
    }
    },
    "@media screen and (max-width:700px)": {
        shopHeadline: {
        fontWeight: (styleGuide) => styleGuide.headline3FontWeight,
        fontSize: (styleGuide) => styleGuide.headline3FontSize,
        lineHeight: (styleGuide) => styleGuide.headline3LineHeight
        }
    },
    "@media screen and (min-width: 1024px)": {
        seeMoreButton: {
        position: "absolute",
        marginTop: 0,
        top: '100px',
        right: '100px',
        }
    },
});

const Shop = (props) => {
    const { styleGuide, accessibleShops, brandData, userData, basket, defaultTheme, langCode } = useContext(AppContext);
    const isSystemAdmin = userData?.userRole?.toUpperCase() === "SYSTEM" && userData?.userPrivilege?.toUpperCase() === "MANAGE" 
    const classes = jss(styleGuide);
    const [filtersApplied, setfiltersApplied] = useState(false)
    const { ShopID } = useParams()
    const location = useLocation();
    const navigate = useNavigate()
    const queryString = new URLSearchParams(location.search);

    //User points
    const userPoints = userData ? userData?.walletAmountPoints: null;
    const pointRatio = brandData ? brandData?.brandPointRatio : null;
    const userPointsInDKK = userPoints && pointRatio ? userPoints / pointRatio : null;
    
    let filterCategoryName = location.search
      ? queryString.get("category") &&
        queryString.get("category").includes("_")
        ? queryString
            .get("category")
            .split("_")
            .join(" ")
        : queryString.get("category")
      : null; 

    let filterSubCategoryName = location.search
      ? queryString.get("subCategory") &&
        queryString.get("subCategory").includes("_")
        ? queryString
            .get("subCategory")
            .split("_")
            .join(" ")
        : queryString.get("subCategory")
      : null; 

    let shopIDs = accessibleShops.map((shop) => shop.ShopID )

    if(brandData?.features?.disableHome && !ShopID) {
        navigate(`/shop/${shopIDs[0]}`)
    }
    if(ShopID && accessibleShops?.length) {
        if(!shopIDs.includes(Number(ShopID))) {
            navigate('/')
        }
    }  

    const pointSuffix = translate("_POINTS_SHORT_", "pt")
    const [products, setProducts] = useState(null);
    const [productsFiltered, setProductsFiltered] = useState(null);
    const [likedProducts, setLikedProducts] = useState([]);
    const [invalidShop, setInvalidShop] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [selectedShop, setSelectedShop] = useState(null)
    const [play, setPlay] = useState(false);
    const [loading, setloading] = useState(false);
    const [categoryFilterOptions, setcategoryFilterOptions] = useState([])
    const [categoryFilterValue, setcategoryFilterValue] = useState([]);
    const [subCategories, setsubCategories] = useState([]);
    const [subcategoryFilterOptions, setsubcategoryFilterOptions] = useState([])
    const [productGridScrollTargets, setProductGridScrollTargets] = useState([])  
    const [subcategoryFilterValue, setsubcategoryFilterValue] = useState([]);
    const [manufacturerFilterOptions, setManufacturerFilterOptions] = useState([])
    const [manufacturerFilterValue, setmanufacturerFilterValue] = useState([])
    const [allItemsCount, setAllItemsCount] = useState(0)
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(40);
    const [searchQuery, setSearchQuery] = useState("");
    const [videoHeight, setVideoHeight] = useState()
    const [imgHeight, setImgHeight] = useState()
    const [shopBanner,setShopBanner] = useState({})
    const [shopVideo, setShopVideo] = useState(false)
    const [shopBannerVideo, setShopBannerVideo] = useState(null)
    const [shopBannerImage, setShopBannerImage] = useState()
    const [showToast, setShowToast] = useState(false)
    const [moreLoading, setMoreLoading] = useState(false)

    const [shopBannerContent, setShopBannerContent] = useState(null);
    
    const [pageNumber, setPageNumber] = useState(1);

    const sortOptions = [
        { value: '', label: translate('_SHOP_SORT_RESET_LABEL_', 'Reset')},
        { value: 1, label: translate('_SHOP_SORT_POINT_LOW_HIGH_LABEL_', 'Point lav - høj')},
        { value: 2, label: translate('_SHOP_SORT_POINT_HIGH_LOW_LABEL_', 'Point høj - lav')},
        { value: 3, label: translate('_SHOP_SORT_ALPHABETIC_A_Z_LABEL_', 'Alfabetisk A-Z')},
        { value: 4, label: translate('_SHOP_SORT_ALPHABETIC_Z_A_LABEL_', 'Alfabetisk Z-A')},
        { value: 5, label: translate('_SHOP_SORT_WITHIN_MY_POINTS_LABEL_', 'Inden for dine point')},
        { value: 6, label: translate('_SHOP_SORT_NEWEST_LABEL_', 'Produkter nyeste - ældste')},
    ]

    const [sortValue, setSortValue] = useState(location && location.state && location.state.sort ? location.state.sort : '')


    //Refs
    const ShopIDRef = useRef(ShopID);
    const filtersRef = React.createRef()
    const videoRef = useRef();
    const searchStringRef = useRef(searchString);
    const categoryRef = useRef(categoryFilterValue);
    const subCategoryRef = useRef(subcategoryFilterValue);
    const sortingRef = useRef(sortValue);
    const manufacturerRef = useRef(manufacturerFilterValue);
    
    const initialStateForUserEnteredParams = () => {
        const currentShopID = ShopID
        const userEnteredParams = JSON.parse(localStorage.getItem('userEnteredParams') || '{}')
        if(!userEnteredParams.shop) {
            setcategoryFilterValue(!!filterCategoryName ? [filterCategoryName] : [])
            setsubcategoryFilterValue([])
            setSortValue('') 
            setSearchString('')
            setSearchQuery('')
            setmanufacturerFilterValue([]);
            setPageNumber(1);
            setOffset(0);
            return
        }
        const existingFilters = userEnteredParams.shop.filter(item => Number(item.ShopID) === Number(currentShopID))
        if(!existingFilters || !existingFilters.length) {
            setcategoryFilterValue(!!filterCategoryName ? [filterCategoryName] : [])
            setsubcategoryFilterValue([])
            setSortValue('') 
            setSearchString('')
            setSearchQuery('')
            setmanufacturerFilterValue([]);
            setPageNumber(1);
            setOffset(0);
            return
        }
        setfiltersApplied(true)
        setcategoryFilterValue(!!filterCategoryName ? [filterCategoryName] : existingFilters[0].categoryFilterValue || [])
        setsubcategoryFilterValue(existingFilters[0].subcategoryFilterValue || [])
        setSortValue(existingFilters[0].sortValue || '') 
        setSearchString(existingFilters[0].searchString || '')
        setSearchQuery(existingFilters[0].searchQuery || '')
        setmanufacturerFilterValue(existingFilters[0].manufacturerFilterValue || '')
        setPageNumber(existingFilters[0].pageNumber || '')
        setOffset(existingFilters[0].offset || 0)
    }

    const fetchShopBanner = async() => {
        if(!ShopID) return setShopBannerImage(brandData?.features?.wishlistBannerImageURL)

        let shopbannerResponse = await callAPI(`/shopbanners`, 'GET')
        if (!shopbannerResponse) return
        setShopBanner(shopbannerResponse)
        const langShopBanner = shopbannerResponse.translations?.find(i => i.languageKey === langCode)
        if(langShopBanner?.content){
            setShopBannerContent(langShopBanner.content)
        }else if(shopbannerResponse.content){
            setShopBannerContent(shopbannerResponse.content)
        }
        shopbannerResponse.isVideo ? setShopVideo(true) : setShopVideo(false)
        if (shopbannerResponse.isVideo && shopbannerResponse.videoSrc) {
            return setShopBannerVideo(shopbannerResponse.videoSrc)
        }
        if (!shopbannerResponse.isVideo && shopbannerResponse?.Image) {
            return setShopBannerImage(shopbannerResponse.Image.imageOriginSource)
        }
    }

    const fetchCategories = async () => {
        let allCategories = await callAPI(`/product/${ShopID}/categories`, "GET");
        allCategories = allCategories.sort((a,b) => a.categoryName.localeCompare(b.categoryName))
        setcategoryFilterOptions(allCategories)
    }

    const fetchManufacturers = async () => {
        const queryParams = {
            searchTerm: searchStringRef.current.trim() || "", 
            categoryName: categoryRef.current || [],
            subCategoryName: subCategoryRef.current || [],
          }
;
        let allManufacturers = await callAPI(`/product/${ShopIDRef.current}/manufacturer`, "GET", {
            query: queryParams,
          });
        if (allManufacturers && allManufacturers.length) {
            allManufacturers = allManufacturers.filter(x => x.manufacturer != "")
            setManufacturerFilterOptions(allManufacturers)
        }
    }

    const fetchSubCategories = async () => {
        if(!categoryFilterValue?.length) return
        let filteredValues = categoryFilterValue
        const queryStringValue = encodeURIComponent(filteredValues.join('--'))
        let allSubCategories = await callAPI(`/product/subcategories`, "GET", {query: {categories: queryStringValue}})
        setsubCategories(allSubCategories || [])
        if(!allSubCategories?.length) {
            setsubcategoryFilterOptions([])
            setsubcategoryFilterValue([])
            return
        }
        if(allSubCategories?.map(sc => sc.subCategoryName)?.includes(filterSubCategoryName)) {
            setsubcategoryFilterValue(!filterSubCategoryName ? subcategoryFilterValue : [filterSubCategoryName])
        }
        setsubcategoryFilterOptions(allSubCategories)
    }

    const debouncedFetchProducts = useMemo(() => debounce((params) => {
        fetchProducts(params);
        fetchManufacturers();
    }, 700), []);

    const fetchProducts = debounce(
        async ({ newOffset = offset, newLimit = limit, showMore = false, isSearch = false }) => {
          if (!showMore && !isSearch) setloading(true);
          if (isSearch) setMoreLoading(true);
          if (showMore) setMoreLoading(true);
          
          //TODO MFK: Fetch packages
          const queryParams = {
            offset: newOffset,
            limit: newLimit,
            searchTerm: searchStringRef.current.trim() || "", 
            categoryName: categoryRef.current || [],
            subCategoryName: subCategoryRef.current || [],
            sortValue: sortingRef.current || [],
            manufacturerFilter: manufacturerRef.current || [],
            userPointsInDKK: userPointsInDKK || "",
          };

          try {
            const shopProductsResult = await callAPI(`/shop/${ShopIDRef.current}/productsPaged`, "GET", {
              query: queryParams,
            });
      
            const { pagedItems: shopProducts, allItemsCount } = shopProductsResult;
      
            if (shopProducts && shopProducts.length) {
              shopProducts.forEach(product => {
                setupProductImages(product, false)
              });
      
              setProductsFiltered(prevProducts =>
                showMore ? [...prevProducts, ...shopProducts] : shopProducts
              );


              setAllItemsCount(allItemsCount);
            } else if (!showMore) {
              setProductsFiltered([]);
            }
          } catch (error) {
            console.error("Error fetching products:", error);
          } finally {
            setloading(false);
            setMoreLoading(false);
          }
        },
        500 
    );

    const productReaction = async (product) => {
        const isCurrentlyLiked = likedProducts[product.ProductID] ?? (product.reaction?.reactionType ? true : false);
        const ReactionID = product.reaction ? product.reaction.ReactionID : null;

        setLikedProducts((prev) => ({
            ...prev,
            [product.ProductID]: !isCurrentlyLiked,
        }));

        try {
            const reaction = await callAPI("/product/reaction", "POST", {
                body: {
                    ReactionTargetID: product.ProductID,
                    reactionData: JSON.stringify({ ShopID: product.ShopID }),
                    ...(isCurrentlyLiked && ReactionID ? { ReactionID } : {}), 
                }
            });

            if (!reaction || !reaction.affectedRows) {
                setLikedProducts((prev) => ({
                    ...prev,
                    [product.ProductID]: isCurrentlyLiked,  
                }));
            }
        } catch (error) {
            console.error("Reaction API failed", error);

            setLikedProducts((prev) => ({
                ...prev,
                [product.ProductID]: isCurrentlyLiked,
            }));
        }
    };

    const getDirectLinkToShopWithFilters = (category=categoryFilterValue?.[0] || '', subcategory="") => {
        const protocol = window.location.protocol
        const basePath = window.location.host
        const contextPath = location.pathname

        if(!category) return
        let url = `${protocol}//${basePath}${contextPath}?category=${encodeURIComponent(category)}`
        if(!subcategory) return url
        url = `${url}&subCategory=${encodeURIComponent(subcategory)}`
        return url
    }

    const copyTextToClipboard = (value) => {
        if(!value) return
        if (!navigator.clipboard) {
          return console.log("Clipboard API not available")
        }
        if (window.getSelection().toString() && this.props.options.copyHightlighText) {
          return
        }
        try {
          navigator.clipboard.writeText(value).then(() => {
            setShowToast(true)
          })
          .catch((error) => {
            console.error('Error copying text to clipboard:', error);
          });
        } catch (err) {
          console.error('Failed to copy!', err)
        }
    }

    const handleCategoryNameClick = (categoryName, alreadyChosen = false) => {
        if(!categoryName) return
        removeSearch();
        if (!alreadyChosen) {
            setsubcategoryFilterValue([])
        }
        Activity({activityType: 'filter',activityName: 'shop',activityArgument: categoryName, activityArgumentID: ''})
        return setcategoryFilterValue(alreadyChosen ? [] : [categoryName])
    }

    const handleSubCategoryNameClick = (categoryName, alreadyChosen = false) => {
        if(!categoryName) return
        removeSearch();
        Activity({activityType: 'filter',activityName: 'shop',activityArgument: categoryName, activityArgumentID: ''})
        let chosenName = [...subcategoryFilterValue]
        if(alreadyChosen){
            chosenName = chosenName.filter(c => c !== categoryName)            
        } else {
            chosenName = [categoryName]
        }
        setsubcategoryFilterValue(chosenName)
    }

    const handleManufacturerChange = (event) => {
        const value = event.target.value;
        
        const manufacturersArray = typeof value === "string" 
            ? value.split(",").map(item => item.trim()) 
            : value.map(item => String(item)); 
    
        setmanufacturerFilterValue(manufacturersArray);
    };

    const handleSearch = (newSearchString) => {
        setSearchString(newSearchString);
        debouncedFetchProducts({ isSearch: true });
    };

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
        removeCategory();        
        handleSearch(e.target.value); 
    };
    

    const handleShowMore = () => {
        setOffset((prevOffset) => {
            const newOffset = prevOffset + limit;
            debouncedFetchProducts({ newOffset, showMore: true });
            return newOffset;
        });
    };

    const handleClearAll = () => {
        setmanufacturerFilterValue([]);
        setPageNumber(1);
    };

    const showMore = () => {
        if (offset + limit >= allItemsCount) return;
        setMoreLoading(true);
        setPageNumber((prev) => prev + 1);
        setMoreLoading();
        handleShowMore();
    };

    const removeCategory = () => {
        setcategoryFilterValue([])
        setsubcategoryFilterValue([])
        setSortValue('')
        setmanufacturerFilterValue([]);
        setPageNumber(1)
        setOffset(0);
    }

    const removeSearch = () => {
        setSearchString('')
        setSearchQuery('')
        setSortValue('')
        setmanufacturerFilterValue([]);
        setPageNumber(1)
        setOffset(0);
    }
    
    const removeAll = () => {
        setcategoryFilterValue([])
        setsubcategoryFilterValue([])
        setSearchString('')
        setSortValue('')
        setmanufacturerFilterValue([]);
        setPageNumber(1)
        setOffset(0);
    }

    const playButton = () => {
        let pause_time = document.getElementById("pause_button_time");
        if (play) {
            videoRef.current.play();
            setPlay(false);
            setTimeout(() => {
            pause_time.style.color = "transparent";
            },5000)
        }
        else {
            videoRef.current.pause();
            pause_time.style.color="white";
            setPlay(true)
        }
    }

    const retainUserEnteredParams = () => {
        const userEnteredParams = JSON.parse(localStorage.getItem('userEnteredParams') || '{}')
        //debugger
        const currentShopParams = {
            ShopID,
            searchString,
            searchQuery,
            sortValue,
            manufacturerFilterValue: [...manufacturerFilterValue],
            categoryFilterValue: [...categoryFilterValue],
            subcategoryFilterValue: [...subcategoryFilterValue],
            pageNumber,
            offset
        }
        if(!userEnteredParams.shop || !userEnteredParams.shop.length) {
            userEnteredParams.shop = []
            userEnteredParams.shop.push(currentShopParams)
            return localStorage.setItem('userEnteredParams', JSON.stringify(userEnteredParams))
        }

        const existingFilters = userEnteredParams.shop.filter(item => Number(item.ShopID) === Number(ShopID))
        
        if(!existingFilters || !existingFilters.length) {
            userEnteredParams.shop.push(currentShopParams)
            return localStorage.setItem('userEnteredParams', JSON.stringify(userEnteredParams))
        }
        userEnteredParams.shop = userEnteredParams.shop.filter(item => Number(item.ShopID) !== Number(ShopID))
        userEnteredParams.shop.push(currentShopParams)
        return localStorage.setItem('userEnteredParams', JSON.stringify(userEnteredParams))
    }

    const htmlDecode = (input) => {
        let e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes[0].nodeValue;
    }

    const clearStates = () => {
        setloading(true)
        setShopBannerVideo('')
        setShopBannerImage('')
        setShopBannerContent('')
        setProductsFiltered([])
        setSearchString('')
        setSearchQuery('')
    }

    useEffect(() => {
            fetchCategories();
        }, [])

    useEffect(() => {
        let Width = window.innerWidth
        setVideoHeight(Width / 2)
        setImgHeight(Width*5 / 12)
    }, []);

    
    useEffect(() => {
        window.addEventListener('resize', _.debounce(() => {
            let imgWidth =  window.innerWidth
            setImgHeight(imgWidth*5 / 12)
        }, 200));
        return () => {
        window.removeEventListener('resize', _.debounce(() => {
            let imgWidth =  window.innerWidth
            setImgHeight(imgWidth*5 / 12)
        }, 200));
        }
    },[shopBannerImage])
    
    useEffect(() => {
        if (ShopID) {
            let newLimit = 40;
            if (pageNumber) {
                newLimit = pageNumber * limit;
            }
            const params = searchQuery
                ? { isSearch: true, newOffset: 0 }
                : pageNumber
                ? { newOffset: 0, newLimit: newLimit }
                : { newOffset: 0 };
            debouncedFetchProducts(params);
        }
    }, [ShopID, categoryFilterValue, subcategoryFilterValue, searchString, sortValue, manufacturerFilterValue]);

    useEffect(() => {
        clearStates()
        setSelectedShop(Number(ShopID))
        initialStateForUserEnteredParams()
    }, [ShopID]);

    useEffect(() => {
        fetchShopBanner();
    }, [ShopID, accessibleShops, basket]);

    useEffect(() => {
        fetchCategories();
    }, [ShopID, accessibleShops]);

    useEffect(() => {
        if(!categoryFilterValue?.length){
            setsubcategoryFilterOptions([])
        }
        
        fetchSubCategories()
    },[categoryFilterValue])

    useEffect(()=>{
        return () => {
            removeAll()
        }
    },[location])

    const sortAndfilterProducts = () => {
        if(!products) return []
        if((!categoryFilterValue.length || categoryFilterValue.includes("Brands")) && !subcategoryFilterValue.length && !manufacturerFilterValue.length && !sortValue && !searchString) return products

        let categoryFilteredProducts = (categoryFilterValue.length > 0 && categoryFilterValue[0] !== "Brands") 
            ? products.filter(item => item.categories && !!item.categories.length && item.categories.filter(value => categoryFilterValue.includes(value.categoryName)).length)
            : products
        const relevantSubCategoryID = subCategories
            .filter(sc => sc.subCategoryName === subcategoryFilterValue?.[0])
            ?.map(sc => sc.SubCategoryID)?.[0] || 0
        let subCategoryFilteredProducts = (subcategoryFilterValue.length > 0) ? categoryFilteredProducts.filter(item => !!item?.subCategories && !!item?.subCategories?.includes(relevantSubCategoryID)) : categoryFilteredProducts

        let manufacturerFilteredProducts = (manufacturerFilterValue.length > 0) ? subCategoryFilteredProducts.filter(item => !!item.manufacturer && manufacturerFilterValue.includes(item.manufacturer.trim())) : subCategoryFilteredProducts
        let finalFilteredProducts = manufacturerFilteredProducts
        if(!sortValue) return finalFilteredProducts
        setProductsFiltered(null)
    }

    useEffect(() => {
        if (productsFiltered && productsFiltered.length) {
            setfiltersApplied(categoryFilterValue.length > 0 ? true : false)
            const productsForGrid = productsFiltered;
            const scrollToProductIds = []
            productsForGrid.forEach((e,idx, arr) => {
                if(idx % 8) return arr[idx].htmlId = ""
                const id = `shop-product-${idx}`
                scrollToProductIds.push(id)
                return arr[idx].htmlId = id
            });
            setProductGridScrollTargets([...scrollToProductIds])
            retainUserEnteredParams()
            const scrollTo = Number(sessionStorage.getItem('scrollPosition')) || 0
            if(scrollTo && scrollTo <= document.body.clientHeight){
                window.scrollTo( 0, scrollTo);
                sessionStorage.removeItem('scrollPosition')
            }
        }
    }, [productsFiltered, document.body.clientHeight, loading]);

    useEffect(() => {
        ShopIDRef.current = ShopID; 
    }, [ShopID]);

    useEffect(() => {
        searchStringRef.current = searchString;
      }, [searchString]);

    useEffect(() => {
        categoryRef.current = categoryFilterValue;
    }, [categoryFilterValue]);

    useEffect(() => {
        subCategoryRef.current = subcategoryFilterValue; 
    }, [subcategoryFilterValue]);

    

    useEffect(() => {
        manufacturerRef.current = Array.isArray(manufacturerFilterValue) ? manufacturerFilterValue : [];
    }, [manufacturerFilterValue]);

    useEffect(() => {
        sortingRef.current = sortValue; 
    }, [sortValue]);

    useEffect(() => {
        if(brandData?.features?.enableCopyingShopFilterDirectLink){
            if(isSystemAdmin){
                const urlToBeCopied = getDirectLinkToShopWithFilters(categoryFilterValue?.[0] || "", subcategoryFilterValue?.[0] || manufacturerFilterValue?.[0] || "")
                copyTextToClipboard(urlToBeCopied)
            }
        }
    },[categoryFilterValue, subcategoryFilterValue, manufacturerFilterValue])


    const bannerStyle1 = { display: 'flex', width: '100%', height:!!shopVideo ? videoHeight : null,flexDirection: 'column', maxHeight: styleGuide.bannerMaxHeight}
    const bannerStyle = defaultTheme == 'ThemeONE' ? { ...bannerStyle1 } : defaultTheme == 'ThemeTWO' ? { ...bannerStyle1, marginTop: '-180px' } : { ...bannerStyle1,marginTop: 'unset' };
    const searchBackground = (color, transparent = true) => `${color?.toUpperCase === 'BLACK' ? `rgba(0,0,0,${transparent ? '0.7' : 1})` : `rgba(255,255,255,${transparent ? '0.7' : 1})`}`
    const searchTextColor = (color) => `${color?.toUpperCase === 'BLACK' ? "#FFF":"#000"}`
    let {shopPageBannerColor}= brandData && brandData.features

    return !!invalidShop ? null : (
        <>
            <Snackbar
                open={showToast}
                onClose={() => setShowToast(false)}
                TransitionComponent={Slide}
                TransitionProps={{direction:"up"}}
                message="URL copied"
                autoHideDuration={1200}
            >
                <SnackbarContent 
                    style={{ backgroundColor: styleGuide.color16 ,}}
                    message="URL copied"
                />
            </Snackbar>
            <div id="shop-banner" style={{...bannerStyle, 
                backgroundColor: shopPageBannerColor|| styleGuide.color14,
                position: 'absolute',
                left: 0,
                right: 0,
                height: '250px',
                justifyContent: 'center'
            }}>
                <div>
                {!brandData?.features?.hideShopSearch
                    ? 
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{display: 'flex', alignItems: 'center', flex: 0.7, maxWidth: 500, gap: '0.5rem', backgroundColor: searchBackground(shopBanner.textColor), borderRadius: '10px', overflow: 'hidden', padding: '0 1rem' }}>
                                    <Lens stroke={searchTextColor(shopBanner.textColor)}/>
                                    <CustomInput
                                        autoFocus={true}
                                        wrapperStyle={{marginBottom: 10, width: '100%', color: searchTextColor(shopBanner.textColor)}}
                                        label={translate('_SHOP_SEARCH_LABEL_', 'Søg')}
                                        labelstyle={{
                                            color: searchTextColor(shopBanner.textColor), 
                                            textShadow: `#FFF 1px 0 5px`,
                                        }}
                                        value={searchQuery}
                                        onChange={handleSearchInputChange}
                                    />
                                </div>
                            </div>
                    
                    : 
                    <div id={`${defaultTheme}shop_banner_text`} style={{ position: "absolute", zIndex: 1, left: 0 }} >
                        <div className="shopBannerTitle">
                            <span
                                style={{
                                    fontFamily: styleGuide.fontFamily,
                                    fontStyle: 'normal',
                                    color: shopBanner.textColor || 'black'
                                }}
                                className={classes.shopHeadline}
                                dangerouslySetInnerHTML={{
                                    __html: htmlDecode(!ShopID ? translate('_WISHLIST_BANNER_HEADLINE_', 'Ønskeliste') : translate('_SHOP_BANNER_HEADLINE_',
                                        shopBanner.translations?.length
                                            ? shopBanner.translations.find(l => l.languageKey === langCode)?.title
                                            : shopBanner.title || 'Produkter'
                                    ))
                                }}
                            >
                            </span>
                        </div>
                        {!isNaN(ShopID) && !!shopBannerContent && <div className="shopBannerContent">
                            <span
                                style={{
                                    fontStyle: 'normal',
                                    color: shopBanner.textColor || 'black',
                                }}
                                className={classes.shopContent}
                                dangerouslySetInnerHTML={{
                                    __html: htmlDecode(translate('_SHOP_BANNER_CONTENT_', shopBannerContent))
                                }}
                            >
                            </span>
                        </div>}
                    </div>
                    }
                    </div>
            </div> 
            <div style={{marginTop: 250}}>
                {
                    !brandData?.features?.hideShopFilters && <>
                    <div style={{ display: "flex", flexDirection: "column" }}>

                        {/* Categories */}
                        <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem", justifyContent: "center", padding: "15px 0", borderBottom: `1px solid ${styleGuide.color4}` }}>
                            {
                                categoryFilterOptions?.map(c => {
                                const isCategoryChosen = categoryFilterValue.includes(c.categoryName) 
                                return <ContentNormal
                                            key={`${c?.CategoryID || 0}-${c.categoryName}`}
                                            className="category-label"
                                            style={{ 
                                                cursor: "pointer", 
                                                // letterSpacing: 0.5,
                                                color: isCategoryChosen ? styleGuide.color16 : styleGuide.color7,
                                                fontWeight: isCategoryChosen ? "500" : "400"
                                            }}
                                            onClick={() => handleCategoryNameClick(c.categoryName, isCategoryChosen)}
                                        >
                                            {
                                                !!c && !!c.translations && !!c.translations.length > 0
                                                ? c.translations.find(
                                                        (translation) => translation.languageKey === langCode
                                                ) 
                                                ? c.translations.find(
                                                    (translation) => translation.languageKey === langCode
                                                ).categoryName
                                                    : c.categoryName
                                                :
                                                 c.categoryName
                                            }
                                        </ContentNormal>
                                })
                                
                            }
                        </div>

                        {/* Sub categories */}
                        {
                            !!categoryFilterValue && !!subcategoryFilterOptions?.length &&                   
                            <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem", justifyContent: "center", padding: "15px 0", borderBottom: `1px solid ${styleGuide.color4}`}}>
                                {
                                    subcategoryFilterOptions?.sort((a, b) => a.subCategoryName.localeCompare(b.subCategoryName)).map((sc,scidx) => {
                                        
                                        const isSubCategoryChosen = subcategoryFilterValue.includes(sc.subCategoryName)
                                        return <ContentNormal
                                                    key={`${scidx}_${sc}`}
                                                    className="category-label"
                                                    style={{ 
                                                        cursor: "pointer", 
                                                        // letterSpacing: 0.5,
                                                        color: isSubCategoryChosen ? styleGuide.color16 : styleGuide.color7,
                                                        fontWeight: isSubCategoryChosen ? "500" : "400",
                                                        ":hover":{
                                                            color: "pink"
                                                        }
                                                    }}
                                                    onClick={() => handleSubCategoryNameClick(sc.subCategoryName, isSubCategoryChosen)}
                                                >
                                                    {
                                                        !!sc && !!sc.translations && !!sc.translations.length > 0
                                                        ? sc.translations.find(
                                                                (translation) => translation.languageKey === langCode
                                                        ) 
                                                        ? sc.translations.find(
                                                            (translation) => translation.languageKey === langCode
                                                        ).subCategoryName
                                                            : sc.subCategoryName
                                                        :
                                                        sc.subCategoryName
                                                    }
                                                </ContentNormal>
                                        })
                                }
                            </div>
                        }
                        {
                            <Box display="flex" flexDirection="column" width="100%">
                            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" gap={2}>
                              {!brandData?.features?.hideBrandsInShopFilter ? 
                              <Box mt={2} flexGrow={1} maxWidth={150}>
                              <FormControl fullWidth size="small">
                                <InputLabel>Brands</InputLabel>
                                <Select
                                  multiple
                                  value={manufacturerFilterValue}
                                  onChange={handleManufacturerChange}
                                  input={<OutlinedInput label="Brands" />}
                                  MenuProps={{
                                    PaperProps: {
                                      style: { maxHeight: 200, overflowY: "auto" },
                                    },
                                  }}
                                  renderValue={() => ""}
                                >
                                  {manufacturerFilterOptions.map((option) => (
                                    <MenuItem 
                                      key={option.manufacturer} 
                                      value={option.manufacturer} 
                                      style={{ display: 'flex', justifyContent: 'space-between' }}
                                    >
                                      <ListItemText primary={option.manufacturer} secondary={`(${option.productCount})`} />
                                      <Checkbox checked={manufacturerFilterValue.includes(option.manufacturer)} size="small"/>
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>: 
                                <Box>
                                </Box>
                               }
                              
                          
                              <Box mt={2} flexShrink={0} minWidth={150}>
                                <CustomSelect 
                                  custlabelStyle={{ marginLeft: 0 }}
                                  wrapperStyle={{ marginBottom: 10, padding: "10px 0", minWidth: "150px" }}
                                  label={translate('_SHOP_SORT_FILTER_LABEL_', 'Sortér')} 
                                  value={sortValue} 
                                  onChange={(e) => setSortValue(e.target.value)}
                                  options={sortOptions}
                                />
                              </Box>
                            </Box>
                          
                            {manufacturerFilterValue.length > 0 && (
                              <Box mt={1} display="flex" gap={1} flexWrap="wrap" alignItems="center">
                                {manufacturerFilterValue.map((manufacturer) => (
                                  <Chip
                                    key={manufacturer}
                                    label={manufacturer}
                                    onDelete={() =>
                                      setmanufacturerFilterValue((prev) => prev.filter((item) => item !== manufacturer))
                                    }
                                    sx={{ fontSize: 14, borderRadius: "14px", height: "35px" }}
                                  />
                                ))}
                                <ButtonMedium1 showIcon={false} onClick={handleClearAll}>Clear</ButtonMedium1>
                              </Box>
                            )}
                          </Box>
                        }
                    </div>
                </>
                }
            </div>
            <div style={{marginTop:0}} >
                <div style={{display: 'flex', flexDirection: 'row' }} >
                {
                    (productsFiltered ===null || loading)
                ?   <div className="shop-content" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '3rem'}}>
                            <CircularIndeterminate size={100}/>
                        </div>
                    </div>
                :   (productsFiltered.length === 0)
                ?   <div className="shop-content" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '3rem'}}>
                            <Headline5 translationKey="_SHOP_FILTER_NO_RESULTS_CAPTION_" fallbackText="Ingen resultater" />
                            <ContentNormal translationKey="_SHOP_FILTER_NO_RESULTS_TEXT_" fallbackText="Vi fandt ingen resultater" />
                        </div>
                    </div>
                :
                    <div ref={filtersRef} className="shop-content" style={{ display: 'flex', flexDirection: 'column' }}>
                            {
                                (!productsFiltered || !productsFiltered.length) && !!filtersApplied && !searchString.trim() && 
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '3rem'}}>
                                    <Headline5 translationKey="_SHOP_FILTER_NO_RESULTS_CAPTION_" fallbackText="Ingen resultater" />
                                    <ContentNormal translationKey="_SHOP_FILTER_NO_RESULTS_TEXT_" fallbackText="Vi fandt ingen resultater" />
                                </div>
                            }
                            {
                                (!productsFiltered || !productsFiltered.length) && (!!searchString && !!searchString.trim() && !!filtersApplied) && 
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '3rem'}}>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <Headline5 translationKey="_SHOP_SEARCH_NO_RESULTS_CAPTION_" fallbackText="Ingen resultater for" />
                                        <span>&nbsp;</span>
                                        <Headline5 fallbackText={` “${searchString}”`} />
                                    </div>
                                    <ContentNormal translationKey="_SHOP_SEARCH_NO_RESULTS_TEXT_" fallbackText="Vi fandt ingen resultater, der matcher din søgning. Søg igen eller brug filteringsmulighederne." />
                                </div>
                            }
                            {
                                (!!productsFiltered && !!productsFiltered.length) && !!filtersApplied && !!searchString && !!searchString.trim() && 
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '3rem'}}>
                                    <Headline5 fallbackText={`${productsFiltered.length} `} />
                                    <span>&nbsp;</span>
                                    <Headline5 translationKey="_SHOP_SEARCH_RESULTS_CAPTION_" fallbackText="resultat for" />
                                    <span>&nbsp;</span>
                                    <Headline5 fallbackText={` “${searchString}”`} />
                                </div>
                            }
                            {
                                (!!productsFiltered && !!productsFiltered.length) && 
                                <Grid className="product-grid" container rowSpacing={1} columnSpacing={1}>
                                    {
                                        productsFiltered.map(item =>
                                            <Grid
                                                id={item.htmlId || ""}
                                                key={item.ProductID || item.ProductPackageID}
                                                size={{
                                                    xs: 6,
                                                    lg: 4,
                                                    xl: 3,
                                                }}
                                                style={{minWidth: 150}}
                                                sx={{justifyContent: 'center'}}>
                                                <ProductNormal
                                                    storeScrollPosition={true}
                                                    productID={item.ProductID}
                                                    packageID={item.ProductPackageID}
                                                    showTag={item.enableDiscount ? true : false}
                                                    tagOptions={{
                                                        fallbackText: `${translate('_PRODUCT_DISCOUNT_TAG_TEXT_', 'Spar')} ${pointsConvertor(brandData,item.productPointComputed - item.discountPointComputed, pointSuffix)}`,
                                                        showClose: false,
                                                    }}
                                                    tagStyles={{borderRadius: 8}}
                                                    sticker={!!brandData && !!brandData.features && !!brandData.features.shopProductPriceButton && !!brandData.features.priceTag ? "PRICETAG" : item.flag && item.flag !== 'STICKY' ? item.flag : null}
                                                    points={`${item.discountPointComputed ? item.discountPointComputed : item.productPointComputed}`}  
                                                    liked={likedProducts[item.ProductID] ?? (item.reaction?.reactionType ? true : false)}
                                                    className="productlarge item"
                                                    style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}
                                                    text2={item.translations?.find((translation) => translation.languageKey === langCode)?.elementTitle ?? item.elementTitle}
                                                    text1={item.manufacturer}
                                                    wishlistCallback={() => productReaction(item)}
                                                    imageSrc={`url(${item.image?.src})`}
                                                    hoverImageSrc={item.hoverImage ? `url(${item.hoverImage.src})` : ''}
                                                    imageBackgroundColor={item.image?.backgroundColor}
                                                    hoverImageBackgroundColor={item.hoverImage?.backgroundColor}
                                                    activityName='shop'
                                                />
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            }
                            
                    </div>
                }
                </div>
                {!!productsFiltered && !!productsFiltered.length && 
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px"}}>
                        {!!moreLoading &&
                        <div className="shop-content" style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '3rem'}}>
                                <CircularIndeterminate size={100}/>
                            </div>
                        </div>
                        }
                        {(!moreLoading && !loading)  && 
                            <div>
                                <div style={{ marginBottom: 10 }}> 
                                    <span>Viser <span style={{fontWeight: 600}}>{productsFiltered.length}</span> ud af <span style={{fontWeight: 600}}>{allItemsCount}</span></span>
                                </div>
                                
                                <div style={{ width: "100%", height: "1px", backgroundColor: "#BFBFBF", margin: "10px 0" }}></div>
                                {productsFiltered.length != allItemsCount &&
                                <div style={{ display: "flex", justifyContent: "center"}}>
                                <ButtonMedium4
                                    onClick={() => showMore()}
                                    style={{ marginTop: 10 }}
                                    showIcon={false}
                                    fallbackText="Vis flere"
                                />
                                </div>
                                }
                            </div>
                        }
                        
                        
                    </div>
                }
            </div>
        </>
    );
}

export default Shop

const DiscountedProductsSection = (props) => {
    const { styleGuide } = useContext(AppContext)
    const [discountedProducts, setDiscountedProducts] = useState([])
    const classes = jss(styleGuide)
    const navigate = useNavigate()
    const { ShopID } = useParams()
    const pointSuffix = translate("_POINTS_SHORT_", "pt")
    const suggestedProductsStyle = {
      position: "relative",
      marginTop: 100, 
            marginBottom: -100, 
            paddingTop: 100, 
            paddingBottom: 100, 
            backgroundColor: styleGuide.color3,
    };

    const fetchDiscountedProducts = async() => {
        if(ShopID){
            let discountedProducts = await callAPI(`/shop/${ShopID}/productsOnDiscount`, "GET");
            if (discountedProducts && !!discountedProducts.length) {
                discountedProducts.forEach(product => {
                    setupProductImages(product, false)
                });
                setDiscountedProducts(discountedProducts)
                return
            }
        }
    }

    useEffect(() => {
        fetchDiscountedProducts()
    },[])

    const  productReaction = async (product) =>{
      const ReactionID = product.reaction ?  product.reaction.ReactionID: null
      let reaction = await callAPI("/product/reaction", "POST",{body: {ReactionTargetID:product.ProductID,reactionData:JSON.stringify({ShopID:product.ShopID}),...(ReactionID && {ReactionID})}});
      fetchDiscountedProducts()
    }
    return !discountedProducts.length ? null : (
      <div className="horizontal-scroll-wrapper products-on-discount" style={suggestedProductsStyle}>
          <HorizontalScrollBlock caption={translate("_SHOP_DISCOUNTED_PRODUCTS_CAPTION_",'Nedsatte produkter')}>
            {discountedProducts.map((product) => (
              <ProductNormal
                productID={product.ProductID}
                packageID={product.ProductPackageID}
                key={product.ProductID || product.ProductPackageID}
                showTag={product.enableDiscount ? true : false}
                tagOptions={{
                    fallbackText: `${translate('_PRODUCT_DISCOUNT_TAG_TEXT_', 'Spar')} ${product.productPointComputed - product.discountPointComputed} ${pointSuffix}`,
                    showClose: false,
                }}
                tagStyles={{borderRadius: 8}}
                points={`${product.discountPointComputed ? product.discountPointComputed : product.productPointComputed}`} 
                liked={product.reaction && product.reaction.ReactionID ? true : false }
                className="productlarge item"
                style={{ boxShadow: `0px 0px 3px 1px rgba(34, 34, 34, 0.15)` }}
                text2={product.elementTitle}
                text1={product.manufacturer}
                wishlistCallback={() => productReaction(product)}
                imageSrc={`url(${product.image?.src})`}
                hoverImageSrc={product.hoverImage ? `url(${product.hoverImage.src})` : ''}
                imageBackgroundColor={product.image?.backgroundColor}
                hoverImageBackgroundColor={product.hoverImage?.backgroundColor}
                activityName='shop'
              />
            ))}
          </HorizontalScrollBlock>
        <div className={classes.seeMoreButton}>
          <ButtonMedium4
                    style={{ margin: "auto" }}
                    showIcon={false}
                    translationKey="_HOME_POPULAR_PRODUCTS_SEE_MORE_BUTTON_"
                    // onClick={ShopID ? () => { navigate(`/shop/${ShopID}`) } : null}
                    onClick={() => { window.scrollTo(0, 0) }}
          >
            Se alle produkter
          </ButtonMedium4>
        </div>
      </div>
    );
};
    