export const setupProductImages = (product, large) => {
    const imageSelector = large ? 'imageOriginSource' : 'imageOptimizedSourceMedium'
    if (product.images?.length) {
        product.images.sort((a, b) => a.poolOrder - b.poolOrder);

        const featureImages = product.images.filter(img => img.featureImage)
        if (featureImages.length) {
            product.image = {
                src: featureImages[0][imageSelector],
                backgroundColor: featureImages[0].backgroundColor,
            }
            if (featureImages.length >= 2) {
                product.hoverImage = {
                    src: featureImages[1][imageSelector],
                    backgroundColor: featureImages[1].backgroundColor,
                }
            }
            else {
                const nonFeatureImages = product.images.filter(img => !img.featureImage)
                if (nonFeatureImages.length) {
                    product.hoverImage = {
                        src: nonFeatureImages[0][imageSelector],
                        backgroundColor: nonFeatureImages[0].backgroundColor,
                    }
                }
            }
        } else {
            product.image = {
                src: product.images[0][imageSelector],
                backgroundColor: product.images[0].backgroundColor,
            }
            if (product.images.length >= 2) {
                product.hoverImage = {
                    src: product.images[1][imageSelector],
                    backgroundColor: product.images[1].backgroundColor,
                }
            }
        }
    }
}