import { useCallback, useEffect, useState } from 'react'
import './Drawer.css'
import { useLocation } from 'react-router-dom';

const Drawer = (props) => {
    const { visible, children, style, closeHandler } = props
    const location = useLocation();
    const [show, setshow] = useState(visible)

    const onClickOutside = useCallback((event) => { closeHandler() }, [])

    useEffect(() => {
        setshow(visible)
        const root = document.querySelector('.page-wrapper');
        if(!!visible) {
            root.classList.add('overlay')
        }else{
            root.classList.remove('overlay')
        }
    }, [visible])

    useEffect(() => {
        closeHandler()
    }, [location])

    useEffect(() => {
        const page = document.querySelector('html')
        if(show) {
            page.addEventListener('click', onClickOutside)
        }else{
            page.removeEventListener('click', onClickOutside)
        }

        return () => {
            const page = document.querySelector('html')
            page.removeEventListener('click', onClickOutside)
        }
    }, [show]);

    return <>
    <div className={`drawer ${show ? 'show' : ''}`} style={style} onClick={(e) => e.stopPropagation()}>
        {children}
    </div>
    {
        // !!showOverlay && !!show && <div id='overlay'/>
    }
    </>
}

export default Drawer