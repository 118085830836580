import PostCard from "components/elements/PostCard";
import ProductLarge from "components/elements/ProductLarge";
import ProductNormal from "components/elements/ProductNormal";
import { AppContext } from "contexts/AppContext";
import { callAPI } from 'utils/API';
import { useContext, useEffect } from "react";
import { Grid2 as Grid } from "@mui/material";
import HorizontalScrollBlock from "components/blocks/horizontalScrollBlock/HorizontalScrollBlock";
import CookiePopUp from "components/blocks/cookiePopUp/CookiePopUp"
import CustomCarousel from "components/blocks/customCarousel/CustomCarousel";
import { createUseStyles } from "react-jss";

const jss = createUseStyles({
    CarouselWrapper: {
        height: 450, // 600,
        maxWidth: 300 // 375,
    },
    '@media screen and (min-width: 768px)': {
        CarouselWrapper: {
            height: 450, //600,
            maxWidth: 518,  // 648,
        },
    },
    '@media screen and (min-width: 1024px)': {
        CarouselWrapper: {
            height: 450, //600,
            maxWidth: 450, //600
        },
    }
})


const ExampleBlocks = (props) => {
    const { styleGuide, setBrandData } = useContext(AppContext)
    const classes = jss(styleGuide)
    useEffect(async () => {
        const brandInfo = await callAPI('/brand/setup', 'GET')
        if (brandInfo) {
            setBrandData(brandInfo)
        }
    }, [])

    const cookieName = 'cookieConsent'
    return (
        <div style={{ padding: 30, backgroundColor: '#F9F9F9' }}>
            <br />
            <br />=========================
                        <br />
            <br />
            <p> CustomCarousel : </p>
            <CustomCarousel
                className={classes.CarouselWrapper}
                style={{
                    
                }}
            >
                <ProductLarge
                    hideLike={true}
                    style={{ maxWidth: '100%' }}
                    points={'123 pt'}
                    text1={'Brand-1'}
                    text2={'Title-1'}
                    imageSrc={`url('https://i.ibb.co/hZYW60N/Product-images-5.jpg')`}
                />
                
                <ProductLarge
                    hideLike={true}
                    style={{ maxWidth: '100%' }}
                    points={'456 pt'}
                    text1={'Brand-2'}
                    text2={'Title-2'}
                    imageSrc={`url('https://i.ibb.co/ch9T7hs/Product-images-3.jpg')`}
                />
                
                <ProductLarge
                    hideLike={true}
                    style={{ maxWidth: '100%' }}
                    points={'789 pt'}
                    text1={'Brand-3'}
                    text2={'Title-3'}
                    imageSrc={`url('https://i.ibb.co/tBWzRmk/News-images-1.jpg')`}
                />
                
                <ProductLarge
                    hideLike={true}
                    style={{ maxWidth: '100%' }}
                    points={'159 pt'}
                    text1={'Brand-4'}
                    text2={'Title-4'}
                    imageSrc={`url('https://i.ibb.co/17VgHtx/News-images-2.jpg')`}
                />
                
                <ProductLarge
                    hideLike={true}
                    style={{ maxWidth: '100%' }}
                    points={'260 pt'}
                    text1={'Brand-5'}
                    text2={'Title-5'}
                    imageSrc={`url('https://static.remove.bg/sample-gallery/graphics/bird-thumbnail.jpg')`}
                />
            </CustomCarousel>
            <br />
            <br />=========================
                        <br />
            <br />
            <p>Horizontal scroll block: </p>
            <div className="scrollDiv">
                <HorizontalScrollBlock caption="Caption text">
                    {
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(item => (
                            <ProductNormal
                            key={item}
                            className="productlarge item"
                            style={{ boxShadow: `0px 0px 3px 1px rgba(34, 34, 34, 0.15)` }}
                            points={'123567 pt'}
                            text1={'Brand - ' + item}
                            text2={'Title'}
                            wishlistCallback={() => alert('you LIKED this Product!')}
                            imageSrc={`url('https://i.ibb.co/hZYW60N/Product-images-5.jpg')`}
                            />
                        ))
                    }
                </HorizontalScrollBlock>
            </div>
            <br />
            <br />=========================
                        <br />
            <br />
            <p>Product Large : </p>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <ProductLarge
                    liked={true}
                    style={{ boxShadow: `0px 1px 3px 0px black` }}
                    showTag={true}
                    tagOptions={{
                        translationKey: null,
                        fallbackText: 'save 1234',
                        showClose: true
                    }}
                    points={'123567 pt'}
                    text1={null}
                    text2={'Title'}
                    wishlistCallback={() => alert('you LIKED this Product!')}
                    imageSrc={`url('https://i.ibb.co/ch9T7hs/Product-images-3.jpg')`}
                />

                <ProductLarge
                    style={{ boxShadow: `0px 1px 3px 0px black` }}
                    points={'123567 pt'}
                    text1={'Brand'}
                    text2={'Title'}
                    wishlistCallback={() => alert('you LIKED this Product!')}
                    imageSrc={`url('https://i.ibb.co/hZYW60N/Product-images-5.jpg')`}
                />

                <ProductLarge
                    style={{ boxShadow: `0px 1px 3px 0px black` }}
                    showTag={true}
                    tagOptions={{
                        translationKey: null,
                        fallbackText: 'save 3322 pt',
                        showClose: false
                    }}
                    points={'123567 pt'}
                    text1={'Brand'}
                    text2={'Title'}
                    wishlistCallback={() => alert('you LIKED this Product!')}
                    imageSrc={`url('https://i.ibb.co/hZYW60N/Product-images-5.jpg')`}
                />
            </div>
            <br />
            <br />=========================
                        <br />
            <br />
            <p>Product Normal : </p>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <ProductNormal
                    liked={true}
                    style={{ boxShadow: `0px 1px 3px 0px black` }}
                    showTag={true}
                    tagOptions={{
                        translationKey: null,
                        fallbackText: '13.023 pt',
                        showClose: true
                    }}
                    points={'123567 pt'}
                    text1={'Brand'}
                    text2={'Title'}
                    wishlistCallback={() => alert('you LIKED this Product!')}
                    imageSrc={`url('https://i.ibb.co/ch9T7hs/Product-images-3.jpg')`}
                />

                <ProductNormal
                    style={{ boxShadow: `0px 1px 3px 0px black` }}
                    points={'123567 pt'}
                    text1={'Brand'}
                    text2={'Title'}
                    wishlistCallback={() => alert('you LIKED this Product!')}
                    imageSrc={`url('https://i.ibb.co/hZYW60N/Product-images-5.jpg')`}
                />

                <ProductNormal
                    style={{ boxShadow: `0px 1px 3px 0px black` }}
                    showTag={true}
                    tagOptions={{
                        translationKey: null,
                        fallbackText: '13.023 pt',
                        showClose: false
                    }}
                    points={'123567 pt'}
                    text1={'Brand'}
                    text2={'Title'}
                    wishlistCallback={() => alert('you LIKED this Product!')}
                    imageSrc={`url('https://i.ibb.co/hZYW60N/Product-images-5.jpg')`}
                />
            </div>
            <br />
            <br />=========================
                        <br />
            <br />
            <p>News : </p>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {/* <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}> */}
                <Grid
                    size={{
                        xs: 12,
                        md: 6
                    }}>
                    <PostCard
                        style={{ boxShadow: `0px 1px 3px 0px black` }}
                        title={'News title one'}
                        description={'some text in description'}
                        imageSrc={`url('https://i.ibb.co/tBWzRmk/News-images-1.jpg')`}
                    />
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        md: 6
                    }}>
                    <PostCard
                        style={{ boxShadow: `0px 1px 3px 0px black` }}
                        title={'News Two'}
                        description={'Description text'}
                        imageSrc={`url('https://i.ibb.co/17VgHtx/News-images-2.jpg')`}
                    />
                </Grid>
                {/* </div> */}
            </Grid>
            <br />
            <br />=========================
                        <br />
            <br />
        </div>
    );
}

export default ExampleBlocks;