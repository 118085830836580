import React, { useContext, useEffect, useState } from 'react'
import { callAPI } from 'utils/API';
import { setupUserData } from 'utils/setup';
import ButtonMedium1 from 'components/elements/ButtonMedium1';
import CustomInput from 'components/elements/CustomInput';
import { translate } from 'utils/Translate';
import { AppContext } from 'contexts/AppContext';
import { isValidPhoneNumber } from "utils/general";
import ContentSmall from 'components/elements/ContentSmall';
import { Box } from '@mui/material';

const PhoneNumberPrompt = (props) => {
    const context = useContext(AppContext);
    const { userData, brandData } = context;

    const countryCode = brandData?.features?.mobileNumberCountryCode || "+45"
    const mobileNumberLength = 8
    const userPhone = userData?.userPhone?.split(countryCode)
    const [ userMobileNumber, setUserMobileNumber ] = useState(userPhone[1] || "")
    const [showMobileNumberError, setShowMobileNumberError] = useState(false)

    const handleChange = (mobileNumberInput = "") => {
        setUserMobileNumber(mobileNumberInput)
    }

    const updateUserMobileNumber = async () => {
        let mobileNumberInput = userMobileNumber
        if (!userMobileNumber.startsWith(countryCode)) {
            mobileNumberInput = `${countryCode}${mobileNumberInput}`
        }
        const response = await callAPI("/user/updateUserMobileNumber", "PATCH", { body: { userMobileNumber: mobileNumberInput } })
        if (response === true) {
            setupUserData(context, () => {})
            props.onConfirm()
        }
    }

    useEffect(() => {
        const isValid = isValidPhoneNumber(countryCode, mobileNumberLength, userMobileNumber)
        setShowMobileNumberError(!isValid)
    }, [userMobileNumber])

    return (
        <Box display={'flex'} alignItems={'end'} gap={1} paddingTop={2} >
            <CustomInput
                style={{ maxWidth: 120 }}
                label={translate("_PROFILE_EDIT_FIELD_LABEL_TELEPHONE_", "Telefon")}
                value={userMobileNumber}
                onChange={(e) => handleChange(e.target.value)}
                prefix={countryCode}
            />
            <ButtonMedium1
                style={{ marginLeft: 20 }}
                showIcon={false}
                translationKey="_BUTTON_CAPTION_SAVE_"
                fallbackText="Godkend"
                onClick={() => updateUserMobileNumber()}
                disabled={showMobileNumberError}
            />
            {showMobileNumberError &&
                <ContentSmall style={{width: 320}} >
                    {
                        translate(
                            "_PROFILE_INVALID_TELEPHONE_TEXT_",
                            "Mobile number should be 8 digits which is automatically prefixed by Country code"
                        )
                    }
                </ContentSmall>
            }
        </Box>
)
}

export default PhoneNumberPrompt;
