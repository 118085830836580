import { Breadcrumbs, Link } from "@mui/material"
import ContentSmall from "./ContentSmall"
import { useContext, useEffect, useState } from "react"
import { AppContext } from "contexts/AppContext"


const ProductBreadcrumb = (props) => {
    const { styleGuide } = useContext(AppContext)
    const [breadcrumb, setBreadcrumb] = useState(null)
    
    useEffect(() => {
        if(!props.productDetails.subCategories || props.productDetails.subCategories.length <= 0) {
            props.productDetails.subCategories = []
            props.productDetails.categories.forEach(c => {
                props.productDetails.subCategories.push({categoryName: c.categoryName, subCategoryName: ''})
            })
        }
        let subCategory = props.productDetails.subCategories?.length ? props.productDetails.subCategories[0] : null
        if(props.productDetails.subCategories?.length >= 2) {
            const userEnteredParams = JSON.parse(localStorage.getItem('userEnteredParams') || '{}')
            if(userEnteredParams.shop) {
                const existingFilters = userEnteredParams.shop.filter(item => Number(item.ShopID) === Number(props.ShopID))
                const filterHasCategory = existingFilters?.length && existingFilters[0].categoryFilterValue?.length
                if(filterHasCategory){
                    const filterHasSubCategory = existingFilters[0].subCategoryFilterValue?.length
                    if(filterHasSubCategory) {
                        const filterBreadcrumb = props.productDetails.subCategories.find(c => c.categoryName === existingFilters[0].categoryFilterValue[0] && c.subCategoryName === existingFilters[0].subCategoryFilterValue[0])
                        if(filterBreadcrumb) {
                            subCategory = filterBreadcrumb
                        }
                    } else {
                        const filterBreadcrumb = props.productDetails.subCategories.find(c => c.categoryName === existingFilters[0].categoryFilterValue[0])
                        if(filterBreadcrumb) {
                            subCategory = filterBreadcrumb
                        }
                    }
                } 
            }
        }
        setBreadcrumb(subCategory)
    }, [props.productDetails])

    return (
        <Breadcrumbs aria-label="breadcrumb" style={{fontFamily: styleGuide.fontFamily, fontSize: styleGuide.contentSmallFontSize}}>
            <Link underline="hover" color="inherit" href={`/shop/${props.ShopID}`} >
                Shop
            </Link>
            {
                !!breadcrumb?.categoryName &&
                <Link underline="hover" color="inherit" href={`/shop/${props.ShopID}?category=${encodeURIComponent(breadcrumb.categoryName)}`} >
                    {breadcrumb.categoryName}
                </Link>
            }
            {
                !!breadcrumb?.subCategoryName &&
                <Link underline="hover" color="inherit" href={`/shop/${props.ShopID}?category=${encodeURIComponent(breadcrumb.categoryName)}&subCategory=${encodeURIComponent(breadcrumb.subCategoryName)}`} >
                    {breadcrumb.subCategoryName}
                </Link>
            }
            <ContentSmall>{props.productTitle}</ContentSmall>
        </Breadcrumbs>
    )

}


export default ProductBreadcrumb