import { createRoot } from "react-dom/client";
import React from 'react';
import './global.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from "./contexts/AppContext";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from 'components/elements/ScrollToTops';
import SalesIQ from 'utils/SalesIQ';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      xs2: 400,
      sm: 768,
      sm2: 960,
      md: 1280,
      lg: 1440,
      xl: 1920,
    },
  },
});

const root = createRoot(document.getElementById('root'));

root.render(<React.StrictMode>
  <ThemeProvider theme={theme}>
    <AppProvider>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </AppProvider>
  </ThemeProvider>
</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
