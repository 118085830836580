import React, { useContext } from 'react'
import './css/ButtonStyles.css'
import { AppContext } from 'contexts/AppContext';
import {createUseStyles} from 'react-jss'
import CircularIndeterminate from "components/blocks/progressBar/progressBar";


const jss = createUseStyles({
    iconButtonMedium: {
        backgroundColor: (styleGuide) => styleGuide.color1,
        color: (styleGuide) => styleGuide.color1,
        borderRadius: (styleGuide) => styleGuide.buttonBorderRadius,
        height: (styleGuide) => styleGuide.buttonMediumHeight,
        width: (styleGuide) => styleGuide.buttonMediumHeight, // height = width
        fontWeight: '600',
        border: (styleGuide) => `1px solid ${styleGuide.color4}`,
        lineHeight: (styleGuide) => styleGuide.buttonMediumLineHeight,
        padding: '9px 8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (hover: hover)':{
            '&:hover':{
                backgroundColor: (styleGuide) => styleGuide.color12,
                border: 'none',
                '& path': {
                    fill: (styleGuide) => styleGuide.color1,
                    stroke: (styleGuide) => styleGuide.color1,
                }
            }
        }
    },
    iconButtonMediumInverse: {
        backgroundColor: (styleGuide) => styleGuide.color12,
        color: (styleGuide) => styleGuide.color1,
        borderRadius: (styleGuide) => styleGuide.buttonBorderRadius,
        height: (styleGuide) => styleGuide.buttonMediumHeight,
        width: (styleGuide) => styleGuide.buttonMediumHeight, // height = width
        fontWeight: '600',
        lineHeight: (styleGuide) => styleGuide.buttonMediumLineHeight,
        padding: '9px 8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        '& path': {
            fill: (styleGuide) => styleGuide.color1,
            stroke: (styleGuide) => styleGuide.color1,
        },
        '@media (hover: hover)':{
            '&:hover':{
                backgroundColor: (styleGuide) => styleGuide.color1,
                border: (styleGuide) => `1px solid ${styleGuide.color4}`,
                '& path': {
                    stroke: (styleGuide) => styleGuide.color7,
                },
            }
        }
    },
    "@media screen and (max-width: 500px)": {
        iconButtonMedium: {
            backgroundColor: (styleGuide) => styleGuide.color1,
            color: (styleGuide) => styleGuide.color1, 
        },
        iconButtonMediumInverse: {
            backgroundColor: (styleGuide) => styleGuide.color12,
            color: (styleGuide) => styleGuide.color1,   
        },
    }
})

const IconButtonMedium = (props) => {
    const { styleGuide } = useContext(AppContext)
    const classes = jss(styleGuide)
    const inverse = (typeof props.inverse === 'boolean') ? props.inverse : false
    return (
        <button
            title={props.title || ''}
            onClick={props.onClick}
            style={{...props.style}}
            disabled={props.disabled}
            className={`${props.className} ${!inverse ? classes.iconButtonMedium : classes.iconButtonMediumInverse}`}
        > 
            {props.loading
                ? <CircularIndeterminate size={16} color={styleGuide.color4} />
                : props.icon
            }
        </button>
    )
}

export default IconButtonMedium;